import { Analytics } from "components/Analytics/Analytics";
import { useAtomValue } from "jotai";
import { IBenefitImplementationSummaryInfo } from "library/types";
import { useCallback, useState } from "react";
import { selectedCompanyState } from "store/UIHrPage";
import styled from "styled-components";

export function SummaryViewPbi() {
  const [selectedBenefitImplementation, setSelectedBenefitImplementation] =
    useState<IBenefitImplementationSummaryInfo | null>(null);

  const onRowClick = useCallback((row: IBenefitImplementationSummaryInfo) => {
    setSelectedBenefitImplementation(row);
  }, []);

  const selectedCompany = useAtomValue(selectedCompanyState);

  return (
    <Container>
      <div>
        {/* <Title>Implemented Benefits </Title> */}
        <Analytics
          countryId={selectedCompany?.countryCodeId?.toString() || null}
          clientIds={selectedCompany?.clientInternalNumberGos + ""}
          isDialog={false}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const Title = styled.div`
  font-size: 14;
  font-weight: 600;
  padding-bottom: 10px;
  text-decoration: underline;
`;
