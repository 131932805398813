import { PAGES_PER_ROLES, ROUTES } from "library/constants";
import { canSeePage } from "library/utils";
import { Navigate, RouteProps } from "react-router-dom";
import { useGetAuthorization } from "state/queries/useGetAuthorization";

type Props = {
  children: JSX.Element;
  redirectRoute?: string;
  path: string;
} & RouteProps;

export const GuardedRoute = ({
  children,
  path,
  redirectRoute = "/",
}: Props) => {
  const { data: authorization } = useGetAuthorization();

  const redirectUrl = (() => {
    const pages: string[] = [];
    authorization?.roles?.forEach((role) => {
      PAGES_PER_ROLES[role]?.pages.forEach((page) => {
        if (!pages.includes(page)) {
          pages.push(page);
        }
      });
    });
    const filteredPages = pages.filter(
      (page) => page !== ROUTES.UNAUTHORIZED_PAGE && page !== ROUTES.HOME
    );
    return filteredPages[0] || redirectRoute;
  })();

  const config = canSeePage({
    roleList: authorization?.roles,
    route: path,
  });

  return config ? <>{children}</> : <Navigate to={redirectUrl} />;
};

export default GuardedRoute;
