import { Button, Spinner } from "@fluentui/react-components";
import { ArrowUndoFilled, Open24Filled } from "@fluentui/react-icons";
import { EditIcon, SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { BenefitCategoryDialog } from "components/BenefitCategoryDialog/BenefitCategoryDialog";
import { ActionButton, Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { ROUTES } from "library/constants";
import { IBenefitCategory, IRowItem } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateBenefitCategory } from "state/queries/useUpdateBenefitCategory";

type Props = {
  item: IRowItem;
  countryCode: number | string;
  clientInternalNumberGOS?: number | string;
  withOpenCategory?: boolean;
};

export function CategoryActionsCell({
  item,
  countryCode,
  clientInternalNumberGOS = 0,
  withOpenCategory,
}: Props) {
  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const [editOpen, setEditOpen] = useState(false);
  const navigate = useNavigate();
  const {
    mutate: updateCategory,
    data: updatedData,
    isLoading: updateInProgress,
  } = useUpdateBenefitCategory(false);

  const goToCategoryImplementations = () => {
    navigate({
      pathname: `${ROUTES.COMPANY_PROFILE}/${item.benefitCategoryTag}/${item?.countryCode}/${item.clientInternalNumberGos}`,
      // search: createSearchParams({
      //   countryCodeId: item?.countryCode?.toString() || "",
      //   clientInternalNumberGOS:
      //     item?.clientInternalNumberGos?.toString() || "",
      // }).toString(),
    });
  };

  const handleEditOpen = useCallback((state) => {
    setEditOpen(state);
  }, []);

  return (
    <>
      <div>
        {!item?.isEdited ? (
          <Flex width={"100%"} justify="center">
            <ActionButton
              noBorder
              appearance="transparent"
              icon={<EditIcon />}
              onClick={() => {
                setEditOpen(true);
              }}
            />
            {withOpenCategory && (
              <Button
                icon={
                  <Open24Filled
                    style={{
                      color: palette.themePrimary,
                    }}
                  />
                }
                disabled={updateInProgress}
                appearance="transparent"
                size="small"
                onClick={goToCategoryImplementations}
              />
            )}
          </Flex>
        ) : updateInProgress ? (
          <>
            <Spinner size="extra-small" />
            <AppSpinner />
          </>
        ) : (
          <Flex width={"100%"} justify="center">
            {withOpenCategory && (
              <Button
                icon={
                  <Open24Filled
                    style={{
                      color: palette.themePrimary,
                    }}
                  />
                }
                disabled={updateInProgress}
                appearance="transparent"
                size="small"
                onClick={goToCategoryImplementations}
              />
            )}
            <Button
              icon={
                <SaveIcon
                  style={{
                    color: palette.themePrimary,
                  }}
                />
              }
              disabled={updateInProgress}
              appearance="transparent"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (item.isEdited) {
                  console.log(item, "item here");
                  updateCategory(
                    {
                      countryCode: Number(item.countryCode),
                      clientInternalNumberGOS: Number(
                        item.clientInternalNumberGos
                      ),
                      benefitCategoryTag: Number(item.benefitCategoryTag),

                      payload: {
                        ...item,

                        ...(item.coverPhoto?.base64String && {
                          backgroundPictureContent:
                            item.coverPhoto?.base64String,
                          backgroundPictureName: item.coverPhoto?.name,
                          backgroundPicturePath: item.coverPhoto?.path,
                          backgroundPictureMimeType: item.coverPhoto?.type,
                        }),
                        ...(!item.coverPhoto?.name && {
                          backgroundPictureContent: null,
                          backgroundPictureName: null,
                          backgroundPicturePath: null,
                          backgroundPictureMimeType: null,
                        }),
                      } as IBenefitCategory,
                    },
                    {
                      onSuccess: (data) => {
                        const updatedItem = {
                          ...data,
                          coverPhoto: {
                            name: data.backgroundPicturePath,
                            base64String: data.backgroundPictureContent,
                            type: data.backgroundPictureMimeType,
                          },
                          defaultState: {
                            ...data,
                            coverPhoto: {
                              name: data.backgroundPicturePath,
                              base64String: data.backgroundPictureContent,
                              type: data.backgroundPictureMimeType,
                            },
                            id: data.benefitCategoryTag,
                          },
                          rowVersion: data.rowVersion,
                          isEdited: false,
                          id: data.benefitCategoryTag,
                        };
                        item.updateState(updatedItem as IBenefitCategory);
                        toast.success(
                          t("hbh.benefitCategoryUpdatedSuccessfully.label")
                        );
                      },
                    }
                  );
                }
              }}
            />
            <Button
              icon={
                <ArrowUndoFilled
                  style={{
                    color: palette.redDark,
                  }}
                />
                //   <CancelIcon
                //   style={{
                //     color: palette.redDark,
                //   }}
                // />
              }
              disabled={updateInProgress}
              appearance="transparent"
              size="small"
              onClick={() => {
                const updatedRowItem = updatedData as IRowItem;
                if (updatedRowItem) {
                  const updatedItem = {
                    ...updatedRowItem,
                    coverPhoto: {
                      name: updatedRowItem.backgroundPicturePath || null,
                      base64String:
                        updatedRowItem.backgroundPictureContent || null,
                      type: updatedRowItem.backgroundPictureMimeType || null,
                    },
                    defaultState: {
                      ...updatedRowItem,
                      coverPhoto: {
                        name: updatedRowItem.backgroundPicturePath || null,
                        base64String:
                          updatedRowItem.backgroundPictureContent || null,
                        type: updatedRowItem.backgroundPictureMimeType || null,
                      },
                      id: updatedRowItem.benefitCategoryTag,
                    },
                    id: updatedRowItem.benefitCategoryTag,

                    isEdited: false,
                  };

                  item.updateState(updatedItem, true);
                  return;
                }

                const updatedItem = {
                  ...item.defaultState,
                  defaultState: item.defaultState,
                  isEdited: false,
                } as any;
                item.updateState(updatedItem, true);
              }}
            />
          </Flex>
        )}
      </div>
      {editOpen && (
        <BenefitCategoryDialog
          clientInternalNumberGOS={Number(clientInternalNumberGOS)}
          countryCode={Number(countryCode)}
          open={editOpen}
          setOpen={handleEditOpen}
          benefitCategory={item}
        />
      )}
    </>
  );
}
