import React, { useState } from "react";
import styled from "styled-components";
import BenefitTypes from "./BenefitTypes";
import BenefitSearchAndSelect from "./BenefitSearchAndSelect";

const BenefitsFilters = () => {
  return (
    <div>
      <BenefitTypes />
      {/* <BenefitSearchAndSelect /> */}
    </div>
  );
};

export default BenefitsFilters;
