import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  payload: Attachment;
};
const createAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,

  payload,
}: Params) => {
  const response = await api.post(
    `/benefitcategory/attachment/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}`,
    payload
  );
  return response.data as Attachment;
};
export function useCreateCategoryAttachment(refetch = true) {
  const queryClient = useQueryClient();
  return useMutation(createAttachment, {
    mutationKey: MUTATION_KEYS.createCategoryAttachment,
    onSuccess: () => {
      if (refetch) {
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitCategories,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
