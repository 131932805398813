import { atom } from "jotai";
import { STORAGE_KEYS } from "../library/shared";
import {
  EAdminTabView,
  EBenefitTab,
  ECompanyTabView,
  ECountryTabView,
  EManageHrTabView,
  INotification,
} from "library/types";
import { loadFromLocalStorage, saveToLocalStorage } from "library/utils";
import { EThemeName } from "theme/types";

const darkModeLocalStorageAtom = atom(
  // Initialize with value from localStorage or a fallback value
  loadFromLocalStorage(STORAGE_KEYS.darkMode, "") // Can be any default value or object
);

export const darkModeState = atom(
  (get) => get(darkModeLocalStorageAtom), // Read atom value
  (get, set, newValue) => {
    set(darkModeLocalStorageAtom, newValue); // Update atom
    saveToLocalStorage(STORAGE_KEYS.darkMode, newValue); // Save new value to localStorage
  }
);

const selectedThemeLocalStorageAtom = atom(
  // Initialize with value from localStorage or a fallback value
  loadFromLocalStorage(STORAGE_KEYS.selectedTheme, EThemeName.main) // Can be any default value or object
);

export const selectedThemeState = atom(
  (get) => get(selectedThemeLocalStorageAtom), // Read atom value
  (get, set, newValue) => {
    set(selectedThemeLocalStorageAtom, newValue); // Update atom
    saveToLocalStorage(STORAGE_KEYS.selectedTheme, newValue); // Save new value to localStorage
  }
);

export const panelIsOpenState = atom(false);

export const notificationMessagesState = atom<INotification[] | []>([]);

const benefitViewLocalStorageAtom = atom(
  // Initialize with value from localStorage or a fallback value
  loadFromLocalStorage(STORAGE_KEYS.isListBenefitView, EBenefitTab.grid) // Can be any default value or object
);

export const selectedHrTabState = atom<EManageHrTabView>(
  EManageHrTabView.summary
);

export const selectedAdminTabState = atom<EAdminTabView>(
  EAdminTabView.benefits
);

export const selectedCountryTabState = atom<ECountryTabView>(
  ECountryTabView.benefits
);

export const selectedCompanyTabState = atom<ECompanyTabView>(
  ECompanyTabView.benefits
);

export const benefitViewState = atom(
  (get) => get(benefitViewLocalStorageAtom), // Read atom value
  (get, set, newValue) => {
    set(benefitViewLocalStorageAtom, newValue); // Update atom
    saveToLocalStorage(STORAGE_KEYS.isListBenefitView, newValue); // Save new value to localStorage
  }
);
