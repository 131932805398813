import styled from "styled-components";
import Dashboard from "./SummaryControl/Dashboard";

export function SummaryView() {
  return (
    <Container>
      <Dashboard />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
