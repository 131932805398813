import { tokens } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import debounce from "debounce-promise";
import { useFormikContext } from "formik";
import { useAppTheme } from "hooks/useAppTheme";
import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { getInsurers } from "state/queries/useGetInsurers";
import { selectedCountryState } from "store/UICompanyPage";

const errMsg = "Select insurer";

type Props = {};
export function SelectInsurer({}: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const { setFieldValue } = useFormikContext();
  const [selectedInsurer, setSelectedInsurer] = useState(null);
  const [selectedCountry, setSelectedCountry] = useAtom(selectedCountryState);

  const disabled = !selectedCountry;

  const onChange = (option) => {
    setSelectedInsurer(option);
    setFieldValue("carrierName", option?.name || "");
    setFieldValue("carrierInternalNumberGos", option?.internalNumber || "");
  };

  return (
    <FormFieldContainer>
      <FormFieldLabel label={t("hbh.selectInsurer.label")} required={false} />

      <AsyncSelect
        isDisabled={disabled}
        cacheOptions
        placeholder={t("greco.form.searchPlaceholder")}
        loadingMessage={() => t("greco.loading")}
        noOptionsMessage={() => t("greco.noResults")}
        // onChange={onChange}
        defaultOptions={false}
        isClearable={true}
        onChange={onChange}
        value={selectedInsurer}
        // value={selectedCompany as any}
        loadOptions={debounce(async (input: string) => {
          const trimmedFilter = input.trim();
          if (trimmedFilter.length === 0) return [];
          const insurers = await getInsurers({
            countryCode: selectedCountry?.value || null,
            letters: trimmedFilter,
          });
          const options = (insurers || [])?.map((option) => {
            return {
              label: option?.name?.toString(),
              value: option?.id?.toString(),
              ...option,
            };
          });

          return options;
        }, 500)}
        name={"insurer"}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            position: "relative",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: "transparent",
            border: "none",
            borderRadius: "0",
            height: "32px !important",
            minHeight: "32px !important",
            borderBottom: error
              ? `2px solid ${theme.palette.redDark} !important`
              : state.isFocused
              ? `2px solid ${theme.palette.themePrimary} !important`
              : `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            }),
          }),
          menu: (p) => ({
            ...p,
            background: theme.palette.neutralLighter,
          }),
          option: (p, state) => ({
            ...p,
            background:
              state.isSelected || state.isFocused
                ? theme.palette.neutralLighter
                : theme.palette.white,
            "&:hover": {
              background: theme.palette.neutralLighter,
            },
            color: theme.palette.black,
            fontSize: "14px",
            fontWeight: 400,
          }),
          input: (p) => ({
            ...p,
            margin: "4px",
            color: (function () {
              if (disabled) {
                return theme.palette.neutralSecondaryAlt;
              }

              return theme.palette.black;
            })(),
          }),
          valueContainer: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          placeholder: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          singleValue: (p, state) => ({
            ...p,
            fontSize: "14px",
            marginLeft: "5px",
            fontWeight: 400,
            color: theme.palette.black,
          }),
          dropdownIndicator: (p, state) => ({
            ...p,
            ...(state.isDisabled && { visibility: "hidden" }),
          }),

          indicatorSeparator: (p, state) => ({
            ...p,
            visibility: "hidden",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            overflow: "visible",
          }),
        }}
      />
      <ErrorMessageContainer>
        <StyledErrorMessage>{error}</StyledErrorMessage>
      </ErrorMessageContainer>
    </FormFieldContainer>
  );
}
