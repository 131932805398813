import { Dialog, DialogSurface } from "@fluentui/react-components";
import styled from "styled-components";

export function AppSpinner() {
  return (
    <Dialog modalType="modal" open={true}>
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Container>
          <Content>
            <div className="loader"></div>
          </Content>
        </Container>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  background-color: ${({ theme }) =>
    theme.palette?.blackTranslucent40} !important;
  width: 100%;
  max-width: 100% !important;
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 100%;

  .loader {
    width: 40px;
    aspect-ratio: 1;
    color: ${({ theme }) => theme.palette.themePrimary};
    position: relative;
    background: conic-gradient(from 134deg at top, currentColor 92deg, #0000 0)
        top,
      conic-gradient(from -46deg at bottom, currentColor 92deg, #0000 0) bottom;
    background-size: 100% 50%;
    background-repeat: no-repeat;
  }
  .loader:before {
    content: "";
    position: absolute;
    inset: 0;
    --g: currentColor 14.5px, #0000 0 calc(100% - 14.5px), currentColor 0;
    background: linear-gradient(45deg, var(--g)),
      linear-gradient(-45deg, var(--g));
    animation: l7 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
  }
  @keyframes l7 {
    33% {
      inset: -10px;
      transform: rotate(0deg);
    }
    66% {
      inset: -10px;
      transform: rotate(90deg);
    }
    100% {
      inset: 0;
      transform: rotate(90deg);
    }
  }
`;
