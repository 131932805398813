import { tokens } from "@fluentui/react-theme";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IBenefitPackageView, SelectItem } from "library/types";
import Select from "react-select";
import { CustomPackageOption } from "./CustomPackageOption";
import { formatPackageOptions } from "./helpers";

type Props = {
  name: string;
  label?: string;
  options?: (IBenefitPackageView & SelectItem)[];
  placeholder?: string;
  value: SelectItem | null;
  setFieldValue: (name: string, value: SelectItem) => void;
  error: string;
  noErrorSection?: boolean;
  required?: boolean;
  noLabel?: boolean;
  disabled?: boolean;
} & Partial<Select>;

export const PackageSelectField = ({
  label = "",
  options = [],
  name = "",
  value,
  placeholder = "",
  error = "",
  noErrorSection = false,
  required = false,
  noLabel = false,
  setFieldValue,
  disabled = false,
  ...props
}: Props) => {
  const theme = useAppTheme();

  const data = formatPackageOptions(options);

  const onChange = (option) => {
    setFieldValue(name, option);
  };

  return (
    <FormFieldContainer>
      <FormFieldLabel label={"Package"} required={true} />
      <Select
        isDisabled={disabled}
        value={value}
        onChange={onChange}
        closeMenuOnSelect={true}
        components={{ Option: CustomPackageOption }}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            position: "relative",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: "transparent",
            border: "none",
            borderRadius: "0",
            height: "32px !important",
            minHeight: "32px !important",
            borderBottom: error
              ? `2px solid ${theme.palette.redDark} !important`
              : state.isFocused
              ? `2px solid ${theme.palette.themePrimary} !important`
              : `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            }),
          }),
          menu: (p) => ({
            ...p,
            background: theme.palette.neutralLighter,
          }),
          menuList: (p) => ({
            ...p,
            maxHeight: "500px",
            scrollbarWidth: "thin",
          }),
          option: (p, state) => ({
            ...p,
            background:
              state.isSelected || state.isFocused
                ? theme.palette.neutralLighter
                : theme.palette.white,
            "&:hover": {
              background: state?.isDisabled ? "" : theme.palette.neutralLighter,
            },
            padding: 0,
            lineHeight: "10px",
            color: theme.palette.black,
            fontWeight: 400,
          }),

          input: (p) => ({
            ...p,
            margin: "4px",
            color: (function () {
              if (disabled) {
                return theme.palette.neutralSecondaryAlt;
              }

              return theme.palette.black;
            })(),
          }),
          valueContainer: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          placeholder: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          singleValue: (p, state) => ({
            ...p,
            fontSize: "14px",
            marginLeft: "5px",
            fontWeight: 400,
            color: theme.palette.black,
          }),
          dropdownIndicator: (p, state) => ({
            ...p,
            ...(state.isDisabled && { visibility: "hidden" }),
          }),

          indicatorSeparator: (p, state) => ({
            ...p,
            visibility: "hidden",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            overflow: "visible",
          }),
        }}
        options={data}
        {...props}
      />
      <ErrorMessageContainer>
        <StyledErrorMessage>{error}</StyledErrorMessage>
      </ErrorMessageContainer>
    </FormFieldContainer>
  );
};

// const exampleOptions = [
//   {
//     value: "category",
//     label: "Category 1",
//     color: "#00B8D9",
//     isCategory: true,
//     isDisabled: true,
//     level: 0,
//   },
//   {
//     value: "benefitImplementationTag-1",
//     label: "Implementation 1",
//     color: "#0052CC",
//     isDisabled: true,
//     isImplementation: true,

//     level: 1,
//   },
//   {
//     value: "package-1",
//     label: "  Package 1",
//     color: "#5243AA",
//     level: 2,
//     isPackage: true,
//   },
//   {
//     value: "package-12",
//     label: "  Package 12",
//     color: "#5243AA",
//     level: 2,
//     isPackage: true,
//   },

//   {
//     value: "benefitImplementationTag-2",
//     label: "  Implementation 2",
//     color: "#0052CC",
//     isImplementation: true,

//     isDisabled: true,
//     level: 1,
//   },
//   {
//     value: "package-2",
//     label: "  Package 2",
//     color: "#5243AA",
//     level: 2,
//     isPackage: true,
//   },
// ];

// const Option = (props: OptionProps<any>) => {
//   const optionData = props.data;
//   return (
//     // <Tooltip content={"Customise your option component!"} truncate>
//     <components.Option {...props}>
//       {/* <Tree
//         aria-label="Default Open"
//         defaultOpenItems={[
//           "category-item",
//           "implementation-item",
//           // "package-item",
//         ]}
//       >
//         <TreeItem itemType="branch" value={"category-item"}>
//           <TreeItemLayout iconBefore={<CertificateRegular />}>
//             {optionData.benefitCategoryName}
//           </TreeItemLayout>
//           <Tree>
//             <TreeItem itemType="branch" value={"implementation-item"}>
//               <TreeItemLayout iconBefore={<RibbonRegular />}>
//                 {optionData.benefitImplementationName}
//               </TreeItemLayout>
//               <Tree>
//                 <TreeItem itemType="leaf">
//                   <TreeItemLayout
//                     iconBefore={
//                       <AvatarWithPreview
//                         avatarStyle={{
//                           borderRadius: "5px",
//                         }}
//                         size={20}
//                         name={`${optionData.name}`}
//                         imgSrc={optionData.backgroundPicturePath}
//                       />
//                     }
//                   >
//                     {" "}
//                     {optionData.name}
//                   </TreeItemLayout>
//                 </TreeItem>
//               </Tree>
//             </TreeItem>
//           </Tree>
//         </TreeItem>
//       </Tree> */}
//     </components.Option>
//     // </Tooltip>
//   );
// };
