import { Button } from "@fluentui/react-components";
import { Add16Filled } from "@fluentui/react-icons";
import { PackageDialog } from "components/PackageDialog/PackageDialog";
import { ToolbarContainer } from "components/styled";
import { IBenefitImplementation } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  benefitImplementation: IBenefitImplementation;
};

export function CompanyPackagesToolbar({ benefitImplementation }: Props) {
  const { t } = useTranslation();
  const [openAddPackage, setOpenAddPackage] = useState(false);

  const onDialogOpenChange = useCallback((state) => {
    setOpenAddPackage(state);
  }, []);
  return (
    <ToolbarContainer>
      <div />
      <ActionButton
        appearance="transparent"
        icon={<Add16Filled />}
        onClick={() => {
          setOpenAddPackage(true);
        }}
      >
        {t("hbh.addPackage.label")}
      </ActionButton>
      {openAddPackage && (
        <PackageDialog
          benefitImplementation={
            benefitImplementation as IBenefitImplementation
          }
          open={openAddPackage}
          setOpen={onDialogOpenChange}
        />
      )}
    </ToolbarContainer>
  );
}

const ActionButton = styled(Button)`
  max-width: fit-content;
  color: ${(props) => props.theme.palette.themePrimary};
`;
