import { ERole } from "library/types";
import { useGetRole } from "state/queries/useGetRole";

export function useRoleType() {
  const { data: roles } = useGetRole();

  const hasHrRole = roles?.some(
    (role) =>
      role === ERole.HRCompanyAdmin || role === ERole.ReadonlyHRCompanyAdmin
  );

  return {
    hasHrRole,
  };
}
