import { Card, Title1 } from "@fluentui/react-components";
import { ICompanyInfosInfo } from "library/types";
import FilterCurrency from "./FilterCurrency";
import FilterDate from "./FilterDate";
import FilterPackages from "./FilterPackages";
import FilterPaymentMethod from "./FilterPaymentMethod";
import FilterPaymentType from "./FilterPaymentType";

interface Props {
  companyInfo?: ICompanyInfosInfo;
  isLoading: boolean;
}
const FilterSection = ({ companyInfo, isLoading }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Card style={{ padding: "20px" }}>
        <Title1>Filters</Title1>
        <FilterPackages companyInfo={companyInfo} isLoading={isLoading} />
        <FilterCurrency />
        <FilterPaymentType />
        <FilterPaymentMethod />
        <FilterDate />
      </Card>
    </div>
  );
};

export default FilterSection;
