import {
  Body1,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Tag,
  TagGroup,
  Text,
} from "@fluentui/react-components";
import {
  AddRegular,
  ArrowExportFilled,
  ArrowSyncCheckmark24Regular,
  CalendarClockRegular,
  CameraArrowUp24Regular,
  Certificate24Filled,
  ClipboardEditRegular,
  Gift24Regular,
  OpenFilled,
  PersonRegular,
  Ribbon20Regular,
  Ribbon24Filled,
} from "@fluentui/react-icons";
import { useSingleAndDoubleClick } from "hooks/useSingleAndDoubleClick";

import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { LOCATION_STATES, ROUTES } from "library/constants";
import {
  IBenefitCategory,
  IBenefitImplementation,
  IBenefitPackage,
  IFormattedFile,
} from "library/types";
import { formatDateString, formatFile, genUUID } from "library/utils";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PlaceholderImg from "../../../assets/download2.png";

type Props = {
  type: "category" | "implementation" | "package";
  benefitCategory?: IBenefitCategory;
  benefitPackage?: IBenefitPackage;
  benefitImplementation?: IBenefitImplementation;
  hasImplementationActions?: boolean;
  onManageDocuments?: (
    item: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;
  onEdit?: (
    item: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;
  onAddNew?: (
    item: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;

  onUpdatePhoto?: (
    file: IFormattedFile | null,
    item?: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;
  previewMode?: boolean;
  // tags?: [
  //   {
  //     label: string;
  //     value: string;
  //   }
  // ];
};

export const BenefitCardNew = ({
  benefitCategory,
  onEdit,
  onAddNew,
  onManageDocuments,
  benefitPackage,
  type,
  benefitImplementation,
  hasImplementationActions,
  previewMode,
  onUpdatePhoto,
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const theme = useAppTheme();
  const navigate = useNavigate();

  const onOpenBenefitImplementation = () => {
    navigate(
      `${ROUTES.COMPANY_PROFILE}/${benefitCategory?.benefitCategoryTag}/${benefitCategory?.countryCode}/${benefitCategory?.clientInternalNumberGos}`,
      {
        state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
      }
    );
  };

  const onOpenBenefitPackages = () => {
    navigate(
      `${ROUTES.COMPANY_PROFILE}/${benefitImplementation?.benefitCategoryTag}/${benefitImplementation?.benefitImplementationTag}/${benefitImplementation?.countryCode}/${benefitImplementation?.clientInternalNumberGos}`,
      {
        state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
      }
    );
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const formattedFile = await formatFile(file);

    const item = (() => {
      if (type === "category") return benefitCategory;
      if (type === "implementation") return benefitImplementation;
      if (type === "package") return benefitPackage;
      return;
    })();
    onUpdatePhoto && onUpdatePhoto(formattedFile, item);
  };

  const config = useMemo(() => {
    let item;
    let showOpenButton = false;
    let showAddButton = false;
    let openAction;
    let addNewAction;
    let editAction;
    let manageDocumentsAction;
    let openActionLabel = "";
    let addNewLabel = "";
    let autoEnrollmentIcon;
    let tagGroup: {
      label: string;
      value?: string;
      icon?: JSX.Element;
    }[] = [];
    let TitleIcon = Certificate24Filled;

    if (type === "category") {
      item = benefitCategory;
      showOpenButton = !!hasImplementationActions;
      showAddButton = !!hasImplementationActions;
      openAction = hasImplementationActions
        ? onOpenBenefitImplementation
        : () => undefined;
      openActionLabel = t("hbh.openBenefits.label");
      addNewLabel = t("hbh.addNewImplementation.label");
      editAction = () => {
        onEdit && onEdit(benefitCategory as IBenefitCategory);
      };
      addNewAction = () => {
        onAddNew && onAddNew(benefitCategory as IBenefitCategory);
      };
      manageDocumentsAction = () => {
        onManageDocuments &&
          onManageDocuments(benefitCategory as IBenefitCategory);
      };
      TitleIcon = Certificate24Filled;
      tagGroup = [
        {
          label: "",
          value: "",
          icon: (
            <ButtonWithTooltip
              tooltipContent={t("hbh.implementedBenefitsNo.label")}
              icon={<Ribbon20Regular />}
            >
              {benefitCategory?.implementationsCount?.toString()}
            </ButtonWithTooltip>
          ),
        },
      ];
    }
    if (type === "implementation") {
      item = benefitImplementation;
      showOpenButton = true;
      showAddButton = !!onAddNew;
      openAction = onOpenBenefitPackages;
      openActionLabel = t("hbh.openPackages.label");
      addNewLabel = t("hbh.addNewPackage.label");

      editAction = () => {
        onEdit && onEdit(benefitImplementation as IBenefitImplementation);
      };
      addNewAction = () => {
        onAddNew && onAddNew(benefitImplementation as IBenefitImplementation);
      };
      manageDocumentsAction = () => {
        onManageDocuments &&
          onManageDocuments(benefitImplementation as IBenefitImplementation);
      };
      TitleIcon = Ribbon24Filled;

      tagGroup = [
        {
          label: "",
          value: "",
          icon: (
            <ButtonWithTooltip
              tooltipContent={`${t("hbh.packagesNo.label")} - ${
                benefitImplementation?.packagesCount
              }`}
              icon={<Gift24Regular />}
            >
              {`${benefitImplementation?.packagesCount}`}
            </ButtonWithTooltip>
          ),
        },
        {
          label: "",
          value: "",
          icon: (
            <ButtonWithTooltip
              tooltipContent={`${"hbh.validFrom.label"}: ${formatDateString(
                benefitImplementation?.validFrom
              )} - ${t("hbh.validTo.label")}: ${formatDateString(
                benefitImplementation?.validTo
              )}`}
              icon={<CalendarClockRegular />}
            >
              {`${formatDateString(
                benefitImplementation?.validFrom
              )} - ${formatDateString(benefitImplementation?.validTo)}`}
            </ButtonWithTooltip>
          ),
        },
      ];
    }
    if (type === "package") {
      item = benefitPackage;
      showOpenButton = false;
      showAddButton = false;

      editAction = () => {
        onEdit && onEdit(benefitPackage as IBenefitPackage);
      };
      manageDocumentsAction = () => {
        onManageDocuments &&
          onManageDocuments(benefitPackage as IBenefitPackage);
      };
      addNewAction = () => {
        onAddNew && onAddNew(benefitPackage as IBenefitPackage);
      };
      TitleIcon = Gift24Regular;
      autoEnrollmentIcon = benefitPackage?.isAutoEnrollment ? (
        <ButtonWithTooltip
          tooltipContent={t("hbh.packageHasAutoenrollment.label")}
          icon={
            <ArrowSyncCheckmark24Regular
              style={{
                color: theme.palette.green,
              }}
            />
          }
        />
      ) : null;
      tagGroup = [
        {
          label: "",
          value: "",
          icon: (
            <ButtonWithTooltip
              tooltipContent={t("hbh.numberOfEnrolledEligibles.label")}
              icon={<PersonRegular />}
            >
              {`${benefitPackage?.enrollmentsCount}/${benefitPackage?.eligibilitiesCount}`}
            </ButtonWithTooltip>
          ),
        },
      ];
    }
    return {
      item,
      showOpenButton,
      openAction,
      editAction,
      openActionLabel,
      TitleIcon,
      manageDocumentsAction,
      tagGroup,
      addNewAction,
      addNewLabel,
      showAddButton,
      autoEnrollmentIcon,
    };
  }, [benefitCategory, benefitImplementation, benefitPackage, type]);

  const onImageClick = useSingleAndDoubleClick(
    () => {
      return;
    },
    () => {
      if (!config.openAction) return;
      config.openAction();
    }
  );

  const showActionSection =
    (hasImplementationActions ||
      config.editAction ||
      config.autoEnrollmentIcon ||
      config.showAddButton ||
      config.manageDocumentsAction ||
      config.editAction) &&
    !previewMode;

  return (
    <StyledCard>
      <CardPreview
        style={{
          height: "250px",
          position: "relative",
          background: theme.palette.white,
        }}
      >
        <img
          src={config.item?.backgroundPicturePath || PlaceholderImg}
          alt={config.item?.name}
          onClick={onImageClick}
          loading="lazy"
          style={{
            width: "100%",
            cursor: "pointer",
            objectFit: "scale-down",
            // objectFit: "cover",
            // objectFit: "fill",
            // height: "250px",
          }}
        />
        {!previewMode && (
          <CameraWrap>
            <CameraArrowUp24Regular
              onClick={() => {
                if (!fileInputRef.current) return;
                fileInputRef.current.click();
              }}
            />

            <input
              ref={fileInputRef}
              onChange={handleFileChange}
              type="file"
              accept="*"
              hidden
            />
          </CameraWrap>
        )}
        {/* {config.autoEnrollmentIcon && (
          <AutoEnrollmentWrap>{config.autoEnrollmentIcon}</AutoEnrollmentWrap>
        )} */}
      </CardPreview>

      <CardHeader
        style={{
          padding: "5px 12px ",
        }}
        image={
          <config.TitleIcon
            style={{
              color: theme.palette.yellowDark,
            }}
          />
        }
        header={
          <Body1>
            <StyledTooltip
              content={config.item?.name || ""}
              relationship="description"
            >
              <Text
                block
                truncate
                weight="bold"
                style={{
                  maxWidth: "250px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {config.item?.name}
              </Text>
            </StyledTooltip>
          </Body1>
        }
      />

      <StyledTooltip
        relationship="label"
        content={{
          children: config.item?.description,
        }}
      >
        <Desc>{config.item?.description}</Desc>
      </StyledTooltip>
      {config.tagGroup?.length ? (
        <TagGroup
          aria-label="Simple tag group with Tag"
          role="list"
          style={{
            display: "flex",
            gap: "7px",
            height: "auto",
            padding: "5px",
          }}
        >
          {/* <TagList> */}
          {config.tagGroup.map((tag) => {
            return (
              <StyledTag
                appearance="filled"
                role="listitem"
                key={genUUID()}
                media={tag.icon}
                shape="circular"
              >
                {tag.label && <Text weight="semibold">{tag.label}:</Text>}
                {tag.value && <Text>{tag.value}</Text>}
              </StyledTag>
            );
          })}
        </TagGroup>
      ) : null}
      {showActionSection && (
        <StyledCardFooter>
          {config.showAddButton ? (
            <ButtonWithTooltip
              tooltipContent={config.addNewLabel}
              handleClick={config.addNewAction}
              icon={<AddRegular />}
            />
          ) : null}
          {config.autoEnrollmentIcon ?? <div />}
          <div>
            {config.showOpenButton && (
              <ButtonWithTooltip
                onClick={config.openAction}
                tooltipContent={config.openActionLabel}
                icon={<OpenFilled />}
              />
            )}

            {config.manageDocumentsAction && (
              <ButtonWithTooltip
                onClick={config.manageDocumentsAction}
                icon={<ArrowExportFilled />}
                tooltipContent={t("hbh.manageDocuments.label")}
              />
            )}
            {config.editAction && (
              <ButtonWithTooltip
                tooltipContent={t("hbh.edit.label")}
                handleClick={config.editAction}
                icon={<ClipboardEditRegular />}
              />
            )}
          </div>
        </StyledCardFooter>
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 100%;
  border-radius: 10px;

  padding: 0 !important;
  gap: 0;
`;

const StyledCardFooter = styled(CardFooter)`
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: ${({ theme }) => theme.palette.neutralLighterAlt};
  background-color: ${({ theme }) => theme.palette.themeLighterAlt};
`;

const Desc = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 12px 5px;
  height: 40px;
`;

const CameraWrap = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 20px;
  cursor: pointer;
  bottom: 5px;
  z-index: 10;
`;

const StyledTag = styled(Tag)`
  width: auto;
  height: auto;
  background-color: ${({ theme }) => theme.palette.neutralLighterAlt};

  .fui-Tag {
    padding: 0;
  }

  button {
    min-width: auto;
    padding: 0px;
    margin: 0px;
  }
  .fui-Tag__media {
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }
`;
