import { Skeleton } from "@fluentui/react-components";
import { CustomPagination } from "components/CustomPagination/CustomPagination";
import { ImplementBenefitDialog } from "components/ImplementBenefitDialog/ImplementBenefitDialog";
import { ManageAttachmentsDialog } from "components/ManageAttachmentsDialog/ManageAttachmentsDialog";
import { BenefitCardNew } from "components/ManageBenefitCategories/BenefitCardList/BenefitCardNew";
import { PackageDialog } from "components/PackageDialog/PackageDialog";
import CardItemSkeleton from "components/Skeletons/CardItemSkeleton";
import { CardListWrap, Flex, PaginationWrap } from "components/styled";
import { usePaginationConfig } from "hooks/usePaginationConfig";
import {
  Attachment,
  IBenefitCategory,
  IBenefitImplementation,
  IFormattedFile,
  IRowItem,
} from "library/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateBenefitImplementationAttachment } from "state/queries/useCreateBenefitImplementationAttachment";
import { useDeleteBenefitImplementationAttachment } from "state/queries/useDeleteBenefitImplementationAttachment";
import { useUpdateBenefitImplementation } from "state/queries/useUpdateBenefitImplementation";
import { useUpdateBenefitImplementationAttachment } from "state/queries/useUpdateBenefitImplementationAttachment";

type Props = {
  benefitImplementations: IBenefitImplementation[];
  benefitCategory?: IBenefitCategory;
  previewMode?: boolean;
  withPagination?: boolean;
};

export function ImplementationCardList({
  benefitImplementations,
  benefitCategory,
  previewMode,
  withPagination = false,
}: Props) {
  const { t } = useTranslation();
  const { currentPage, totalPages, from, to, handlePageChange } =
    usePaginationConfig({ numberOfItems: benefitImplementations.length });

  const [selectedImplementation, setSelectedImplementation] = useState<
    IBenefitImplementation | undefined
  >(undefined);

  const [addPackageOpen, setAddPackageOpen] = useState(false);

  const [implementationDialogOpen, setImplementationDialogOpen] =
    useState(false);

  const [manageDocumentOpen, setManageDocumentOpen] = useState(false);

  const { mutate: onCreateAttachment, isLoading: createAttachmentInProgress } =
    useCreateBenefitImplementationAttachment();

  const { mutate: onDeleteAttachment, isLoading: deleteInProgress } =
    useDeleteBenefitImplementationAttachment();

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdateBenefitImplementationAttachment();

  const {
    mutate: updateImplementation,
    isLoading: updateImplementationInProgress,
  } = useUpdateBenefitImplementation();

  const handleAddPackageState = useCallback((state) => {
    setAddPackageOpen(state);
  }, []);

  const handleAddPackageOpen = useCallback((implementation) => {
    setSelectedImplementation(implementation);
    setAddPackageOpen(true);
  }, []);

  const handleEditImplementationDialogState = useCallback((implementation) => {
    setSelectedImplementation(implementation);
    setImplementationDialogOpen(true);
  }, []);

  const handleImplementationDialogOpen = useCallback((state) => {
    setImplementationDialogOpen(state);
  }, []);

  const handleOpenManageDocuments = useCallback((implementation) => {
    setSelectedImplementation(implementation);
    setManageDocumentOpen(true);
  }, []);

  const onManageDocumentsOpenStateChange = useCallback((state) => {
    setManageDocumentOpen(state);
  }, []);

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          benefitCategoryTag: Number(
            selectedImplementation?.benefitCategoryTag
          ),
          benefitImplementationTag: Number(
            selectedImplementation?.benefitImplementationTag
          ),
          clientInternalNumberGOS:
            selectedImplementation?.clientInternalNumberGos || 0,
          countryCode: selectedImplementation?.countryCode || 0,
          payload: {
            ...attachment,
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    [selectedImplementation]
  );

  const handleCreateAttachment = useCallback(
    (newAttach: IFormattedFile | null) => {
      onCreateAttachment(
        {
          benefitCategoryTag: Number(
            selectedImplementation?.benefitCategoryTag
          ),
          benefitImplementationTag: Number(
            selectedImplementation?.benefitImplementationTag
          ),
          clientInternalNumberGOS:
            selectedImplementation?.clientInternalNumberGos || 0,
          countryCode: selectedImplementation?.countryCode || 0,
          payload: {
            attachmentName: newAttach?.name || "",
            attachmentContent: newAttach?.base64String || "",
            attachmentMimeType: newAttach?.type || "",
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          },
        }
      );
    },
    [selectedImplementation]
  );

  const handleDeleteAttachment = useCallback(
    (attach: Attachment) => {
      onDeleteAttachment(
        {
          attachmentName: attach.attachmentName || "",
          benefitCategoryTag: Number(
            selectedImplementation?.benefitCategoryTag
          ),
          benefitImplementationTag: Number(
            selectedImplementation?.benefitImplementationTag
          ),
          clientInternalNumberGOS:
            selectedImplementation?.clientInternalNumberGos || 0,
          countryCode: selectedImplementation?.countryCode || 0,
          rowVersion: attach.rowVersion || "",
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentDeletedSuccessfully.label"));
          },
        }
      );
    },
    [selectedImplementation]
  );

  const onUpdatePhoto = useCallback(
    (photo, implementation) => {
      setSelectedImplementation(implementation);
      updateImplementation(
        {
          countryCode: Number(implementation?.countryCode),
          clientInternalNumberGOS: Number(
            implementation?.clientInternalNumberGos
          ),
          benefitCategoryTag: Number(implementation?.benefitCategoryTag),
          benefitImplementationTag: Number(
            implementation?.benefitImplementationTag
          ),
          payload: {
            ...(implementation as IBenefitImplementation),
            backgroundPictureContent: photo?.base64String,
            backgroundPictureName: photo?.name,
            backgroundPictureMimeType: photo?.type,
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.implementationUpdatedSuccessfully.label"));
          },
          onError() {
            toast.error(t("greco.error"));
          },
        }
      );
    },
    [selectedImplementation]
  );

  const sortedImplementations = benefitImplementations.sort(
    (a, b) =>
      Number(a?.benefitImplementationTag) - Number(b.benefitImplementationTag)
  );

  useEffect(() => {
    const updatedImplementation = benefitImplementations.find(
      (item) =>
        item.benefitImplementationTag ===
        selectedImplementation?.benefitImplementationTag
    );
    if (updatedImplementation) {
      setSelectedImplementation(updatedImplementation); // update selected implementation when documents are updated
    }
  }, [benefitImplementations]);

  const implementationList = withPagination
    ? sortedImplementations.slice(from, to)
    : sortedImplementations;

  return (
    <>
      {withPagination && (
        <PaginationWrap>
          <CustomPagination
            page={currentPage}
            totalPages={totalPages}
            onChange={handlePageChange}
          />
        </PaginationWrap>
      )}
      <CardListWrap>
        {implementationList.map((benefit) => {
          if (
            benefit.benefitImplementationTag ===
              selectedImplementation?.benefitImplementationTag &&
            updateImplementationInProgress
          ) {
            return (
              <Skeleton aria-label="Loading Content">
                <CardItemSkeleton />
              </Skeleton>
            );
          }
          return (
            <BenefitCardNew
              previewMode={previewMode}
              onUpdatePhoto={onUpdatePhoto}
              type="implementation"
              benefitImplementation={benefit}
              key={benefit.benefitImplementationTag}
              onEdit={handleEditImplementationDialogState}
              onManageDocuments={handleOpenManageDocuments}
              onAddNew={handleAddPackageOpen} // we need category for default values when creating new implementation
            />
          );
        })}
      </CardListWrap>

      {implementationDialogOpen && (
        <ImplementBenefitDialog
          category={benefitCategory}
          open={implementationDialogOpen}
          setOpen={handleImplementationDialogOpen}
          benefitImplementation={
            selectedImplementation as IBenefitImplementation
          }
        />
      )}
      {manageDocumentOpen && (
        <ManageAttachmentsDialog
          attachmentActionsState={{
            createAttachmentInProgress,
            onCreateAttachment: handleCreateAttachment,
            updateInProgress,
            onUpdateAttachment: handleUpdateAttachment,
            deleteInProgress,
            onDeleteAttachment: handleDeleteAttachment,
          }}
          item={selectedImplementation as IRowItem}
          open={manageDocumentOpen}
          setOpen={onManageDocumentsOpenStateChange}
        />
      )}
      {addPackageOpen && (
        <PackageDialog
          benefitImplementation={
            selectedImplementation as IBenefitImplementation
          }
          open={addPackageOpen}
          setOpen={handleAddPackageState}
        />
      )}
    </>
  );
}
