import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";

export function CancellationFields() {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();
  const cancellationPossibilityTypeOptions = useTaxonomyOptions(
    ETaxonomy.EligibleType
  );
  const cancellationPossibilityDateTypeOptions = useTaxonomyOptions(
    ETaxonomy.EligibleDateType
  );

  return (
    <>
      <SelectField
        error={errors["cancellationPossibilityDateType"]}
        setFieldValue={setFieldValue}
        value={values["cancellationPossibilityDateType"]}
        label={t("hbh.cancellationPossibilityDateType.label")}
        options={cancellationPossibilityDateTypeOptions}
        name={"cancellationPossibilityDateType"}
        showEmptyOption
      />
      <SelectField
        error={errors["cancellationPossibilityType"]}
        setFieldValue={setFieldValue}
        value={values["cancellationPossibilityType"]}
        label={t("hbh.cancellationPossibilityType.label")}
        options={cancellationPossibilityTypeOptions}
        name={"cancellationPossibilityType"}
        showEmptyOption
      />
      <InputField
        label={t("hbh.cancellationPossibilityValue.label")}
        name="cancellationPossibilityValue"
        value={values["cancellationPossibilityValue"]}
        setFieldValue={setFieldValue}
        error={errors["cancellationPossibilityValue"]}
      />
    </>
  );
}
