export const STORAGE_KEYS = {
  darkMode: "dark-mode",
  appLang: "app-lang",
  isListBenefitView: "is-list-benefit-view",
  selectedTheme: "selected-theme",
};

export const QUERY_KEYS = {
  userData: "userData",
  benefitCategories: "benefitCategories",
  taxonomies: "taxonomies",
  roles: "roles",
  authorization: "authorization",
  benefitImplementations: "benefitImplementations",
  benefitImplementationPackages: "benefitImplementationPackages",
  insurers: "insurers",
  clients: "clients",
  clientsByCountry: "clientsByCountry",
  companySummary: "companySummary",
  companyInfos: "companyInfos",
  companyEnrollments: "companyEnrollments",
  companyEmployees: "companyEmployees",
  companyEmployeeViews: "companyEmployeeViews",
  employeeDetails: "employeeDetails",
  dependentDetails: "dependentDetails",
  employeeEnrollmentsByPackage: "employeeEnrollmentsByPackage",
  employeeEnrollmentsByEmployee: "employeeEnrollmentsByEmployee",
  companyPackages: "companyPackages",
  employeeEligibility: "employeeEligibility",
  benefitCategory: "benefitCategory",
  benefitImplementation: "benefitImplementation",
  employeeEnrollment: "employeeEnrollment",
  dependentEnrollment: "dependentEnrollment",
  generateEmployeeTemplate: "generateEmployeeTemplate",
  companyAction: "companyAction",
};

export const MUTATION_KEYS = {
  createBenefitCategory: "createBenefitCategory",
  createBenefitImplementation: "createBenefitImplementation",
  updateBenefitCategory: "updateBenefitCategory",
  updateBenefitImplementation: "updateBenefitImplementation",
  createMultipleBenefitCategories: "createMultipleBenefitCategories",
  createBenefitPackage: "createBenefitPackage",
  createBenefitCategoryAttachment: "createBenefitCategoryAttachment",
  deleteBenefitCategoryAttachment: "deleteBenefitCategoryAttachment",
  deleteBenefitImplementationAttachment:
    "deleteBenefitImplementationAttachment",
  createBenefitImplementationAttachment:
    "createBenefitImplementationAttachment",
  updateBenefitImplementationAttachment:
    "updateBenefitImplementationAttachment",
  updatePackageDependent: "updatePackageDependent",
  createPackageDependent: "createPackageDependent",
  deletePackageDependent: "deletePackageDependent",
  updateClient: "updateClient",
  updateBenefitImplementationPackage: "updateBenefitImplementationPackage",
  createEmployee: "createEmployee",
  deleteEmployee: "deleteEmployee",
  updateEmployeeAttachment: "updateEmployeeAttachment",
  deleteEmployeeAttachment: "deleteEmployeeAttachment",
  createEmployeeAttachment: "createEmployeeAttachment",
  createEmployeeDependent: "createEmployeeDependent",
  updateEmployeeDependent: "updateEmployeeDependent",
  deleteEmployeeDependent: "deleteEmployeeDependent",
  deleteEmployeeEnrollment: "deleteEmployeeEnrollment",
  createEmployeeEnrollment: "createEmployeeEnrollment",
  updateEmployeeEnrollment: "updateEmployeeEnrollment",
  deleteEmployeeEnrollments: "deleteEmployeeEnrollments",
  createEmployeeEnrollments: "createEmployeeEnrollments",
  updateEmployeeEnrollments: "updateEmployeeEnrollments",
  createEmployeeEligibility: "createEmployeeEligibility",
  updateEmployeeEligibility: "updateEmployeeEligibility",
  deleteEmployeeEligibility: "deleteEmployeeEligibility",
  createEmployeeEligibilities: "createEmployeeEligibilities",
  updateEmployeeEligibilities: "updateEmployeeEligibilities",
  deleteEmployeeEligibilities: "deleteEmployeeEligibilities",
  createEmployeeDependentEnrollment: "createEmployeeDependentEnrollment",
  updateEmployeeDependentEnrollment: "updateEmployeeDependentEnrollment",
  deleteEmployeeDependentEnrollment: "deleteEmployeeDependentEnrollment",
  addEmployeeTags: "addEmployeeTags",
  removeEmployeeTags: "removeEmployeeTags",
  createCategoryAttachment: "createCategoryAttachment",
  deleteCategoryAttachment: "deleteCategoryAttachment",
  updateCategoryAttachment: "updateCategoryAttachment",
  createPackageAttachment: "createPackageAttachment",
  deletePackageAttachment: "deletePackageAttachment",
  updatePackageAttachment: "updatePackageAttachment",
  createDependentEligibility: "createDependentEligibility",
  updateDependentEligibility: "updateDependentEligibility",
  deleteDependentEligibility: "deleteDependentEligibility",
  importEmployeesFromExcel: "importEmployeesFromExcel",
};

export const ROUTE_PARAMS = {};
