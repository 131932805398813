import { MenuItem, useIsOverflowItemVisible } from "@fluentui/react-components";

export const OverflowMenuItem = (props) => {
  const { item, id } = props;
  const isVisible = useIsOverflowItemVisible(id);

  if (isVisible) {
    return null;
  }

  // As an union between button props and div props may be conflicting, casting is required
  return <MenuItem>{item}</MenuItem>;
};
