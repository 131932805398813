import { Dropdown, Option } from "@fluentui/react-components";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtom } from "jotai";
import { ETaxonomy } from "library/types";
import React from "react";
import { filterPaymentMethodAtom } from "store/UIHrPageDashboard";

const FilterPaymentMethod = () => {
  const paymentMethodOptions = useTaxonomyOptions(ETaxonomy.PaymentMethod);
  const [filterPaymentMethod, setFilterPaymentMethod] = useAtom(
    filterPaymentMethodAtom
  );

  const handleOptionSelect = (ev, data) => {
    if (data.optionValue === "") {
      setFilterPaymentMethod({
        code: "PaymentMethod.All",
        text: "All Payment Methods",
        value: "",
      });
      return;
    }
    setFilterPaymentMethod(
      paymentMethodOptions.find((e) => e.value === data.optionValue)
    );
  };

  return (
    <Dropdown
      placeholder="Payment Method"
      title="Payment Method"
      style={{ marginTop: "10px" }}
      value={String(filterPaymentMethod?.text) || ""}
      onOptionSelect={handleOptionSelect}
    >
      <Option key={"All"} defaultChecked={true} value="">
        {"All Payment Methods"}
      </Option>
      {paymentMethodOptions
        .sort((a, b) => a.text.localeCompare(b.text))
        .map((option) => (
          <Option key={option.code} value={option.value}>
            {option.text}
          </Option>
        ))}
    </Dropdown>
  );
};

export default FilterPaymentMethod;
