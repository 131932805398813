import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";

export function OffboardingFields() {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();
  const offboardingTypeOptions = useTaxonomyOptions(ETaxonomy.OffboardingType);
  const offboardingDateTypeOptions = useTaxonomyOptions(
    ETaxonomy.OffboardingDateType
  );

  return (
    <>
      <SelectField
        error={errors["offboardingDateType"]}
        setFieldValue={setFieldValue}
        value={values["offboardingDateType"]}
        label={t("hbh.offboardingDateType.label")}
        options={offboardingDateTypeOptions}
        name={"offboardingDateType"}
        showEmptyOption
      />
      <SelectField
        error={errors["offboardingType"]}
        setFieldValue={setFieldValue}
        value={values["offboardingType"]}
        label={t("hbh.offboardingType.label")}
        options={offboardingTypeOptions}
        name={"offboardingType"}
        showEmptyOption
      />

      <InputField
        label={t("hbh.offboardingValue.label")}
        name="offboardingValue"
        value={values["offboardingValue"]}
        setFieldValue={setFieldValue}
        error={errors["offboardingValue"]}
      />
    </>
  );
}
