import { Button } from "@fluentui/react-components";
import { NoteEdit24Regular } from "@fluentui/react-icons";
import { Flex, StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useCallback, useState } from "react";

import { IBenefitPackageView, IEmployeeView } from "library/types";
import { EnrollmentsFormDialog } from "../EnrollmentsFormDialog/EnrollmentsFormDialog";
import { useTranslation } from "react-i18next";

type Props = {
  packageData?: IBenefitPackageView;
  packageUser?: IEmployeeView;
  disabled?: boolean;
};

export function EditEmployeeEnrollment({
  packageData,
  packageUser,

  disabled,
}: Props) {
  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const [editEmployeeOpen, setEditEmployeeOpen] = useState(false);

  const setEditEmployeeOpenState = useCallback((state) => {
    setEditEmployeeOpen(state);
  }, []);

  return (
    <>
      <Flex width={"100%"} justify="center">
        <StyledTooltip
          relationship="label"
          content={{
            children: t("hbh.enrollment.editEnrollment.label"),
          }}
        >
          <Button
            icon={
              <NoteEdit24Regular
                style={{
                  ...(!disabled ? { color: palette.themePrimary } : {}),
                }}
              />
            }
            disabled={disabled}
            appearance="transparent"
            size="small"
            onClick={() => {
              setEditEmployeeOpen(true);
            }}
          />
        </StyledTooltip>
      </Flex>
      {editEmployeeOpen && (
        <EnrollmentsFormDialog
          open={editEmployeeOpen}
          setOpen={setEditEmployeeOpenState}
          packageData={packageData}
          packageUser={packageUser}
        />
      )}
    </>
  );
}
