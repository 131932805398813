import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Form, Formik } from "formik";
import { devices } from "library/constants";
import { useMemo } from "react";
import styled from "styled-components";
import * as yup from "yup";

import { AppSpinner } from "components/AppSpinner/AppSpinner";
import useGetCurrencyOptionsPerUser from "hooks/useGetCurrencyOptionsPerUser";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import {
  EPaidBy,
  ETaxonomy,
  IBenefitImplementation,
  IBenefitPackage,
} from "library/types";
import { formatSelectFieldForPayload } from "library/utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateBenefitPackage } from "state/queries/useCreateBenefitPackage";
import { useUpdateBenefitImplementationPackage } from "state/queries/useUpdateBenefitImplementationPackage";
import { PackageFormItems } from "./PackageFormItems";

type Props = {
  packageData?: IBenefitPackage;
  benefitImplementation: IBenefitImplementation;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "950px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});

export const PackageDialog = ({
  packageData,
  benefitImplementation,
  open,
  setOpen,
}: Props) => {
  const { t } = useTranslation();
  const addPackageTitle = t("hbh.addPackageTo.label", {
    implementation: benefitImplementation.name,
  });
  const editPackageTitle = t("hbh.editPackage.label", {
    package: packageData?.name,
  });
  const title = packageData ? editPackageTitle : addPackageTitle;
  const styles = useStyles();
  const { mutate: onCreatePackage, isLoading: createInProgress } =
    useCreateBenefitPackage();

  const { mutate: onUpdatePackage, isLoading: updateInProgress } =
    useUpdateBenefitImplementationPackage();

  const currencyOptions = useGetCurrencyOptionsPerUser();
  const paymentMethodOptions = useTaxonomyOptions(ETaxonomy.PaymentMethod);
  const eligibleTypeOptions = useTaxonomyOptions(ETaxonomy.EligibleType);
  const eligibleDateTypeOptions = useTaxonomyOptions(
    ETaxonomy.EligibleDateType
  );
  const paymentTypeOptions = useTaxonomyOptions(ETaxonomy.PaymentType);
  const offboardingTypeOptions = useTaxonomyOptions(ETaxonomy.OffboardingType);
  const offboardingDateTypeOptions = useTaxonomyOptions(
    ETaxonomy.OffboardingDateType
  );

  const initialValues = useMemo(() => {
    const paidByValue = packageData?.usingPercentagesForPaidBy
      ? EPaidBy.Percentage
      : EPaidBy.Amount;
    return {
      ...packageData,
      usingPercentagesForPaidBy: packageData ? paidByValue : null,
      benefitImplementationName: benefitImplementation?.name || "",
      carrierName: benefitImplementation?.carrierName || "",
      currencyCode: currencyOptions.find(
        (currency) => currency.value === benefitImplementation?.currencyCode
      ),
      paymentMethod: packageData
        ? paymentMethodOptions.find((item) => {
            return item.value === packageData.paymentMethod;
          })
        : null,
      paymentType: packageData
        ? paymentTypeOptions.find((item) => {
            return item.value === packageData.paymentType;
          })
        : null,
      eligibleFromType: packageData
        ? eligibleTypeOptions.find((option) => {
            return option.value === packageData.eligibleFromType;
          })
        : null,
      eligibleToType: packageData
        ? eligibleTypeOptions.find((option) => {
            return option.value === packageData.eligibleToType;
          })
        : null,
      eligibleFromDateType: packageData
        ? eligibleDateTypeOptions.find((option) => {
            return option.value === packageData.eligibleFromDateType;
          })
        : null,

      //
      cancellationPossibilityType: packageData
        ? eligibleTypeOptions.find((option) => {
            return option.value === packageData.cancellationPossibilityType;
          })
        : null,
      cancellationPossibilityDateType: packageData
        ? eligibleDateTypeOptions.find((option) => {
            return option.value === packageData.cancellationPossibilityDateType;
          })
        : null,
      cancellationPossibilityValue: packageData?.cancellationPossibilityValue,

      //
      eligibleToDateType: packageData
        ? eligibleDateTypeOptions.find((option) => {
            return option.value === packageData.eligibleToDateType;
          })
        : null,
      offboardingType: packageData
        ? offboardingTypeOptions.find((option) => {
            return option.value === packageData.offboardingType;
          })
        : null,
      offboardingDateType: packageData
        ? offboardingDateTypeOptions.find((option) => {
            return option.value === packageData.offboardingDateType;
          })
        : null,
      coverPhoto: packageData?.backgroundPicturePath
        ? {
            name: packageData?.backgroundPictureName,
            path: packageData?.backgroundPicturePath,
            base64String: packageData?.backgroundPictureContent,
            type: packageData?.backgroundPictureMimeType,
          }
        : null,
      isAutoEnrollment: packageData?.isAutoEnrollment || false,
      eligibleFromValue: packageData?.eligibleFromValue,
      eligibleToValue: packageData?.eligibleToValue,
      offboardingValue: packageData?.offboardingValue,
      description: packageData?.description || "",
    };
  }, [
    offboardingDateTypeOptions,
    offboardingTypeOptions,
    eligibleDateTypeOptions,
    benefitImplementation?.carrierName,
    benefitImplementation?.currencyCode,
    benefitImplementation?.name,
    currencyOptions,
    eligibleTypeOptions,
    packageData,
    paymentMethodOptions,
    paymentTypeOptions,
  ]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    carrierName: yup.string().required(),
    currencyCode: yup.mixed().required(),
    price: yup.string().required(),
    description: yup.string().min(2).max(4000).required(),

    usingPercentagesForPaidBy: yup.mixed().required(),
    paidByCompany: yup.string().required(),
    paidByEmployee: yup.string().required(),
    paymentMethod: yup.mixed().required(),
    paymentType: yup.mixed().required(),
  });

  const handleSubmit = (values) => {
    if (packageData) {
      onUpdatePackage(
        {
          countryCode: Number(benefitImplementation?.countryCode),
          clientInternalNumberGOS: Number(
            benefitImplementation?.clientInternalNumberGos
          ),
          benefitCategoryTag: Number(benefitImplementation?.benefitCategoryTag),
          benefitImplementationTag: Number(
            benefitImplementation?.benefitImplementationTag
          ),
          benefitPackageTag: Number(packageData.benefitPackageTag),
          payload: {
            ...packageData,
            ...values,
            eligibleFromType: formatSelectFieldForPayload({
              values,
              field: "eligibleFromType",
            }),
            currencyCode: formatSelectFieldForPayload({
              values,
              field: "currencyCode",
            }),
            paymentMethod: formatSelectFieldForPayload({
              values,
              field: "paymentMethod",
            }),
            paymentType: formatSelectFieldForPayload({
              values,
              field: "paymentType",
            }),
            eligibleFromDateType: formatSelectFieldForPayload({
              values,
              field: "eligibleFromDateType",
            }),
            eligibleToDateType: formatSelectFieldForPayload({
              values,
              field: "eligibleToDateType",
            }),
            eligibleToType: formatSelectFieldForPayload({
              values,
              field: "eligibleToType",
            }),
            offboardingType: formatSelectFieldForPayload({
              values,
              field: "offboardingType",
            }),
            offboardingDateType: formatSelectFieldForPayload({
              values,
              field: "offboardingDateType",
            }),
            //
            cancellationPossibilityType: formatSelectFieldForPayload({
              values,
              field: "cancellationPossibilityType",
            }),
            cancellationPossibilityDateType: formatSelectFieldForPayload({
              values,
              field: "cancellationPossibilityDateType",
            }),
            cancellationPossibilityValue: Number(
              values.cancellationPossibilityValue
            ),

            //
            eligibleFromValue: Number(values.eligibleFromValue),

            offboardingValue: Number(values.offboardingValue),

            benefitCategoryParentTag: benefitImplementation?.benefitCategoryTag,
            usingPercentagesForPaidBy:
              values?.usingPercentagesForPaidBy === EPaidBy.Percentage,
            price: Number(values.price),
            paidByCompany: Number(values.paidByCompany),
            paidByEmployee: Number(values.paidByEmployee),
            backgroundPictureContent: values.coverPhoto?.base64String,
            backgroundPictureName: values.coverPhoto?.name,
            backgroundPicturePath: values.coverPhoto?.path,
            backgroundPictureMimeType: values.coverPhoto?.type,
            ...(values.coverPhoto?.base64String && {
              backgroundPictureContent: values.coverPhoto?.base64String,
              backgroundPictureName: values.coverPhoto?.name,
              backgroundPicturePath: values.coverPhoto?.path,
              backgroundPictureMimeType: values.coverPhoto?.type,
            }),
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.packageUpdatedSuccessfully.label"));
            setOpen(false);
          },
        }
      );
      return;
    }
    onCreatePackage(
      {
        countryCode: Number(benefitImplementation.countryCode),
        clientInternalNumberGOS: Number(
          benefitImplementation.clientInternalNumberGos
        ),
        benefitCategoryTag: Number(benefitImplementation.benefitCategoryTag),
        benefitImplementationTag:
          benefitImplementation.benefitImplementationTag,
        payload: {
          ...benefitImplementation,
          ...values,
          currencyCode: formatSelectFieldForPayload({
            values,
            field: "currencyCode",
          }),
          eligibleFromType: formatSelectFieldForPayload({
            values,
            field: "eligibleFromType",
          }),
          eligibleFromValue: Number(values.eligibleFromValue),

          paymentMethod: formatSelectFieldForPayload({
            values,
            field: "paymentMethod",
          }),
          paymentType: formatSelectFieldForPayload({
            values,
            field: "paymentType",
          }),
          eligibleFromDateType: formatSelectFieldForPayload({
            values,
            field: "eligibleFromDateType",
          }),
          eligibleToDateType: formatSelectFieldForPayload({
            values,
            field: "eligibleToDateType",
          }),
          eligibleToType: formatSelectFieldForPayload({
            values,
            field: "eligibleToType",
          }),
          offboardingType: formatSelectFieldForPayload({
            values,
            field: "offboardingType",
          }),
          offboardingDateType: formatSelectFieldForPayload({
            values,
            field: "offboardingDateType",
          }),
          offboardingValue: Number(values.offboardingValue),

          //
          cancellationPossibilityType: formatSelectFieldForPayload({
            values,
            field: "cancellationPossibilityType",
          }),
          cancellationPossibilityDateType: formatSelectFieldForPayload({
            values,
            field: "cancellationPossibilityDateType",
          }),
          cancellationPossibilityValue: Number(
            values.cancellationPossibilityValue
          ),
          //

          benefitCategoryParentTag: benefitImplementation?.benefitCategoryTag,
          usingPercentagesForPaidBy:
            values?.usingPercentagesForPaidBy === EPaidBy.Percentage,
          price: Number(values.price),
          paidByCompany: Number(values.paidByCompany),
          paidByEmployee: Number(values.paidByEmployee),
          attachments: values?.attachments || [],
          backgroundPictureName: values?.coverPhoto?.name,
          backgroundPictureContent: values?.coverPhoto?.base64String,
          backgroundPictureMimeType: values?.coverPhoto?.type,
          ...(!values?.coverPhoto?.base64String && {
            backgroundPicturePath: values?.coverPhoto?.path,
          }),
          // benefitPackageTag: 0,
        },
      },
      {
        onSuccess() {
          toast.success(t("hbh.packageCreatedSuccessfully.label"));
          setOpen(false);
        },
      }
    );
  };

  return (
    <>
      <Dialog
        modalType="alert"
        open={open}
        onOpenChange={(event, data) => setOpen(data.open)}
      >
        <StyledDialogSurface
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {(updateInProgress || createInProgress) && <AppSpinner />}
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {() => {
              return (
                <Form>
                  <DialogBody className={styles.content}>
                    <DialogTitle
                      action={
                        <DialogTrigger action="close">
                          <Button
                            appearance="subtle"
                            aria-label="close"
                            icon={<Dismiss24Regular />}
                          />
                        </DialogTrigger>
                      }
                    >
                      {title}
                    </DialogTitle>
                    <DialogContent className={styles.content}>
                      <PackageFormItems packageData={packageData} />
                    </DialogContent>
                    <DialogActions>
                      <PrimaryButton
                        style={{
                          paddingLeft: "25px",
                          paddingRight: "25px",
                        }}
                      >
                        {t("greco.save")}
                      </PrimaryButton>
                    </DialogActions>
                  </DialogBody>
                </Form>
              );
            }}
          </Formik>
        </StyledDialogSurface>
      </Dialog>
    </>
  );
};

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 1200px;
    min-width: 1200px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
