import { IBenefitPackageView, SelectItem } from "library/types";

export type PackageOptionType = IBenefitPackageView &
  SelectItem & {
    isFixed?: boolean;
    isCategory?: boolean;
    isImplementation?: boolean;
    isPackage?: boolean;
    isDisabled?: boolean;
    level: number;
  };

export const formatPackageOptions = (
  options: (IBenefitPackageView & SelectItem)[]
) => {
  const categories = {};
  const transformedData: PackageOptionType[] = [];
  options.forEach((item) => {
    const categoryKey = `category-${item.benefitCategoryTag}`;

    // If the category doesn't exist, create it
    if (!categories[categoryKey]) {
      categories[categoryKey] = {
        ...item,

        isFixed: true,
        isCategory: true,
        isDisabled: true,
        level: 0,
        implementations: {},
      };
    }

    // Step 2: Group by benefitImplementationTag within each category
    const implementationKey = `implementation-${item.benefitImplementationTag}`;

    if (!categories[categoryKey].implementations[implementationKey]) {
      categories[categoryKey].implementations[implementationKey] = {
        ...item,

        isImplementation: true,
        level: 1,
        isDisabled: true,
        packages: [],
      };
    }

    // Step 3: Add the benefitPackageTag as level 2 under the implementation
    categories[categoryKey].implementations[implementationKey].packages.push({
      ...item,

      level: 2,
      isPackage: true,
    });
  });

  // Step 4: Flatten the structure into the desired format
  Object.values(categories).forEach((category: any) => {
    transformedData.push({
      ...category,
      isFixed: category.isFixed,
      isCategory: category.isCategory,
      level: category.level,
    });

    Object.values(category.implementations).forEach((implementation: any) => {
      transformedData.push({
        ...implementation,
        isImplementation: implementation.isImplementation,
        level: implementation.level,
      });

      implementation.packages.forEach((pkg) => {
        transformedData.push(pkg);
      });
    });
  });
  return transformedData;
};
