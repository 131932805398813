import { TableCellLayout } from "@fluentui/react-components";
import { ChevronDownIcon, ChevronRightIcon } from "@fluentui/react-icons-mdl2";
import { EmployeeRowItem } from "library/types";
import styled from "styled-components";

import { useRef } from "react";
import { AvatarWithPreview } from "../../../../../../../components/AvatarWithPreview/AvatarWithPreview";

type Props = {
  item: EmployeeRowItem;
};
export function EmployeeWithDependentsCell({ item }: Props) {
  const employeeFullName = `${item.firstName} ${item.lastName}`;
  const elementRef = useRef<HTMLDivElement>(null);

  const hasDetails = item.enrollments?.length || item.dependentList?.length;

  const personStatusBadge = item?.isInactive ? "offline" : "available";
  return (
    <Container
      ref={elementRef}
      onClick={() => {
        if (hasDetails) {
          item.updateState({
            ...item,
            expanded: !item.expanded,
          });
        }
      }}
    >
      <NameWrap>
        <AvatarWithPreview
          name={employeeFullName}
          imgSrc={item?.backgroundPicturePath}
          badge={{ status: personStatusBadge }}
        />

        <TableCellLayout truncate>{employeeFullName}</TableCellLayout>

        {hasDetails ? (
          <IconWrap>
            {/* {item.expanded && item.selected ? ( */}
            {/* {item.expanded  */}
            {item.expanded && item.selected ? (
              <ChevronDownIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconWrap>
        ) : null}
      </NameWrap>
    </Container>
  );
}

const Container = styled.div`
  .sub-component-row {
    background-color: ${({ theme }) => theme.palette.red} !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  padding: 2px;
  width: 100%;
`;

const NameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
`;
