import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IBenefitCategory } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateBenefitCategories } from "./useGetBenefitCategories";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  payload: IBenefitCategory;
};

const updateBenefitCategory = async ({
  countryCode,
  benefitCategoryTag,
  clientInternalNumberGOS,
  payload,
}: Params): Promise<IBenefitCategory> => {
  const response = await api.patch(
    `/benefitcategory/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}`,
    {
      ...payload,
    }
  );

  return response.data as IBenefitCategory;
};

export function useUpdateBenefitCategory(refetch = true) {
  const queryClient = useQueryClient();

  return useMutation(updateBenefitCategory, {
    mutationKey: MUTATION_KEYS.updateBenefitCategory,
    onSuccess: () => {
      if (refetch) {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.benefitCategories,
        });
      }
      // queryClient.refetchQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error: AxiosError) => {
      toast.error(error?.message);
    },
  });
}
