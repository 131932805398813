import { Input, InputProps, Spinner, useId } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";

type Props = {
  name: string;
  label: string;
  required?: boolean;
  onActionBtnClick?: () => void;
  isLoading?: boolean;
  hasClear?: boolean;
  info?: string;
  value: string;
  setFieldValue: (name: string, value: string) => void;
  error?: string;
  noLabel?: boolean;
  noErrorSection?: boolean;
  type?:
    | "number"
    | "search"
    | "time"
    | "text"
    | "email"
    | "password"
    | "tel"
    | "url"
    | "date"
    | "datetime-local"
    | "month"
    | "week";
} & InputProps;

const InputField = ({
  name,
  label,
  required = false,
  type = "text",
  hasClear,
  isLoading,
  error,
  setFieldValue,
  value,
  noLabel,
  noErrorSection,
  info,
  ...props
}: Props) => {
  const inputId = useId(name);

  const onChange = (event, data) => {
    setFieldValue(name, data.value);
  };

  return (
    <FormFieldContainer>
      {noLabel ? null : (
        <FormFieldLabel label={label} required={required} info={info} />
      )}
      <Input
        type={type}
        appearance="underline"
        id={inputId}
        name={name}
        onChange={onChange}
        value={value || ""}
        contentAfter={
          isLoading ? (
            <Spinner />
          ) : hasClear && value ? (
            <DismissRegular
              style={{
                cursor: value ? "pointer" : "inherit",
                opacity: value ? "1" : "0.5",
              }}
              onClick={() => {
                if (value) {
                  setFieldValue(name, "");
                }
              }}
            />
          ) : null
        }
        {...props}
      />
      {noErrorSection ? null : (
        <ErrorMessageContainer>
          <StyledErrorMessage>{error}</StyledErrorMessage>
        </ErrorMessageContainer>
      )}
    </FormFieldContainer>
  );
};

export default InputField;
