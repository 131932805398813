import { AddAttachments } from "components/AddAttachments/AddAttachments";
import { MultiSelectCombobox } from "components/controls/MultiSelectCombobox";
import { RadioGroupField } from "components/controls/RadioGroupField";
import { TextareaField } from "components/controls/TextareaField";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { checkedOptions } from "library/constants";
import { ETaxonomy } from "library/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BenefitCategoryNameField } from "./BenefitCategoryNameField";
import { UploadFile } from "./UploadFile";

type Props = {
  isAddCategory?: boolean;
  countryCode?: number | string;
  clientInternalNumberGOS?: number;
  rootCountryCode?: boolean;
};

export function BenefitFormItems({
  isAddCategory,
  countryCode,
  clientInternalNumberGOS,
  rootCountryCode,
}: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);

  const consentValues = useMemo(() => {
    return consentOptions.filter((option) => {
      return values["consentTypes"]?.includes(option.value);
    });
  }, [consentOptions, values]);

  // const uploadDocuments = values["uploadDocuments"] === ECheckedValue.Yes;

  return (
    <Container>
      <Grid>
        <BenefitCategoryNameField
          isAddCategory={isAddCategory}
          countryCode={countryCode}
          clientInternalNumberGOS={clientInternalNumberGOS}
          rootCountryCode={rootCountryCode}
        />

        <MultiSelectCombobox
          required
          key={values["benefitCategory"]}
          error={errors["consentTypes"]}
          setFieldValue={setFieldValue}
          value={consentValues as any}
          label={t("hbh.typeOfConsent.label")}
          options={consentOptions}
          name={"consentTypes"}
          // required
        />
        <UploadFile
          key={values["coverPhoto"]?.path}
          setFieldValue={setFieldValue}
          fieldName="coverPhoto"
          defaultValue={values["coverPhoto"]}
          label={t("hbh.chooseCoverPicture.label")}
        />
      </Grid>
      <Grid>
        <RadioGroupField
          name="uploadDocuments"
          value={values["uploadDocuments"]}
          setFieldValue={setFieldValue}
          label={t("hbh.uploadDocuments.label")}
          options={checkedOptions}
          // required
        />
        <RadioGroupField
          value={values["consentsNeeded"]}
          setFieldValue={setFieldValue}
          name="consentsNeeded"
          label={t("hbh.consentNeeded.label")}
          options={checkedOptions}
          // required
        />

        <RadioGroupField
          value={values["descriptionAsDocument"]}
          setFieldValue={setFieldValue}
          name="descriptionAsDocument"
          label={t("hbh.descriptionAsDocument.label")}
          options={checkedOptions}
          // required
        />
      </Grid>
      <Grid gridColumns={1}>
        <RadioGroupField
          value={values["enrollmentProcessNeeded"]}
          setFieldValue={setFieldValue}
          name="enrollmentProcessNeeded"
          label={t("hbh.enrollmentProcessNeeded.label")}
          options={checkedOptions}
          // required
        />
      </Grid>
      <TextareaField
        required
        label={t("hbh.description.label")}
        name="description"
        value={values["description"]}
        setFieldValue={setFieldValue}
        error={errors["description"]}
        rows={3}
        resize="vertical"
      />
      <AddAttachments />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
