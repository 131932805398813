import { Switch } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

export function OnlyActiveSwitch({ value, setValue }) {
  const { t } = useTranslation();
  return (
    <div>
      <Switch
        checked={value}
        onChange={(ev) => {
          setValue(ev.currentTarget.checked);
        }}
        label={t("hbh.onlyActive.label")}
      />
    </div>
  );
}
