import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployeeEnrollment } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IEmployeeEnrollment;
  employeeEmail: string;
};

const createEmployeeEligibility = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  payload,
}: Params) => {
  const response = await api.post(
    `/employeeenrollment/${countryCode}/${clientInternalNumberGOS}`,
    // `/employeeenrollment/${countryCode}/${clientInternalNumberGOS}/${employeeEmail}`,
    payload
  );
  return response.data as IEmployeeEnrollment;
};

export function useCreateEmployeeEligibility(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();

  return useMutation(createEmployeeEligibility, {
    mutationKey: MUTATION_KEYS.createEmployeeEligibility,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEligibility,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeDetails,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
