import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  TableCellLayout,
  Text,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  NotepadPerson24Regular,
} from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import { Formik } from "formik";
import { EMAIL_ID_SEPARATOR, formatDateFieldForPayload } from "library/utils";
import { useCreateEmployeeEligibilities } from "state/queries/useCreateEmployeeEligibilities";
import { selectedCompanyState } from "store/UIHrPage";
import EmployeeEligibilityFormFields from "./EmployeeEligibilityFormFields";
import { useEffect, useState } from "react";

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
  employeeItems?: any[];
  enrollmentItems?: any[];
  benefitItems?: any[];
};

export function EmployeeEligibilityFormDialog({
  open,
  setOpen,
  employeeItems,
  enrollmentItems,
  benefitItems,
}: Props) {
  const selectedCompany = useAtomValue(selectedCompanyState);
  const [operationResult, setOperationResult] = useState<any>(null);
  const [showOperationResult, setShowOperationResult] = useState(false);

  const { mutate: onCreateEligibilities, isLoading: createInProgress } =
    useCreateEmployeeEligibilities();

  const styles = useStyles();

  useEffect(() => {
    if (open) {
      setShowOperationResult(false);
      setOperationResult(null);
    }
  }, [open]);

  const handleSubmit = (values) => {
    if (!benefitItems) return;
    let payloadItems: any[] = [];
    benefitItems?.forEach((benefitItem, index) => {
      employeeItems?.forEach((employee) => {
        const [employeeLoginEmailAddress, employeeDependentTag] =
          employee.split(EMAIL_ID_SEPARATOR);
        const newEnrollment = enrollmentItems?.find((enrollment) => {
          return (
            enrollment.parentId === benefitItem + "" &&
            enrollment.id === employee
          );
        });
        if (newEnrollment) {
          payloadItems.push({
            benefitPackageTag: Number(benefitItem),
            dateOfEligibility:
              formatDateFieldForPayload(values["dateOfEligibility"]) ?? null,
            endDateOfEligibility:
              formatDateFieldForPayload(values["endDateOfEligibility"]) ?? null,
            cancellationPossibility:
              formatDateFieldForPayload(values["cancellationPossibility"]) ??
              null,
            isAutoEnrollment: values["isAutoEnrollment"],
            employeeLoginEmailAddress: employeeLoginEmailAddress,
            employeeDependentTag: employeeDependentTag ?? null,
          });
        }
      });
    });
    onCreateEligibilities(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        payload: payloadItems,
      },
      {
        onSuccess: (data: any) => {
          setOperationResult(data);
          toast.success("Employee eligibilities updated successfully");

          //setOpen(false);
        },
      }
    );
    return;
  };

  const title = operationResult
    ? "Operation completed successfully"
    : (enrollmentItems ?? [])?.length === 0
    ? `No appropriate employees selected`
    : `Assign ${(enrollmentItems ?? [])?.length} eligibilities to ${
        benefitItems?.length
      } package(s)`;

  const validationSchema = yup.object().shape({
    isAutoEnrollment: yup.boolean().required(),
    dateOfEligibility: yup
      .mixed()
      .when("isAutoEligibility", (isAutoEligibility, schema) => {
        return isAutoEligibility[0]
          ? schema.nullable() // When isAutoEligibility is true, dateOfEligibility is not required
          : schema.required();
      }),
    endDateOfEligibility: yup
      .mixed()
      .when("isAutoEligibility", (isAutoEligibility, schema) => {
        return isAutoEligibility[0]
          ? schema.nullable() // When isAutoEligibility is true, dateOfEligibility is not required
          : schema.required();
      }),
    cancellationPossibility: yup
      .mixed()
      .when("isAutoEligibility", (isAutoEligibility, schema) => {
        return isAutoEligibility[0]
          ? schema.nullable() // When isAutoEligibility is true, dateOfEligibility is not required
          : schema.required();
      }),
  });

  const initialValues = {
    isAutoEnrollment: false,
    isAutoEligibility: false,
    dateOfEligibility: null,
    endDateOfEligibility: null,
    cancellationPossibility: null,
  };

  const submitLabel = "Make Eligible";
  if (!benefitItems) return null;
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({ values, errors, setFieldValue, submitForm }) => {
        return (
          <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(event, data) => setOpen(data.open)}
          >
            <StyledDialogSurface
              // style={{
              //   bottom: "10%",
              // }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {createInProgress && <AppSpinner />}

              <DialogBody className={styles.content}>
                <DialogTitle
                  action={
                    <DialogTrigger action="close">
                      <Button
                        appearance="subtle"
                        aria-label="close"
                        icon={<Dismiss24Regular />}
                      />
                    </DialogTrigger>
                  }
                >
                  <Flex width={"100%"} justify="flex-start">
                    <NotepadPerson24Regular />
                    <TableCellLayout truncate>{title}</TableCellLayout>
                  </Flex>
                </DialogTitle>
                <DialogContent className={styles.content}>
                  {showOperationResult ? (
                    <code
                      dangerouslySetInnerHTML={{
                        __html: operationResult,
                      }}
                    ></code>
                  ) : operationResult ? null : (
                    <EmployeeEligibilityFormFields />
                  )}
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">Close</Button>
                  </DialogTrigger>
                  {!showOperationResult && (
                    <PrimaryButton
                      disabled={
                        createInProgress ||
                        (!operationResult &&
                          (enrollmentItems ?? [])?.length === 0)
                      }
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                      onClick={() => {
                        operationResult
                          ? setShowOperationResult(true)
                          : submitForm();
                      }}
                    >
                      {operationResult ? "Show results" : submitLabel}
                    </PrimaryButton>
                  )}
                </DialogActions>
              </DialogBody>
            </StyledDialogSurface>
          </Dialog>
        );
      }}
    </Formik>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 800px;
    min-width: 800px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "800px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
