import { Button } from "@fluentui/react-components";
import {
  Add16Filled,
  DataTrendingRegular,
  RibbonRegular,
} from "@fluentui/react-icons";
import { BenefitCategoryDialog } from "components/BenefitCategoryDialog/BenefitCategoryDialog";
import { StyledTab, StyledTabList, ToolbarContainer } from "components/styled";
import { useAtom } from "jotai";
import { EAdminTabView } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectedAdminTabState } from "store";
import styled from "styled-components";

export function CategoryToolbar() {
  const { t } = useTranslation();
  const [addBenefitOpen, setAddBenefitOpen] = useState(false);
  const handleAddBenefitDialogState = useCallback((state) => {
    setAddBenefitOpen(state);
  }, []);

  const [selectedAdminPageTab, setSelectedAdminPageTab] = useAtom(
    selectedAdminTabState
  );

  return (
    <ToolbarContainer>
      <LeftItems>
        <StyledTabList
          selectedValue={selectedAdminPageTab}
          onTabSelect={(e, data) => {
            setSelectedAdminPageTab(data.value as EAdminTabView);
          }}
        >
          <StyledTab icon={<RibbonRegular />} value={EAdminTabView.benefits}>
            {t("hbh.benefits.label")}
          </StyledTab>
          <StyledTab
            icon={<DataTrendingRegular />}
            value={EAdminTabView.analytics}
          >
            {t("hbh.analytics.label")}
          </StyledTab>
        </StyledTabList>
      </LeftItems>
      <RightItems>
        <AddCategoryButton
          appearance="transparent"
          icon={<Add16Filled />}
          onClick={() => {
            setAddBenefitOpen(true);
          }}
        >
          {t("hbh.addBenefitCategory.label")}
        </AddCategoryButton>
      </RightItems>

      {addBenefitOpen && (
        <BenefitCategoryDialog
          countryCode={0}
          open={addBenefitOpen}
          setOpen={handleAddBenefitDialogState}
        />
      )}
    </ToolbarContainer>
  );
}

const LeftItems = styled.div`
  display: flex;
  align-items: center;
`;
const RightItems = styled.div`
  display: flex;
  align-items: center;
`;
const AddCategoryButton = styled(Button)`
  max-width: fit-content;
  float: right;

  color: ${(props) => props.theme.palette.themePrimary};
`;
