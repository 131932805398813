import { Button } from "@fluentui/react-components";
import { PersonEdit24Regular } from "@fluentui/react-icons";
import { PeopleAddIcon } from "@fluentui/react-icons-mdl2";
import { Flex, StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { EmployeeRowItem } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetEmployeeDetails } from "state/queries/useGetEmployeeDetails";
import { EmployeeFormDialog } from "./EmployeeFormDialog";
import styled from "styled-components";
import { useGetDependentDetails } from "state/queries/useGetDependentDetails";
import { AppSpinner } from "components/AppSpinner/AppSpinner";

type Props = {
  disabled?: boolean;
  item?: EmployeeRowItem;

  isAddEmployee?: boolean;
};

export function ManageEmployee({ isAddEmployee, disabled, item }: Props) {
  const { palette } = useAppTheme();
  const { t } = useTranslation();

  const [employeeDetailsOpen, setEmployeeDetailsOpen] = useState(false);

  const { data: employeeData, isLoading: employeeLoading } =
    useGetEmployeeDetails({
      countryCode: Number(item?.countryCode),
      clientInternalNumberGOS: Number(item?.clientInternalNumberGos),
      employeeEmail: item?.employeeLoginEmailAddress || "",
      enabled: employeeDetailsOpen && !isAddEmployee && !item?.isDependent,
    });

  const { data: dependentData, isLoading: dependentLoading } =
    useGetDependentDetails({
      countryCode: Number(item?.countryCode),
      clientInternalNumberGOS: Number(item?.clientInternalNumberGos),
      employeeLoginEmailAddress: item?.employeeLoginEmailAddress || "",
      employeeDependentTag: Number(item?.employeeDependentTag),
      enabled: employeeDetailsOpen && !isAddEmployee && item?.isDependent,
    });

  const handleDialogStateChange = useCallback(() => {
    setEmployeeDetailsOpen((prev) => !prev);
  }, []);

  if (employeeLoading || dependentLoading) return <AppSpinner />;

  const packageUserData = item?.isDependent ? dependentData : employeeData;

  return (
    <>
      <Flex width={"100%"} justify="flex-end">
        {isAddEmployee ? (
          <ActionButton
            appearance="transparent"
            icon={<PeopleAddIcon />}
            onClick={() => {
              setEmployeeDetailsOpen(true);
            }}
          >
            {t("hbh.addEmployee.label")}
          </ActionButton>
        ) : (
          <StyledTooltip
            relationship="label"
            content={{
              children: t("hbh.edit.label"),
            }}
          >
            <Button
              icon={
                <PersonEdit24Regular
                  style={{
                    color: palette.themePrimary,
                    transform: "scale(1.1)",
                  }}
                />
              }
              disabled={disabled}
              appearance="transparent"
              size="small"
              onClick={() => {
                setEmployeeDetailsOpen(true);
              }}
            />
          </StyledTooltip>
        )}
      </Flex>
      {employeeDetailsOpen && (
        <EmployeeFormDialog
          item={(isAddEmployee ? item : packageUserData) as EmployeeRowItem}
          open={employeeDetailsOpen}
          setOpen={handleDialogStateChange}
          isAddEmployee={isAddEmployee}
        />
      )}
    </>
  );
}

const ActionButton = styled(Button)`
  max-width: fit-content;
  color: ${(props) => props.theme.palette.themePrimary};
`;
