import { QUERY_KEYS } from "library/shared";
import { IAuthorization } from "library/types";
import { handleAxiosError } from "library/utils";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

const getAthorization = async () => {
  const response = await api.get(`/authorizationinfo`);

  return response.data;
};

export function useGetAuthorization(
  enabled = true
): UseQueryResult<IAuthorization> {
  return useQuery<IAuthorization, Error>(
    [QUERY_KEYS.authorization],
    getAthorization,
    {
      enabled: enabled,
      onError: (error: any) => {
        // errorHandler({
        //   label: "Error",
        //   type: notificationTypes.error,
        //   value: "Error while loading offer item data",
        //   errors: error?.message,
        //   fieldName: "",
        // });
        handleAxiosError(error);
      },
    }
  );
}
