import { useAtomValue } from "jotai";
import { EManageHrTabView } from "library/types";
import { selectedHrTabState } from "store";
import styled from "styled-components";
import BulkManagementView from "../BulkManagementView/BulkManagementView";
import { EmployeeView } from "./EmployeesView/EmployeeView";
import { ImplementationsHrView } from "./ImplementationsHrView/ImplementationsHrView";
import { SummaryView } from "./SummaryView/SummaryView";
import { SummaryViewPbi } from "./SummaryView/SummaryViewPbi";
import { ActionHistoryView } from "./ActionHistoryView/ActionHistoryView";

export function ManagePageView() {
  const selectedTab = useAtomValue(selectedHrTabState);

  return (
    <div>
      <TabContent>
        {selectedTab === EManageHrTabView.summary && <SummaryView />}
        {selectedTab === EManageHrTabView.summaryPbi && <SummaryViewPbi />}
        {selectedTab === EManageHrTabView.benefits && <ImplementationsHrView />}
        {selectedTab === EManageHrTabView.employees && <EmployeeView />}
        {selectedTab === EManageHrTabView.bulk && <BulkManagementView />}
        {selectedTab === EManageHrTabView.actionHistory && (
          <ActionHistoryView />
        )}
      </TabContent>
    </div>
  );
}

const TabContent = styled.div`
  // margin-top: 20px;
`;
