import {
  add,
  addMonths,
  addYears,
  getDaysInMonth,
  isAfter,
  isBefore,
  startOfYear,
} from "date-fns";
import { IBenefitPackage, IBenefitPackageView } from "library/types";

enum DateOrigin {
  FROM = 1,
  TO = 2,
  CANCELLATION = 3,
}

enum EEligibleDateType {
  START_OF_EMPLOYMENT = 1,
  START_OF_POLICY = 2,
  // END_OF_EMPLOYMENT = "EligibleDateType.END_OF_EMPLOYMENT",
  END_OF_POLICY = 4,
}

enum EEligibilityType {
  IMMEDIATE = 1,
  DAYS = 2,
  MONTHS = 3,
  YEARS = 4,
  DATE_IN_NEXT_MONTH = 5,
  BEGINNING_OF_NEXT_MONTH = 6,
  BEGINNING_OF_NEXT_YEAR = 7,
  INDIVIDUAL = 8,
  DATE_IN_PREVIOUS_MONTH = 9,
  BEGINNING_OF_PREVIOUS_MONTH = 10,
}

export function useCalculateDates(
  packageData?: IBenefitPackageView,
  employeeDateOfEmployment?: string,
  employeeDateOfExit?: string
) {
  const calculatedDates = fillInEligibilityDates({
    bp: packageData as IBenefitPackage,
    dateOfEmployment: employeeDateOfEmployment || "",
    validFrom: packageData?.validFrom || "",
    validTo: packageData?.validTo || "",
  });

  // return dateToSuggest;
  return {
    startEligibilityDate: calculatedDates.dateOfEligibility,
    endEligibilityDate: calculatedDates.endDateOfEligibility,
    cancellationPossibility: calculatedDates.cancellationPossibility,
  };
}

type Props = {
  dateOfEmployment: string;
  validFrom: string;
  validTo: string;
  bp: IBenefitPackageView;
};

export const fillInEligibilityDates = ({
  dateOfEmployment,
  validFrom,
  validTo,
  bp,
}: Props): {
  dateOfEligibility: string;
  endDateOfEligibility: string;
  cancellationPossibility: string;
} => {
  if (!bp)
    return {
      dateOfEligibility: "",
      endDateOfEligibility: "",
      cancellationPossibility: "",
    };
  const result = {} as any;
  result.dateOfEligibility = CalculateEligibilityDate(
    dateOfEmployment,
    validFrom,
    validTo,
    bp.eligibleFromType,
    bp.eligibleFromDateType,
    bp.eligibleFromValue,
    DateOrigin.FROM
  );
  result.endDateOfEligibility = CalculateEligibilityDate(
    dateOfEmployment,
    validFrom,
    validTo,
    bp.eligibleToType,
    bp.eligibleToDateType,
    bp.eligibleToValue,
    DateOrigin.TO
  );
  result.cancellationPossibility = CalculateEligibilityDate(
    dateOfEmployment,
    validFrom,
    validTo,
    bp.cancellationPossibilityType,
    bp.cancellationPossibilityDateType,
    bp.cancellationPossibilityValue,
    DateOrigin.CANCELLATION
  );
  result.cancellationPossibility = isAfter(
    result.cancellationPossibility || "",
    result.dateOfEligibility || ""
  )
    ? result.cancellationPossibility
    : result.dateOfEligibility;
  if (
    isAfter(result.dateOfEligibility || "", result.endDateOfEligibility || "")
  ) {
    console.log(
      "Employee is not eligible for the package since date of eligibility {employeeEnrollmentDto.DateOfEligibility.Value.ToString(CommonConstants.DATE_FORMAT_ddMMyyyy)} is after end date of eligibility {employeeEnrollmentDto.EndDateOfEligibility.Value.ToString(CommonConstants.DATE_FORMAT_ddMMyyyy)}"
    );
  }
  return result;
};

const CalculateEligibilityDate = (
  dateOfEmployment: string,
  validFromPolicy: string,
  validToPolicy: string | Date,
  eligibilityType: any,
  eligibilityDateType: any,
  eligibilityValue: any,
  dt: DateOrigin
) => {
  const firstPossibleValidFrom = isAfter(validFromPolicy, dateOfEmployment)
    ? validFromPolicy
    : dateOfEmployment;
  let eligibilityDate =
    dt === DateOrigin.FROM ? firstPossibleValidFrom : validToPolicy;

  if (isAfter(eligibilityDate, validToPolicy)) {
    // this is a special case when employee is employed after the policy is valid
    return null;
  }
  if (eligibilityType === EEligibilityType.INDIVIDUAL) {
    return null;
  }

  if (eligibilityType == null) {
    if (dt === DateOrigin.FROM) {
      return firstPossibleValidFrom;
    } else {
      return validToPolicy;
    }
  }

  //

  let referentDate: string | Date = "";
  let reverseCalculation = false;
  if (eligibilityDateType === EEligibleDateType.START_OF_EMPLOYMENT) {
    referentDate = dateOfEmployment;
  } else if (eligibilityDateType === EEligibleDateType.START_OF_POLICY) {
    referentDate = validFromPolicy;
  } else if (eligibilityDateType === EEligibleDateType.END_OF_POLICY) {
    referentDate = validToPolicy;
    reverseCalculation = true;
  }
  if (referentDate === null) {
    return null;
  }
  eligibilityDate = referentDate; //???
  if (eligibilityDateType !== EEligibilityType.IMMEDIATE) {
    if (eligibilityValue == null) {
      return null;
    }

    let coefficient = reverseCalculation ? -1 : 1;
    if (eligibilityType === EEligibilityType.DAYS) {
      eligibilityDate = add(eligibilityDate, {
        days: eligibilityValue * coefficient,
      });
    } else if (eligibilityType === EEligibilityType.MONTHS) {
      eligibilityDate = addMonths(
        eligibilityDate,
        eligibilityValue * coefficient
      );
    } else if (eligibilityType === EEligibilityType.YEARS) {
      eligibilityDate = add(eligibilityDate, {
        years: eligibilityValue * coefficient,
      });
    } else if (
      eligibilityType === EEligibilityType.DATE_IN_NEXT_MONTH ||
      eligibilityType === EEligibilityType.DATE_IN_PREVIOUS_MONTH ||
      eligibilityType === EEligibilityType.BEGINNING_OF_NEXT_MONTH ||
      eligibilityType === EEligibilityType.BEGINNING_OF_PREVIOUS_MONTH
    ) {
      let isPrevious =
        eligibilityType === EEligibilityType.DATE_IN_PREVIOUS_MONTH ||
        eligibilityType === EEligibilityType.BEGINNING_OF_PREVIOUS_MONTH;
      coefficient = isPrevious ? -1 : 1;
      if (
        isPrevious &&
        eligibilityDateType !== EEligibleDateType.END_OF_POLICY
      ) {
        return null;
      }
      eligibilityDate = addMonths(eligibilityDate, 1 * coefficient);
      if (
        eligibilityType === EEligibilityType.BEGINNING_OF_NEXT_MONTH ||
        eligibilityType === EEligibilityType.BEGINNING_OF_PREVIOUS_MONTH
      ) {
        eligibilityDate = new Date(
          new Date(eligibilityDate).getFullYear(),
          new Date(eligibilityDate).getMonth(),
          1
        );
      } else {
        // max days in month
        let maxDays = getDaysInMonth(eligibilityDate);
        if (eligibilityValue < 1 || eligibilityValue > maxDays) {
          return null;
        }
        // eligibilityDate = new Date(eligibilityDate.Year, eligibilityDate.Month, eligibilityValue.Value);
      }
    } else if (eligibilityType === EEligibilityType.BEGINNING_OF_NEXT_YEAR) {
      eligibilityDate = startOfYear(addYears(eligibilityDate, 1)); // Get January 1st of next year
    }
  }

  if (isBefore(eligibilityDate, validFromPolicy)) {
    eligibilityDate = validFromPolicy;
  }
  if (dt === DateOrigin.FROM && isBefore(eligibilityDate, dateOfEmployment)) {
    eligibilityDate = dateOfEmployment;
  }
  if (isAfter(eligibilityDate, validToPolicy)) {
    if (dt !== DateOrigin.FROM) {
      eligibilityDate = validToPolicy;
    } else {
      return null;
    }
  }
  return eligibilityDate;
};
