import { useIsMobile } from "hooks/useIsMobile";
import { FormDemo } from "./components/FormDemo";
import { TableDemo } from "./components/TableDemo/TableDemo";
import styled from "styled-components";

export function DemoPage(props) {
  const isMobile = useIsMobile();

  return (
    <Container>
      <FormDemo />
      <TableDemo />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 16px;
  max-width: 80%;
  margin: auto;
`;
