import { MultiSelectCombobox } from "components/controls/MultiSelectCombobox";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy, IRowItem } from "library/types";
import { useMemo, useState } from "react";

type Props = {
  item: IRowItem;
};

export function TypeOfConsentCell({ item }: Props) {
  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);
  const [updatedValue, setUpdatedValue] = useState<number[] | null>(null);

  const defaultValue = useMemo(() => {
    return consentOptions.filter((option) => {
      return item?.consentTypes?.includes(option.value);
    });
  }, [consentOptions, item.consentTypes]);

  return (
    <MultiSelectCombobox
      onClick={(e) => {
        e.stopPropagation();
      }}
      key={item.consentTypes?.join(",")}
      error={""}
      onBlur={() => {
        if (!updatedValue) return;
        item.updateState({
          ...item,
          isEdited: true,

          consentTypes: updatedValue,
        });
      }}
      setFieldValue={(name, value) => {
        const ids = value.map((v) => v.value);

        // item.updateState({
        //   ...item,
        //   consentTypes: ids as number[],
        // });
        setUpdatedValue(ids as number[]);
      }}
      value={defaultValue as any}
      // value={localState as any}

      label=""
      options={consentOptions}
      name={"consentTypes"}
      noErrorSection
      noLabel
    />
  );
}
