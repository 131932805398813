import { Text, Tooltip } from "@fluentui/react-components";

type Props = {
  text?: string;
};

export const BenefitInfoText = ({ text }: Props) => {
  return (
    <Tooltip relationship="description" content={text || ""}>
      <Text
        truncate
        block
        weight="semibold"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {text}
      </Text>
    </Tooltip>
  );
};
