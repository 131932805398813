import { Divider } from "@fluentui/react-components";
import { AddAttachments } from "components/AddAttachments/AddAttachments";
import CheckboxField from "components/controls/CheckboxField";
import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy, IBenefitPackage } from "library/types";
import { DocumentsCell } from "pages/CompanyPackagesPage/components/BenefitImplementationPackages/DocumentsCell";
import { IPackageRowItem } from "pages/CompanyPackagesPage/components/BenefitImplementationPackages/shared";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EligibleFields } from "./EligibleFields";
import { OffboardingFields } from "./OffboardingFields";
import { PriceFields } from "./PriceFields";
import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import { TextareaField } from "components/controls/TextareaField";
import { CancellationFields } from "./CancellationFields";

type Props = {
  packageData?: IBenefitPackage;
};

export function PackageFormItems({ packageData }: Props) {
  const { t } = useTranslation();
  const paymentTypeOptions = useTaxonomyOptions(ETaxonomy.PaymentType);

  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  return (
    <Container>
      <Grid gridColumns={4}>
        <InputField
          required
          label={t("greco.name")}
          name="name"
          value={values["name"]}
          setFieldValue={setFieldValue}
          error={errors["name"]}
        />
        <InputField
          required
          label={t("hbh.carrier.label")}
          name="carrierName"
          value={values["carrierName"]}
          setFieldValue={setFieldValue}
          error={errors["carrierName"]}
        />
        <PriceFields />
        <SelectField
          required
          error={errors["paymentType"]}
          showEmptyOption
          setFieldValue={setFieldValue}
          value={values["paymentType"]}
          label={t("hbh.paymentType.label")}
          options={paymentTypeOptions}
          name={"paymentType"}
        />
        <UploadFile
          key={values["coverPhoto"]?.path}
          setFieldValue={setFieldValue}
          fieldName="coverPhoto"
          defaultValue={values["coverPhoto"]}
          label={t("hbh.chooseCoverPicture.label")}
        />
      </Grid>
      <Grid gridColumns={1}>
        <TextareaField
          label={t("hbh.description.label")}
          name="description"
          value={values["description"]}
          setFieldValue={setFieldValue}
          error={errors["description"]}
          rows={3}
          resize="vertical"
          required
        />
      </Grid>
      <Divider appearance="subtle" />
      <Grid gridColumns={3}>
        <EligibleFields />
        <CancellationFields />
        <OffboardingFields />
      </Grid>
      <Divider appearance="subtle" />
      <CheckboxField
        key={values["isAutoEnrollment"]}
        name="isAutoEnrollment"
        required
        value={values["isAutoEnrollment"]}
        setFieldValue={setFieldValue}
        label={t("hbh.isAutoEnrollment.label")}
      />

      {!packageData ? <AddAttachments /> : <Divider />}

      {!!packageData ? (
        <>
          <Grid>
            <DocumentsCell item={packageData as IPackageRowItem} />
          </Grid>
        </>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
