import { Button } from "@fluentui/react-components";
import {
  Add16Filled,
  DataTrendingRegular,
  RibbonRegular,
} from "@fluentui/react-icons";
import { BenefitCategoryDialog } from "components/BenefitCategoryDialog/BenefitCategoryDialog";
import { StyledTab, StyledTabList, ToolbarContainer } from "components/styled";
import { useAtom, useAtomValue } from "jotai";
import { ECountryTabView, IClientAndBenefitCategories } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectedCountryTabState } from "store";
import { selectedCountryState } from "store/UICountryPage";
import styled from "styled-components";

type Props = {
  benefitCategories?: IClientAndBenefitCategories;
  countryBenefitCategories?: IClientAndBenefitCategories;
};

export function CountryPageToolbar({
  benefitCategories,
  countryBenefitCategories,
}: Props) {
  const { t } = useTranslation();
  const [addBenefitCategoryOpen, setAddBenefitCategoryOpen] = useState(false);
  const selectedCountry = useAtomValue(selectedCountryState);
  const [selectedCountryPageTab, setSelectedCountryPageTab] = useAtom(
    selectedCountryTabState
  );

  const handleAddBenefitCategoryDialogState = useCallback((state) => {
    setAddBenefitCategoryOpen(state);
  }, []);

  const hasBenefitCategoriesToAdd =
    benefitCategories?.benefitCategories?.filter((cat) => {
      return !countryBenefitCategories?.benefitCategories?.some(
        (countryCat) =>
          countryCat.benefitCategoryParentTag === cat.benefitCategoryTag
      );
    })?.length;

  return (
    <ToolbarContainer mobileTop="89px">
      <LeftItems>
        <StyledTabList
          onTabSelect={(e, data) => {
            setSelectedCountryPageTab(data.value as ECountryTabView);
          }}
          selectedValue={selectedCountryPageTab}
        >
          <StyledTab icon={<RibbonRegular />} value={ECountryTabView.benefits}>
            {t("hbh.benefits.label")}
          </StyledTab>
          <StyledTab
            icon={<DataTrendingRegular />}
            value={ECountryTabView.analytics}
          >
            {t("hbh.analytics.label")}
          </StyledTab>
        </StyledTabList>
      </LeftItems>

      <RightItems>
        <AddCategoryButton
          disabled={!selectedCountry || !hasBenefitCategoriesToAdd}
          shape="circular"
          appearance="transparent"
          icon={<Add16Filled />}
          onClick={() => {
            setAddBenefitCategoryOpen(true);
          }}
        >
          {t("hbh.addBenefitCategory.label")}
        </AddCategoryButton>
        {/* <AnalyticsButton
          appearance="transparent"
          icon={<BarChartVerticalFillIcon />}
          onClick={() => {
            setAnalyticsOpen(true);
          }}
        >
          {t("hbh.analytics.label")}
        </AnalyticsButton> */}
      </RightItems>
      {addBenefitCategoryOpen && (
        <BenefitCategoryDialog
          countryCode={Number(selectedCountry?.value) || 0}
          open={addBenefitCategoryOpen}
          setOpen={handleAddBenefitCategoryDialogState}
          benefitCategoryParentTag={Number(selectedCountry?.value)}
        />
      )}
    </ToolbarContainer>
  );
}

const LeftItems = styled.div`
  display: flex;
  align-items: center;
`;
const RightItems = styled.div`
  display: flex;
  align-items: center;
`;
const AddCategoryButton = styled(Button)`
  max-width: fit-content;
  color: ${(props) => props.theme.palette.themePrimary};
`;
