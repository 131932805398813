import { useCallback, useState } from "react";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;

type Props = {
  numberOfItems: number;
};

export function usePaginationConfig({ numberOfItems }: Props) {
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const totalPages = Math.round(numberOfItems / DEFAULT_PAGE_SIZE);

  const from = currentPage === 1 ? 0 : currentPage * DEFAULT_PAGE_SIZE;

  const to =
    currentPage === 1
      ? DEFAULT_PAGE_SIZE
      : currentPage * DEFAULT_PAGE_SIZE + 20;

  const handlePageChange = useCallback((value: number) => {
    setCurrentPage(value);
  }, []);

  return {
    currentPage,
    totalPages,
    from,
    to,
    handlePageChange,
  };
}
