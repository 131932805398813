import { Button, Spinner } from "@fluentui/react-components";
import { ArrowUndoFilled } from "@fluentui/react-icons";
import { EditIcon, SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PackageDialog } from "components/PackageDialog/PackageDialog";
import { ActionButton, Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IBenefitImplementation, IBenefitPackage } from "library/types";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useUpdateBenefitImplementationPackage } from "state/queries/useUpdateBenefitImplementationPackage";
import styled from "styled-components";
import { IPackageRowItem } from "./shared";
import { useTranslation } from "react-i18next";

type Props = {
  item: IPackageRowItem;

  benefitImplementation?: IBenefitImplementation;
};

export function PackageActionCell({ item, benefitImplementation }: Props) {
  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const [open, setOpen] = useState(false);

  const {
    mutate: onUpdatePackage,
    data: updatedData,
    isLoading: updateInProgress,
  } = useUpdateBenefitImplementationPackage(false);
  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  return (
    <Container>
      {!item?.isEdited ? (
        <>
          <ActionButton
            noBorder
            appearance="transparent"
            icon={<EditIcon />}
            onClick={() => {
              setOpen(true);
            }}
          />
          {open && (
            <PackageDialog
              benefitImplementation={
                benefitImplementation as IBenefitImplementation
              }
              packageData={item}
              open={open}
              setOpen={onDialogOpenChange}
            />
          )}
        </>
      ) : updateInProgress ? (
        <>
          <Spinner size="extra-small" />
          <AppSpinner />
        </>
      ) : (
        <Flex width={"100%"} justify="center">
          <Button
            icon={
              <SaveIcon
                style={{
                  ...(!item.disabled ? { color: palette.themePrimary } : {}),
                }}
              />
            }
            disabled={updateInProgress || item.disabled}
            appearance="transparent"
            size="small"
            onClick={() => {
              onUpdatePackage(
                {
                  countryCode: Number(benefitImplementation?.countryCode),
                  clientInternalNumberGOS: Number(
                    benefitImplementation?.clientInternalNumberGos
                  ),
                  benefitCategoryTag: Number(
                    benefitImplementation?.benefitCategoryTag
                  ),
                  benefitImplementationTag: Number(
                    benefitImplementation?.benefitImplementationTag
                  ),
                  benefitPackageTag: Number(item.benefitPackageTag),

                  payload: {
                    ...item,

                    ...(item.coverPhoto?.base64String && {
                      backgroundPictureContent:
                        item.coverPhoto?.base64String || null,
                      backgroundPictureName: item.coverPhoto?.name || null,
                      backgroundPicturePath: item.coverPhoto?.path || null,
                      backgroundPictureMimeType: item.coverPhoto?.type || null,
                    }),
                    ...(!item.coverPhoto?.name && {
                      backgroundPictureContent: null,
                      backgroundPictureName: null,
                      backgroundPicturePath: null,
                      backgroundPictureMimeType: null,
                    }),
                  } as IBenefitPackage,
                },
                {
                  onSuccess: (data) => {
                    const updatedItem = {
                      ...data,
                      coverPhoto: {
                        name: data.backgroundPicturePath,
                        base64String: data.backgroundPictureContent,
                        type: data.backgroundPictureMimeType,
                      },
                      defaultState: {
                        ...data,
                        id: data.benefitPackageTag,
                        coverPhoto: {
                          name: data.backgroundPicturePath,
                          base64String: data.backgroundPictureContent,
                          type: data.backgroundPictureMimeType,
                        },
                      },
                      isEdited: false,
                      rowVersion: data.rowVersion,
                      id: data.benefitPackageTag,
                    };
                    item.updateState(updatedItem as IBenefitPackage);
                    toast.success(t("hbh.packageUpdatedSuccessfully.label"));
                  },
                }
              );
            }}
          />
          <Button
            icon={
              <ArrowUndoFilled
                style={{
                  color: palette.redDark,
                }}
              />
            }
            disabled={updateInProgress}
            appearance="transparent"
            size="small"
            onClick={() => {
              const updatedRowItem = updatedData as IPackageRowItem;
              if (updatedRowItem) {
                const updatedItem = {
                  ...updatedRowItem,
                  coverPhoto: {
                    name: updatedRowItem.backgroundPicturePath || null,
                    base64String:
                      updatedRowItem.backgroundPictureContent || null,
                    type: updatedRowItem.backgroundPictureMimeType || null,
                  },
                  defaultState: {
                    ...updatedRowItem,
                    coverPhoto: {
                      name: updatedRowItem.backgroundPicturePath,
                      base64String: updatedRowItem.backgroundPictureContent,
                      type: updatedRowItem.backgroundPictureMimeType,
                    },
                    id: updatedRowItem.benefitPackageTag,
                  },
                  isEdited: false,
                  id: updatedRowItem.benefitPackageTag,
                };
                item.updateState(updatedItem, true);
                return;
              }
              const updatedItem = {
                ...item.defaultState,
                defaultState: item.defaultState,
                isEdited: false,
              } as any;
              item.updateState(updatedItem, true);
            }}
          />
        </Flex>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
