import { Divider, Skeleton, SkeletonItem } from "@fluentui/react-components";
import { BenefitSkeleton } from "components/Skeletons/BenefitSkeleton";
import { PageContent } from "components/styled";
import React from "react";
import styled from "styled-components";

type Props = {
  hideSubheader?: boolean;
  hideToolbar?: boolean;
  isListView?: boolean;
  isCardView?: boolean;
};

export function PageSkeleton({
  hideSubheader,
  hideToolbar,
  isListView,
  isCardView,
}: Props) {
  return (
    <Container>
      <Skeleton>
        {hideSubheader ? null : (
          <SkeletonItem
            size={48}
            style={{
              opacity: "0.4",
            }}
          />
        )}
        <Divider />
        {hideToolbar ? null : (
          <SkeletonItem
            size={48}
            style={{
              opacity: "0.4",
            }}
          />
        )}
      </Skeleton>
      <PageContent>
        <BenefitSkeleton isListView={isListView} isCardView={isCardView} />;
      </PageContent>
    </Container>
  );
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
