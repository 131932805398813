import { OnlyActiveSwitch } from "components/OnlyActiveSwitch/OnlyActiveSwitch";
import { CardListSkeleton } from "components/Skeletons/CardListSkeleton";
import { useRoleType } from "hooks/useRoleType";
import { useAtom, useAtomValue } from "jotai";
import { ImplementationCardList } from "pages/CompanyBenefitImplementationPage/components/ManageBenefitImplementationsView/ImplementationCardList";
import { useCallback } from "react";
import { useGetBenefitImplementations } from "state/queries/useGetBenefitImplementations";
import {
  onlyActiveImplementationsState,
  selectedCompanyState,
} from "store/UIHrPage";

export function ImplementationsHrView() {
  const selectedCompany = useAtomValue(selectedCompanyState);
  const hasHrRole = useRoleType().hasHrRole;

  const [onlyActiveImplementations, setOnlyActiveImplementation] = useAtom(
    onlyActiveImplementationsState
  );

  const { data: implementedBenefits, isLoading: implementationLoading } =
    useGetBenefitImplementations({
      countryCode: Number(selectedCompany?.countryCode),
      clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
      benefitCategoryTag: 0,
      onlyActive: onlyActiveImplementations,
    });

  const onSetOnlyActiveImplementation = useCallback(
    (value) => {
      setOnlyActiveImplementation(value);
    },
    [setOnlyActiveImplementation]
  );

  const loading = implementationLoading || !implementedBenefits;

  return (
    <>
      {loading ? (
        <CardListSkeleton cardNumber={10} />
      ) : (
        <>
          <OnlyActiveSwitch
            value={onlyActiveImplementations}
            setValue={onSetOnlyActiveImplementation}
          />
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <ImplementationCardList
              withPagination={implementedBenefits?.length > 50}
              benefitImplementations={implementedBenefits}
              previewMode={hasHrRole}
            />
          </div>
        </>
      )}
    </>
  );
}
