import { PageContent } from "components/styled";
import { columnNames } from "library/constants";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Analytics } from "components/Analytics/Analytics";
import {
  defaultColumnSizingOptions,
  getColumnsDef,
} from "components/ManageBenefitCategories/IncludedCategoriesTable/columns";
import { ManageBenefitCategories } from "components/ManageBenefitCategories/ManageBenefitCategories";
import { SelectBenefitCategoriesDialog } from "components/SelectBenefitCategoriesDialog/SelectBenefitCategoriesDialog";
import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { Subheader } from "components/Subheader/Subheader";
import { useAtom, useAtomValue } from "jotai";
import { ECountryTabView } from "library/types";
import { useTranslation } from "react-i18next";
import { useGetBenefitCategories } from "state/queries/useGetBenefitCategories";
import { selectedCountryTabState } from "store";
import {
  countryCodeState,
  filteredBenefitCategoriesState,
  searchFilterValueState,
  selectedCountryState,
} from "store/UICountryPage";
import { CountryPageToolbar } from "./CountryPageToolbar";

export function Content() {
  const { t } = useTranslation();

  const selectedCountry = useAtomValue(selectedCountryState);
  const selectedCountryPageTab = useAtomValue(selectedCountryTabState);

  const countryCode = useAtomValue(countryCodeState);
  const [selectBenefitCategoriesDialog, setSelectedBenefitCategoriesDialog] =
    useState(false);

  const [filteredBenefitCategories, setFilteredBenefitCategories] = useAtom(
    filteredBenefitCategoriesState
  );
  const [searchFilterValue, setSearchFilterValue] = useAtom(
    searchFilterValueState
  );

  const {
    data: countryBenefitCategories,
    isFetched,
    isLoading: countryCategoriesLoading,
  } = useGetBenefitCategories({
    countryCode,
    clientInternalNumberGOS: 0,
    enabled: !!selectedCountry,
  });

  const { data: benefitCategories, isLoading: benefitCategoriesLoading } =
    useGetBenefitCategories({
      countryCode: 0,
      clientInternalNumberGOS: 0,
      enabled: !!selectedCountry,
    });

  const hasBenefitCategoriesToAdd =
    benefitCategories?.benefitCategories?.filter((cat) => {
      return !countryBenefitCategories?.benefitCategories?.some(
        (countryCat) =>
          countryCat.benefitCategoryParentTag === cat.benefitCategoryTag
      );
    })?.length;

  const columnsDef = getColumnsDef({
    countryCode: Number(countryCode),
    hasSelection: true,
    t,
  });

  const benefitCategoryTableItems = useMemo(() => {
    return filteredBenefitCategories
      ?.map((category) => {
        return {
          ...category,
          coverPhoto: {
            name: category.backgroundPicturePath,
            base64String: category.backgroundPictureContent,
            type: category.backgroundPictureMimeType,
          },
          id: category.benefitCategoryTag,
          defaultState: {
            ...category,
            coverPhoto: {
              name: category.backgroundPicturePath,
              base64String: category.backgroundPictureContent,
              type: category.backgroundPictureMimeType,
            },
            id: category.benefitCategoryTag,
          },
        };
      })
      .sort(
        (a, b) => Number(a?.benefitCategoryTag) - Number(b?.benefitCategoryTag)
      );
  }, [filteredBenefitCategories]);

  const onSelectBenefitCategoryDialogChange = useCallback((state) => {
    setSelectedBenefitCategoriesDialog(state);
  }, []);

  const handleSearchFilterValue = useCallback(
    (value) => {
      setSearchFilterValue(value);
    },
    [setSearchFilterValue]
  );

  useEffect(() => {
    if (!countryBenefitCategories) return;

    setFilteredBenefitCategories(
      countryBenefitCategories?.benefitCategories?.filter((item) =>
        item?.name?.toLowerCase().includes(searchFilterValue)
      )
    );
  }, [
    countryBenefitCategories,
    searchFilterValue,
    setFilteredBenefitCategories,
  ]);

  useEffect(() => {
    if (!countryBenefitCategories) return;
    setFilteredBenefitCategories(countryBenefitCategories?.benefitCategories);
  }, [countryBenefitCategories, setFilteredBenefitCategories]);

  useEffect(() => {
    const noCategoriesForCountry =
      isFetched &&
      !countryBenefitCategories?.benefitCategories?.length &&
      selectedCountry;
    if (noCategoriesForCountry) {
      setSelectedBenefitCategoriesDialog(true);
    }
  }, [
    countryBenefitCategories?.benefitCategories?.length,
    isFetched,
    selectedCountry,
  ]);
  const showAddCategory = selectedCountry && hasBenefitCategoriesToAdd;

  const loading = benefitCategoriesLoading || countryCategoriesLoading;
  return (
    <>
      <Subheader
        isCountryPage
        searchFilterValue={searchFilterValue}
        disabled={
          !selectedCountry ||
          !(countryBenefitCategories?.benefitCategories || []).length ||
          countryCategoriesLoading
        }
        items={filteredBenefitCategories || []}
        setSearchFilterValue={handleSearchFilterValue}
      />
      {showAddCategory ? (
        <CountryPageToolbar
          benefitCategories={benefitCategories}
          countryBenefitCategories={countryBenefitCategories}
        />
      ) : null}

      {loading ? (
        <PageSkeleton hideSubheader />
      ) : (
        <>
          {selectedCountryPageTab === ECountryTabView.benefits && (
            <>
              <PageContent>
                {selectBenefitCategoriesDialog &&
                  selectedCountry &&
                  !countryBenefitCategories?.benefitCategories?.length &&
                  !countryCategoriesLoading && (
                    <SelectBenefitCategoriesDialog
                      open={selectBenefitCategoriesDialog}
                      setOpen={onSelectBenefitCategoryDialogChange}
                      country={selectedCountry}
                      withRootCountryCode
                    />
                  )}
                {selectedCountry &&
                (countryBenefitCategories?.benefitCategories || []).length &&
                !countryCategoriesLoading ? (
                  <div>
                    <ManageBenefitCategories
                      selectionMode="single"
                      countryCode={countryCode || 0}
                      benefitCategories={filteredBenefitCategories || []}
                      benefitTableItems={benefitCategoryTableItems || []}
                      defaultColumnSizingOptions={defaultColumnSizingOptions}
                      columns={columnsDef}
                      columnNames={columnNames}
                      onRowClick={() => {}}
                    />
                  </div>
                ) : null}
              </PageContent>
            </>
          )}
          {selectedCountryPageTab === ECountryTabView.analytics && (
            <Analytics
              countryId={countryCode ? countryCode + "" : null}
              clientIds={null}
              isDialog={false}
            />
          )}
        </>
      )}
    </>
  );
}
