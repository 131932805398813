import { AxiosError } from "axios";
import { MUTATION_KEYS } from "library/shared";
import { IFileRequest } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { handleAxiosError } from "library/utils";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;

  payload: IFileRequest;
};

const updateClient = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params): Promise<string> => {
  const response = await api.post(
    `/company/template/${countryCode}/${clientInternalNumberGOS}`,

    {
      ...payload,
    }
  );

  return response.data;
};

export function useImportEmployeesFromExcel() {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();

  return useMutation(updateClient, {
    mutationKey: MUTATION_KEYS.importEmployeesFromExcel,
    onSuccess: () => {
      invalidateCompanyEmployees();
      // queryClient.refetchQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error: AxiosError) => {
      handleAxiosError(error);
    },
  });
}
