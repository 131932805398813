import { FilteringCombobox } from "components/controls/FilteringCombobox";
import { useAppTheme } from "hooks/useAppTheme";
import useGetCountryOptionsPerUser from "hooks/useGetCountryOptionsPerUser";
import { useAtom, useSetAtom } from "jotai";
import { devices } from "library/constants";
import { useTranslation } from "react-i18next";
import {
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";
import styled from "styled-components";
import { CompanySelect } from "./CompanySelect";
// import { isListViewState } from "store/UIAdminPage";

export function CompanyPageSection() {
  const { t } = useTranslation();
  const { palette } = useAppTheme();

  const setSelectedCompany = useSetAtom(selectedCompanyState);
  const [selectedCountry, setSelectedCountry] = useAtom(selectedCountryState);
  const countryOptionsPerUser = useGetCountryOptionsPerUser();

  return (
    <InputsWrap>
      <FilteringCombobox
        style={{
          width: "100%",
          background: palette.themeLighterAlt,
          borderColor: palette.neutralLight,
        }}
        error={""}
        setFieldValue={(_, value) => {
          setSelectedCompany(null);
          setSelectedCountry(value || null);
        }}
        value={selectedCountry as any}
        label={t("hbh.country.label")}
        options={countryOptionsPerUser || []}
        name={"country"}
        noLabel
        noErrorSection
      />
      <CompanySelect selectedCountry={selectedCountry} noLabel noErrorSection />
    </InputsWrap>
  );
}

export default CompanyPageSection;

const InputsWrap = styled.div<{ wider?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 5px;

  @media only screen and ${devices.md} {
    flex-direction: row;

    width: ${({ wider }) => (wider ? "1200px" : "900px")};
    gap: 20px;
  }
`;
