import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IBenefitCategory, IBenefitPackage } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  benefitImplementationTag?: number | string;
  payload: IBenefitCategory;
};

const createBenefitPackage = async ({
  countryCode,
  benefitCategoryTag,
  clientInternalNumberGOS,
  benefitImplementationTag,
  payload,
}: Params): Promise<IBenefitPackage> => {
  const response = await api.post(
    `/benefitpackage/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}/${benefitImplementationTag}`,
    {
      ...payload,
    }
  );

  return response.data as IBenefitPackage;
};

export function useCreateBenefitPackage(refetch = true) {
  const queryClient = useQueryClient();
  return useMutation(createBenefitPackage, {
    mutationKey: MUTATION_KEYS.createBenefitPackage,
    onSuccess: () => {
      if (refetch) {
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitImplementations,
        });
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitImplementationPackages,
        });
      }
      // queryClient.invalidateQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
