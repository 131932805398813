import { atom, useAtom } from "jotai";
import {
  EManageHrTabView,
  IGrecoClient,
  ITransformedBenefitPackages,
  SelectItem,
  IBenefitCategory,
  IBenefitPackageView,
  IEmployee,
  IEmployeeView,
  IClient,
  IEmployeeDependent,
  IBenefitPackage,
  IEmployeeEnrollment,
} from "library/types";

export const selectedCompanyState = atom<IClient | null>(null);

export const searchEmployeeFilterValueState = atom<string>("");

export const selectedCountryState = atom<SelectItem | null>(null);

export const companyPackagesState = atom<ITransformedBenefitPackages | null>(
  null
);

export const selectedEmployeeRow = atom<any>(null);

// ==============================================================================
export const selectedEmployeeState = atom<IEmployeeView | null>(null);

export const selectedPackageState = atom<IBenefitPackage | null>(null);

export const selectedEnrollmentState = atom<IEmployeeEnrollment | null>(null);

// Davor - TODO review and remove unnecessary
export const filteredBenefitCategoriesState = atom<
  IBenefitCategory[] | undefined
>(undefined);

export const companyEmployeeViewsAtom = atom<IEmployeeView[] | undefined>(
  undefined
);
export const employeeViewsLoadingAtom = atom(false);

export const companyPackagesAtom = atom<IBenefitPackageView[] | undefined>(
  undefined
);
export const companyPackagesLoadingAtom = atom(false);

export const companyEmployeesAtom = atom<IEmployee[] | undefined>(undefined);
export const employeesLoadingAtom = atom(false);

export const filteredCompanyPackagesAtom = atom<IBenefitPackageView[]>([]);

export const searchFilterValueState = atom<string>("");

export const selectedBenefitsAtom = atom<Set<string>>(new Set<string>());
export const expandedBenefitsAtom = atom<Set<string>>(new Set<string>());

export const visibleBenefitTagsAtom = atom<
  Array<{ children: string; value: string }>
>([]);

export const selectedEmployeesAtom = atom<Set<string>>(new Set<string>());
export const expandedEmployeesAtom = atom<Set<string>>(new Set<string>());

export const visibleEmployeeTagsAtom = atom<
  Array<{ children: string; value: string }>
>([]);

export const filteredEmployeeViewsAtom = atom<IEmployeeView[] | undefined>(
  (get) => {
    const employees = get(companyEmployeeViewsAtom);
    const tags = get(visibleEmployeeTagsAtom);

    if (tags.length === 0) {
      return employees; // If no tags are selected, show all employees
    }

    return employees?.filter((employee) =>
      tags.every((tag) => employee.tags?.includes(tag.value))
    );
  }
);

export const employeeFilterSideEffectAtom = atom(null, (get, set) => {
  // const filteredEmployees = get(filteredEmployeeViewsAtom);

  // You can reset other atoms here if necessary
  set(selectedEmployeesAtom, new Set<string>());
  set(expandedEmployeesAtom, new Set<string>());
});
// ==============================================================================

export const onlyActiveImplementationsState = atom<boolean>(true);
