import { useAtomValue } from "jotai";
import { EMAIL_ID_SEPARATOR } from "library/utils";
import { selectedEmployeesAtom } from "store/UIHrPage";

const EmployeeSelectedInfo = () => {
  const selectedItems = useAtomValue(selectedEmployeesAtom);
  return (
    <div style={{ marginTop: "40px" }}>
      Selected{" "}
      {
        Array.from(selectedItems).filter(
          (el) => el.indexOf(EMAIL_ID_SEPARATOR) === -1
        ).length
      }{" "}
      employees and{" "}
      {
        Array.from(selectedItems).filter(
          (el) => el.indexOf(EMAIL_ID_SEPARATOR) !== -1
        ).length
      }{" "}
      dependents
    </div>
  );
};

export default EmployeeSelectedInfo;
