import InputField from "components/controls/InputField";
import { IRowItem } from "library/types";

type Props = {
  item: IRowItem;
};

export function PolicyContractNumberCell({ item }: Props) {
  const handleTableUpdate = (value) => {
    if (value || value === "") {
      const updatedItem = {
        ...item,
        policyOrContractNumber: value,
        isEdited: true,
      };
      item.updateState(updatedItem);
    }
  };
  return (
    <InputField
      onClick={(e) => {
        e.stopPropagation();
      }}
      label=""
      name="policyOrContractNumber"
      noLabel
      noErrorSection
      value={item?.policyOrContractNumber || ""}
      setFieldValue={(_, value) => {
        handleTableUpdate(value);
      }}
      error={""}
    />
  );
}
