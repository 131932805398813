import { Dropdown, Option } from "@fluentui/react-components";
import useGetCurrencyOptionsPerUser from "hooks/useGetCurrencyOptionsPerUser";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtom } from "jotai";
import { ETaxonomy } from "library/types";
import { filterCurrencyAtom } from "store/UIHrPageDashboard";

const FilterCurrency = () => {
  const currencyOptions = useGetCurrencyOptionsPerUser();
  const [filterCurrency, setFilterCurrency] = useAtom(filterCurrencyAtom);
  const handleOptionSelect = (ev, data) => {
    setFilterCurrency(
      currencyOptions.find((e) => e.value === data.optionValue)
    );
  };

  return (
    <Dropdown
      placeholder="Currency"
      title="Currency"
      style={{ marginTop: "10px" }}
      value={String(filterCurrency?.text) || ""}
      onOptionSelect={handleOptionSelect}
    >
      {currencyOptions
        //.sort((a, b) => a.text.localeCompare(b.text))
        .map((option) => (
          <Option key={option.code} value={option.value}>
            {option.text}
          </Option>
        ))}
    </Dropdown>
  );
};

export default FilterCurrency;
