import { ETaxonomy, SelectItem } from "library/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetTaxonomies } from "state/queries/useGetTaxonomies";

export function useTaxonomyOptions(taxonomy: ETaxonomy) {
  const { t } = useTranslation();
  const { data: taxonomies } = useGetTaxonomies();

  const options = useMemo(() => {
    if (!taxonomies) return [];
    return taxonomies[taxonomy]
      ?.map((item) => {
        return {
          // text: item.code,
          text: t(item.code),
          code: item.code,
          value: item.id,
        } as SelectItem;
      })
      .sort((a, b) => a.text.localeCompare(b.text));
  }, [taxonomies, taxonomy, t]);

  return options;
}
