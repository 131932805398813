import React, { useState } from "react";
import styled from "styled-components";
import EmployeeTags from "./EmployeeTags";
import EmployeeDependentCheckboxes from "./EmployeeSearchAndSelect";
import EmployeeSearchAndSelect from "./EmployeeSearchAndSelect";

const EmployeeFilters = () => {
  return (
    <div>
      <EmployeeTags />
      {/* <EmployeeSearchAndSelect /> */}
    </div>
  );
};

export default EmployeeFilters;
