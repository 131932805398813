import { IFileRequest } from "library/types";
import { getBase64 } from "library/utils";
import { CSSProperties, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

type Props = {
  setFile: (file: IFileRequest) => void;
};

export function DropzoneComponent({ setFile }: Props) {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        const encode = await getBase64(file);
        setFile({ base64Str: encode, name: file.name });
      });
    },
    [setFile]
  );
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () =>
      ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      } as CSSProperties),
    [isFocused, isDragAccept, isDragReject]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} {t("hbh.bytes.label")}
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{t("hbh.dropzonePlaceholder.label")}</p>
      </div>
      {files.length ? (
        <aside>
          <h4>{t("hbh.files.label")}</h4>
          <ul>{files}</ul>
        </aside>
      ) : null}
    </section>
  );
}

export default DropzoneComponent;

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
