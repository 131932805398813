import React from "react";
import IncludedIcon from "./IncludedIcon";

type Props = {
  value: string;
};

export function IncludedCheckbox({ value }: Props) {
  return <IncludedIcon value={value} />;
}
