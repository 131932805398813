import { ExcelDocumentIcon } from "@fluentui/react-icons-mdl2-branded";
import { ActionButton } from "components/styled";
import { useAtomValue } from "jotai";
import { IExcelReportResponse } from "library/types";
import { downloadFile } from "library/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generateEmployeeTemplate } from "state/queries/useGenerateEmployeeTemplate";
import { selectedCompanyState } from "store/UIHrPage";

export function GenerateEmployeeTemplate() {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);

  const [employeeTemplateLoading, setEmployeeTemplateLoading] = useState(false);
  const [employeeTemplate, setEmployeeTemplate] =
    useState<IExcelReportResponse | null>(null);

  useEffect(() => {
    setEmployeeTemplate(null);
  }, [selectedCompany]);

  return (
    <ActionButton
      noBorder
      appearance="transparent"
      disabled={employeeTemplateLoading}
      icon={<ExcelDocumentIcon />}
      onClick={async () => {
        if (employeeTemplate) {
          downloadFile(employeeTemplate, employeeTemplate.name);
          return;
        }
        setEmployeeTemplateLoading(true);
        const employeeTemplateData = await generateEmployeeTemplate({
          countryCode: Number(selectedCompany?.countryCode) || 0,
          clientInternalNumberGos:
            Number(selectedCompany?.clientInternalNumberGos) || 0,
        });
        setEmployeeTemplateLoading(false);
        setEmployeeTemplate(employeeTemplateData);
        downloadFile(employeeTemplateData, employeeTemplateData.name);
      }}
    >
      {t("hbh.generateEmployeeTemplate.label")}
    </ActionButton>
  );
}
