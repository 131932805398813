import { ECheckedValue, EPaidBy, ERole } from "library/types";

export const ROUTES = {
  HOME: "/",
  UNAUTHORIZED_PAGE: "/unauthorized",
  ADMIN: "/admin",
  COUNTRY: "/country",
  COMPANY_PROFILE: "/company-profile",
  HR: "/hr",
};

export const PAGES_PER_ROLES: {
  [x: string]: {
    role: ERole;
    pages: string[];
    canEdit: boolean;
  };
} = {
  [ERole.GlobalAdmin]: {
    role: ERole.GlobalAdmin,
    pages: Object.keys(ROUTES).map((key) => ROUTES[key]), //all pages
    canEdit: true,
  },
  [ERole.ReadonlyGlobalAdmin]: {
    role: ERole.ReadonlyGlobalAdmin,
    pages: Object.keys(ROUTES).map((key) => ROUTES[key]), //all pages
    canEdit: false,
  },
  //
  [ERole.CountryAdmin]: {
    role: ERole.CountryAdmin,
    pages: Object.keys(ROUTES)
      .map((key) => ROUTES[key])
      .filter((item) => item !== ROUTES.ADMIN), //all except admin
    canEdit: true,
  },
  [ERole.ReadonlyCountryAdmin]: {
    role: ERole.ReadonlyCountryAdmin,
    pages: Object.keys(ROUTES)
      .map((key) => ROUTES[key])
      .filter((item) => item !== ROUTES.ADMIN), //all except admin
    canEdit: false,
  },
  //
  [ERole.CompanyAdmin]: {
    role: ERole.CompanyAdmin,
    pages: [ROUTES.COMPANY_PROFILE, ROUTES.HR],
    canEdit: true,
  },
  [ERole.ReadonlyCompanyAdmin]: {
    role: ERole.ReadonlyCompanyAdmin,
    pages: [ROUTES.COMPANY_PROFILE, ROUTES.HR],
    canEdit: false,
  },
  //
  [ERole.HRCompanyAdmin]: {
    role: ERole.CompanyAdmin,
    pages: [ROUTES.HR],
    canEdit: true,
  },
  [ERole.ReadonlyHRCompanyAdmin]: {
    role: ERole.ReadonlyCompanyAdmin,
    pages: [ROUTES.HR],
    canEdit: false,
  },
  [ERole.B2CUser]: {
    role: ERole.ReadonlyCompanyAdmin,
    pages: [], //cant see anything
    canEdit: false,
  },
};

export const breakpoints = {
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const devices = {
  xs: `(min-width: ${breakpoints.xs}px)`,
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  "2xl": `(min-width: ${breakpoints["2xl"]}px)`,
};

export const checkedOptions = [
  {
    value: ECheckedValue.Yes,
    label: "Yes",
    text: "Yes",
  },
  {
    value: ECheckedValue.No,
    label: "No",
    text: "No",
  },
];

export const paidByOptions = [
  {
    text: EPaidBy.Percentage,
    value: EPaidBy.Percentage,
    label: EPaidBy.Percentage,
  },
  {
    text: EPaidBy.Amount,
    value: EPaidBy.Amount,
    label: EPaidBy.Amount,
  },
];

export const columnNames = {
  id: "ID",
  benefitCategory: "Benefit Category",
  implementedBenefit: "Implemented Benefit",
  coverPhoto: "Benefit Cover Picture",
  uploadDocuments: "Upload Documents",
  consentNeeded: "Consent Needed",
  typeOfConsent: "Type of Consent",
  descAsDocument: "Description as Document",
  enrollmentProcessNeeded: "Enrollment Needed",
  entitlements: "Entitlement",
  description: "Description",
  implementBenefit: "Implement Benefit",
  included: "Included",
  actions: "Actions",
  addPackage: "Add Package",
  carrier: "Carrier",
  packagesCount: "Packages No.",
  policyOrContractNumber: "Policy Number / Contract ID",
  validFrom: "Valid From",
  validTo: "Valid To",
  documents: "Documents",
  package: "Package",
  price: "Price",
  companyCostPerPeriod: "Company Cost Per Period",
  employeeCostPerPeriod: "Employee Cost Per Period",
  paidByEmployee: "Paid By Employee",
  paidByCompany: "Paid By Company",
  totalCostPerPeriod: "Total Cost Per Period",
  totalCost: "Total Cost",
  dependents: "Dependents",
  currency: "Currency",
  usingPercentagesForPaidBy: "Value Type",
  eligible: "Eligible",
  enrolled: "Enrolled",
  enrolledEmployee: "Enrolled Employee",
  enrolledDependent: "Enrolled Dependent",
  enrolledTotal: "Enrolled Total",
  totalEmployeeCost: "Total Employee Cost",
  employeeCost: "Employee Cost",
  totalCompanyCost: "Total Company Cost",
  companyCost: "Company Cost",
  coverages: "Coverages",
  dependentsCosts: "Dependents Costs",
  employees: "Employees",
  employee: "Employee",
  email: "Email",
  user: "User",
  actionType: "Action Type",
  createdAt: "Created At",
  documentType: "Documents",
};

export const ACTION_HISTORY_TRANSLATION_KEYS = {
  CreateEmployeesEnrollments: "hbh.createEmployeesEnrollments.label",
  DeleteEmployeesEnrollments: "hbh.deleteEmployeesEnrollments.label",
  ImportFromExcelTemplate: "hbh.importFromExcelTemplate.label",
  UpdateEmployeesEnrollments: "hbh.updateEmployeesEnrollments.label",
};

export const LOCATION_STATES = {
  PREV_PATH_NAME: "previousLocationPathname",
};
