import { FilteringCombobox } from "components/controls/FilteringCombobox";
import useGetCurrencyOptionsPerUser from "hooks/useGetCurrencyOptionsPerUser";
import { IRowItem } from "library/types";

type Props = {
  item: IRowItem;
};
export function CurrencyCell({ item }: Props) {
  const currencyOptions = useGetCurrencyOptionsPerUser();

  const currency = currencyOptions.find(
    (currency) => currency.value === item.currencyCode
  );

  return (
    <FilteringCombobox
      error=""
      noErrorSection
      noLabel
      setFieldValue={(name, value) => {
        const updatedItem = {
          ...item,
          isEdited: true,

          currencyCode: Number(value?.value),
        };
        item.updateState(updatedItem);
      }}
      value={currency}
      label=""
      options={currencyOptions}
      name={"currencyCode"}
    />
  );
}
