export enum EThemeName {
  main = "main",
  purple = "purple",
  gold = "gold",
}

export enum EThemeMode {
  dark = "dark",
  light = "light",
}

export interface IStyledTheme {
  name: string;
  palette: Palette;
  effects: Effects;
  semanticColors: SemanticColors;
  isDark: boolean;
}

interface Palette {
  themeDarker: string;
  themeDark: string;
  themeDarkAlt: string;
  themePrimary: string;
  themeSecondary: string;
  themeTertiary: string;
  themeLight: string;
  themeLighter: string;
  themeLighterAlt: string;
  black: string;
  blackTranslucent40: string;
  themeBackground: string;
  neutralDark: string;
  neutralPrimary: string;
  neutralPrimaryAlt: string;
  neutralSecondary: string;
  neutralSecondaryAlt: string;
  neutralTertiary: string;
  neutralTertiaryAlt: string;
  neutralQuaternary: string;
  neutralQuaternaryAlt: string;
  neutralLight: string;
  neutralLighter: string;
  neutralLighterAlt: string;
  accent: string;
  white: string;
  whiteTranslucent40: string;
  yellowDark: string;
  yellow: string;
  yellowLight: string;
  orange: string;
  orangeLight: string;
  orangeLighter: string;
  redDark: string;
  red: string;
  magentaDark: string;
  magenta: string;
  magentaLight: string;
  purpleDark: string;
  purple: string;
  purpleLight: string;
  blueDark: string;
  blueMid: string;
  blue: string;
  blueLight: string;
  tealDark: string;
  teal: string;
  tealLight: string;
  greenDark: string;
  green: string;
  greenLight: string;
  titanWhite: string;
}

export interface Effects {
  elevation4: string;
  elevation8: string;
  elevation16: string;
  elevation64: string;
  roundedCorner2: string;
  roundedCorner4: string;
  roundedCorner6: string;
}

export interface SemanticColors {
  bodyBackground: string;
  bodyFrameBackground: string;
  accentButtonText: string;
  buttonBackground: string;
  primaryButtonText: string;
  primaryButtonTextHovered: string;
  primaryButtonTextPressed: string;
  inputBackground: string;
  inputForegroundChecked: string;
  listBackground: string;
  menuBackground: string;
  cardStandoutBackground: string;
  bodyTextChecked: string;
  buttonTextCheckedHovered: string;
  link: string;
  primaryButtonBackground: string;
  inputBackgroundChecked: string;
  inputIcon: string;
  inputFocusBorderAlt: string;
  menuIcon: string;
  menuHeader: string;
  accentButtonBackground: string;
  primaryButtonBackgroundPressed: string;
  inputBackgroundCheckedHovered: string;
  inputIconHovered: string;
  linkHovered: string;
  primaryButtonBackgroundHovered: string;
  inputPlaceholderBackgroundChecked: string;
  bodyBackgroundChecked: string;
  bodyFrameDivider: string;
  bodyDivider: string;
  variantBorder: string;
  buttonBackgroundCheckedHovered: string;
  buttonBackgroundPressed: string;
  listItemBackgroundChecked: string;
  listHeaderBackgroundPressed: string;
  menuItemBackgroundPressed: string;
  menuItemBackgroundChecked: string;
  bodyBackgroundHovered: string;
  buttonBackgroundHovered: string;
  buttonBackgroundDisabled: string;
  buttonBorderDisabled: string;
  primaryButtonBackgroundDisabled: string;
  disabledBackground: string;
  listItemBackgroundHovered: string;
  listHeaderBackgroundHovered: string;
  menuItemBackgroundHovered: string;
  primaryButtonTextDisabled: string;
  disabledSubtext: string;
  listItemBackgroundCheckedHovered: string;
  disabledBodyText: string;
  variantBorderHovered: string;
  buttonTextDisabled: string;
  inputIconDisabled: string;
  disabledText: string;
  bodyText: string;
  actionLink: string;
  buttonText: string;
  inputBorderHovered: string;
  inputText: string;
  listText: string;
  menuItemText: string;
  bodyStandoutBackground: string;
  defaultStateBackground: string;
  actionLinkHovered: string;
  buttonTextHovered: string;
  buttonTextChecked: string;
  buttonTextPressed: string;
  inputTextHovered: string;
  menuItemTextHovered: string;
  bodySubtext: string;
  focusBorder: string;
  inputBorder: string;
  smallInputBorder: string;
  inputPlaceholderText: string;
  buttonBorder: string;
  disabledBodySubtext: string;
  disabledBorder: string;
  buttonBackgroundChecked: string;
  menuDivider: string;
  cardShadow: string;
  cardShadowHovered: string;
  primaryButtonBorder: string;
  errorText: string;
  messageText: string;
  messageLink: string;
  messageLinkHovered: string;
  infoIcon: string;
  errorIcon: string;
  blockingIcon: string;
  warningIcon: string;
  severeWarningIcon: string;
  successIcon: string;
  infoBackground: string;
  errorBackground: string;
  blockingBackground: string;
  warningBackground: string;
  severeWarningBackground: string;
  successBackground: string;
  successHighlight: string;
  warningHighlight: string;
  successText: string;
  listTextColor: string;
  warningText: string;
}
