export const styledGoldTheme = {
  name: "gold-theme-light",

  palette: {
    themeDarker: "#4d3a00",
    themeDark: "#806000",
    themeDarkAlt: "#b38300",
    themePrimary: "#FFD700", // Gold
    themeSecondary: "#FFDF77", // Light gold secondary
    themeTertiary: "#FFEAB0",
    themeLight: "#FFF3D4",
    themeLighter: "#FFF7E2",
    themeLighterAlt: "#FFFDF6",
    black: "#000000",
    blackTranslucent40: "rgba(0,0,0,.1)",
    themeBackground: "#FFF8E1",
    neutralDark: "#201f1e",
    neutralPrimary: "#323130",
    neutralPrimaryAlt: "#3b3a39",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralTertiary: "#a19f9d",
    neutralTertiaryAlt: "#c8c6c4",
    neutralQuaternary: "#d0d0d0",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralLight: "#edebe9",
    neutralLighter: "#f3f2f1",
    neutralLighterAlt: "#faf9f8",
    accent: "#FFD700",
    white: "#ffffff",
    whiteTranslucent40: "rgba(255,255,255,.1)",
    yellowDark: "#d4a600",
    yellow: "#ffcc00",
    yellowLight: "#fff3a1",
    orange: "#cc5500",
    orangeLight: "#ff6600",
    orangeLighter: "#ff9f40",
    redDark: "#9b3333",
    red: "#e81123",
    magentaDark: "#5c005c",
    magenta: "#b4009e",
    magentaLight: "#e3008c",
    purpleDark: "#32145a",
    purple: "#5c2d91",
    purpleLight: "#b4a0ff",
    blueDark: "#002050",
    blueMid: "#00188f",
    blue: "#0078d4",
    blueLight: "#00bcf2",
    tealDark: "#004b50",
    teal: "#008272",
    tealLight: "#00b294",
    greenDark: "#004b1c",
    green: "#107c10",
    greenLight: "#bad80a",
    titanWhite: "#eeefff",
  },
  effects: {
    elevation4:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    elevation8:
      "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
    elevation16:
      "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
    elevation64:
      "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
    roundedCorner2: "2px",
    roundedCorner4: "4px",
    roundedCorner6: "6px",
  },
  semanticColors: {
    bodyBackground: "#FFF8E1",
    bodyFrameBackground: "#FFF8E1",
    accentButtonText: "#000000",
    buttonBackground: "#FFF8E1",
    primaryButtonText: "#000000",
    primaryButtonTextHovered: "#000000",
    primaryButtonTextPressed: "#000000",
    inputBackground: "#FFF8E1",
    inputForegroundChecked: "#000000",
    listBackground: "#FFF8E1",
    menuBackground: "#FFF8E1",
    cardStandoutBackground: "#FFF3D4",
    bodyTextChecked: "#000000",
    buttonTextCheckedHovered: "#000000",
    link: "#FFD700",
    primaryButtonBackground: "#FFD700",
    inputBackgroundChecked: "#FFD700",
    inputIcon: "#FFD700",
    inputFocusBorderAlt: "#FFD700",
    menuIcon: "#FFD700",
    menuHeader: "#FFD700",
    accentButtonBackground: "#FFD700",
    primaryButtonBackgroundPressed: "#b38300",
    inputBackgroundCheckedHovered: "#b38300",
    inputIconHovered: "#806000",
    linkHovered: "#806000",
    primaryButtonBackgroundHovered: "#b38300",
    inputPlaceholderBackgroundChecked: "#FFF3D4",
    bodyBackgroundChecked: "#FFF3D4",
    bodyFrameDivider: "#FFF3D4",
    bodyDivider: "#FFF3D4",
    variantBorder: "#FFF3D4",
    buttonBackgroundCheckedHovered: "#FFF3D4",
    buttonBackgroundPressed: "#FFF3D4",
    listItemBackgroundChecked: "#FFF3D4",
    listHeaderBackgroundPressed: "#FFF3D4",
    menuItemBackgroundPressed: "#FFF3D4",
    menuItemBackgroundChecked: "#FFF3D4",
    bodyBackgroundHovered: "#FFF7E2",
    buttonBackgroundHovered: "#FFF7E2",
    buttonBackgroundDisabled: "#FFF7E2",
    buttonBorderDisabled: "#FFF7E2",
    primaryButtonBackgroundDisabled: "#FFF7E2",
    disabledBackground: "#FFF7E2",
    listItemBackgroundHovered: "#FFF7E2",
    listHeaderBackgroundHovered: "#FFF7E2",
    menuItemBackgroundHovered: "#FFF7E2",
    primaryButtonTextDisabled: "#d0d0d0",
    disabledSubtext: "#d0d0d0",
    listItemBackgroundCheckedHovered: "#e1dfdd",
    disabledBodyText: "#a19f9d",
    variantBorderHovered: "#a19f9d",
    buttonTextDisabled: "#a19f9d",
    inputIconDisabled: "#a19f9d",
    disabledText: "#a19f9d",
    bodyText: "#323130",
    actionLink: "#323130",
    buttonText: "#323130",
    inputBorderHovered: "#323130",
    inputText: "#323130",
    listText: "#323130",
    menuItemText: "#323130",
    bodyStandoutBackground: "#faf9f8",
    defaultStateBackground: "#faf9f8",
    actionLinkHovered: "#201f1e",
    buttonTextHovered: "#201f1e",
    buttonTextChecked: "#201f1e",
    buttonTextPressed: "#201f1e",
    inputTextHovered: "#201f1e",
    menuItemTextHovered: "#201f1e",
    bodySubtext: "#605e5c",
    focusBorder: "#605e5c",
    inputBorder: "#605e5c",
    smallInputBorder: "#605e5c",
    inputPlaceholderText: "#605e5c",
    buttonBorder: "#8a8886",
    disabledBodySubtext: "#c8c6c4",
    disabledBorder: "#c8c6c4",
    buttonBackgroundChecked: "#c8c6c4",
    menuDivider: "#c8c6c4",
    cardShadow:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    cardShadowHovered: "0 0 1px #a19f9d",
    primaryButtonBorder: "transparent",
    errorText: "#a4262c",
    messageText: "#323130",
    messageLink: "#FFD700",
    messageLinkHovered: "#806000",
    infoIcon: "#605e5c",
    errorIcon: "#A80000",
    blockingIcon: "#FDE7E9",
    warningIcon: "#797775",
    severeWarningIcon: "#CC3F01",
    successIcon: "#107C10",
    infoBackground: "#f3f2f1",
    errorBackground: "#FDE7E9",
    blockingBackground: "#FDE7E9",
    warningBackground: "#FFF4CE",
    severeWarningBackground: "#FED9CC",
    successBackground: "#DFF6DD",
    warningHighlight: "#FFD700",
    successHighlight: "#9FD7B2",
    successText: "#107C10",
    listTextColor: "#323130",
    warningText: "#323130",
  },
  isDark: false,
};

export const styledGoldDarkTheme = {
  name: "gold-theme-dark",

  palette: {
    themeDarker: "#FFD700", // Deep gold for accents
    themeDark: "#FFC300", // Brighter gold for more prominent accents
    themeDarkAlt: "#FFB000",
    themePrimary: "#FFD700", // Gold
    themeSecondary: "#C69C6D", // Muted gold for subtler elements
    themeTertiary: "#8D8D8D", // Dark neutral gray for accents
    themeLight: "#474747",
    themeLighter: "#333333",
    themeLighterAlt: "#1F1F1F",
    black: "#FFFFFF", // White text for readability
    blackTranslucent40: "rgba(255,255,255,.4)",
    themeBackground: "#1F1F1F",
    neutralDark: "#F3F3F3",
    neutralPrimary: "#EAEAEA",
    neutralPrimaryAlt: "#D0D0D0",
    neutralSecondary: "#A6A6A6",
    neutralSecondaryAlt: "#8C8C8C",
    neutralTertiary: "#737373",
    neutralTertiaryAlt: "#595959",
    neutralQuaternary: "#404040",
    neutralQuaternaryAlt: "#333333",
    neutralLight: "#262626",
    neutralLighter: "#1A1A1A",
    neutralLighterAlt: "#141414",
    accent: "#FFD700",
    white: "#1F1F1F",
    whiteTranslucent40: "rgba(255,255,255,.1)",
    yellowDark: "#FFD700",
    yellow: "#FFC107",
    yellowLight: "#FFECB3",
    orange: "#FF9100",
    orangeLight: "#FFAB40",
    orangeLighter: "#FFCC80",
    redDark: "#FF5252",
    red: "#D32F2F",
    magentaDark: "#B4009E",
    magenta: "#CE93D8",
    magentaLight: "#F3E5F5",
    purpleDark: "#8E24AA",
    purple: "#AB47BC",
    purpleLight: "#D1C4E9",
    blueDark: "#1976D2",
    blueMid: "#64B5F6",
    blue: "#90CAF9",
    blueLight: "#E3F2FD",
    tealDark: "#004D40",
    teal: "#26A69A",
    tealLight: "#B2DFDB",
    greenDark: "#2E7D32",
    green: "#66BB6A",
    greenLight: "#A5D6A7",
    titanWhite: "#333333",
  },
  effects: {
    elevation4:
      "0 1.6px 3.6px 0 rgba(255, 255, 255, 0.14), 0 0.3px 0.9px 0 rgba(255, 255, 255, 0.1)",
    elevation8:
      "0 3.2px 7.2px 0 rgba(255, 255, 255, 0.14), 0 0.6px 1.8px 0 rgba(255, 255, 255, 0.1)",
    elevation16:
      "0 6.4px 14.4px 0 rgba(255, 255, 255, 0.14), 0 1.2px 3.6px 0 rgba(255, 255, 255, 0.1)",
    elevation64:
      "0 25.6px 57.6px 0 rgba(255, 255, 255, 0.18), 0 4.8px 14.4px 0 rgba(255, 255, 255, 0.14)",
    roundedCorner2: "2px",
    roundedCorner4: "4px",
    roundedCorner6: "6px",
  },
  semanticColors: {
    bodyBackground: "#1F1F1F",
    bodyFrameBackground: "#1F1F1F",
    accentButtonText: "#000000",
    buttonBackground: "#333333",
    primaryButtonText: "#1F1F1F",
    primaryButtonTextHovered: "#1F1F1F",
    primaryButtonTextPressed: "#1F1F1F",
    inputBackground: "#2A2A2A",
    inputForegroundChecked: "#FFD700",
    listBackground: "#1F1F1F",
    menuBackground: "#1F1F1F",
    cardStandoutBackground: "#333333",
    bodyTextChecked: "#FFD700",
    buttonTextCheckedHovered: "#FFD700",
    link: "#FFD700",
    primaryButtonBackground: "#FFD700",
    inputBackgroundChecked: "#FFD700",
    inputIcon: "#FFD700",
    inputFocusBorderAlt: "#FFD700",
    menuIcon: "#FFD700",
    menuHeader: "#FFD700",
    accentButtonBackground: "#FFD700",
    primaryButtonBackgroundPressed: "#C69C6D",
    inputBackgroundCheckedHovered: "#C69C6D",
    inputIconHovered: "#806000",
    linkHovered: "#806000",
    primaryButtonBackgroundHovered: "#C69C6D",
    inputPlaceholderBackgroundChecked: "#474747",
    bodyBackgroundChecked: "#333333",
    bodyFrameDivider: "#333333",
    bodyDivider: "#333333",
    variantBorder: "#333333",
    buttonBackgroundCheckedHovered: "#333333",
    buttonBackgroundPressed: "#333333",
    listItemBackgroundChecked: "#333333",
    listHeaderBackgroundPressed: "#333333",
    menuItemBackgroundPressed: "#333333",
    menuItemBackgroundChecked: "#333333",
    bodyBackgroundHovered: "#262626",
    buttonBackgroundHovered: "#262626",
    buttonBackgroundDisabled: "#262626",
    buttonBorderDisabled: "#262626",
    primaryButtonBackgroundDisabled: "#262626",
    disabledBackground: "#262626",
    listItemBackgroundHovered: "#262626",
    listHeaderBackgroundHovered: "#262626",
    menuItemBackgroundHovered: "#262626",
    primaryButtonTextDisabled: "#595959",
    disabledSubtext: "#595959",
    listItemBackgroundCheckedHovered: "#404040",
    disabledBodyText: "#737373",
    variantBorderHovered: "#737373",
    buttonTextDisabled: "#737373",
    inputIconDisabled: "#737373",
    disabledText: "#737373",
    bodyText: "#EAEAEA",
    actionLink: "#EAEAEA",
    buttonText: "#EAEAEA",
    inputBorderHovered: "#EAEAEA",
    inputText: "#EAEAEA",
    listText: "#EAEAEA",
    menuItemText: "#EAEAEA",
    bodyStandoutBackground: "#141414",
    defaultStateBackground: "#141414",
    actionLinkHovered: "#F3F3F3",
    buttonTextHovered: "#F3F3F3",
    buttonTextChecked: "#F3F3F3",
    buttonTextPressed: "#F3F3F3",
    inputTextHovered: "#F3F3F3",
    menuItemTextHovered: "#F3F3F3",
    bodySubtext: "#A6A6A6",
    focusBorder: "#A6A6A6",
    inputBorder: "#A6A6A6",
    smallInputBorder: "#A6A6A6",
    inputPlaceholderText: "#A6A6A6",
    buttonBorder: "#8C8C8C",
    disabledBodySubtext: "#595959",
    disabledBorder: "#595959",
    buttonBackgroundChecked: "#595959",
    menuDivider: "#595959",
    cardShadow:
      "0 1.6px 3.6px 0 rgba(255, 255, 255, 0.14), 0 0.3px 0.9px 0 rgba(255, 255, 255, 0.1)",
    cardShadowHovered: "0 0 1px #737373",
    primaryButtonBorder: "transparent",
    errorText: "#FF6F61",
    messageText: "#EAEAEA",
    messageLink: "#FFD700",
    messageLinkHovered: "#C69C6D",
    infoIcon: "#A6A6A6",
    errorIcon: "#FF6F61",
    blockingIcon: "#FFABAB",
    warningIcon: "#C69C6D",
    severeWarningIcon: "#FF8A50",
    successIcon: "#66BB6A",
    infoBackground: "#333333",
    errorBackground: "#FF5252",
    blockingBackground: "#FF5252",
    warningBackground: "#FFCC80",
    severeWarningBackground: "#FFAB80",
    successBackground: "#A5D6A7",
    warningHighlight: "#FFC107",
    successHighlight: "#9FD7B2",
    successText: "#66BB6A",
    listTextColor: "#EAEAEA",
    warningText: "#EAEAEA",
  },
  isDark: true,
};
