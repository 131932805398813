import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { Grid } from "components/styled";
import { formatDate } from "date-fns";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy, IBenefitPackageView } from "library/types";
import { useTranslation } from "react-i18next";

type Props = {
  packageData: IBenefitPackageView & {
    dateOfEmployment: string;
  };
  gridColumns?: number;
  isDependent?: boolean;
};

export function PackageValidationFields({
  packageData,
  gridColumns = 3,
  isDependent,
}: Props) {
  const { t } = useTranslation();

  const eligibleTypeOptions = useTaxonomyOptions(ETaxonomy.EligibleType);
  const eligibleDateTypeOptions = useTaxonomyOptions(
    ETaxonomy.EligibleDateType
  );
  const offboardingTypeOptions = useTaxonomyOptions(ETaxonomy.OffboardingType);
  const offboardingDateTypeOptions = useTaxonomyOptions(
    ETaxonomy.OffboardingDateType
  );

  const eligibleFromType = packageData
    ? eligibleTypeOptions.find((option) => {
        return option.value === packageData.eligibleFromType;
      })
    : null;
  const eligibleToType = packageData
    ? eligibleTypeOptions.find((option) => {
        return option.value === packageData.eligibleToType;
      })
    : null;
  const eligibleFromDateType = packageData
    ? eligibleDateTypeOptions.find((option) => {
        return option.value === packageData.eligibleFromDateType;
      })
    : null;

  const eligibleToDateType = packageData
    ? eligibleDateTypeOptions.find((option) => {
        return option.value === packageData.eligibleToDateType;
      })
    : null;
  const offboardingType = packageData
    ? offboardingTypeOptions.find((option) => {
        return option.value === packageData.offboardingType;
      })
    : null;
  const offboardingDateType = packageData
    ? offboardingDateTypeOptions.find((option) => {
        return option.value === packageData.offboardingDateType;
      })
    : null;
  const employeeDateOfEmployment = packageData?.dateOfEmployment;

  const cancellationPossibilityDateType = packageData
    ? eligibleDateTypeOptions.find((option) => {
        return option.value === packageData.cancellationPossibilityDateType;
      })
    : null;

  const cancellationPossibilityType = packageData
    ? eligibleTypeOptions.find((option) => {
        return option.value === packageData.cancellationPossibilityType;
      })
    : null;

  return (
    <Grid gridColumns={gridColumns}>
      <InputField
        label={
          isDependent
            ? t("hbh.packageOwnerDateOfEmployment.label")
            : t("hbh.dateOfEmployment.label")
        }
        name=""
        value={
          employeeDateOfEmployment
            ? formatDate(employeeDateOfEmployment, "dd.MM.yyyy")
            : ""
        }
        disabled
        setFieldValue={() => undefined}
      />
      <InputField
        label={t("hbh.validFrom.label")}
        name=""
        value={
          packageData?.validFrom
            ? formatDate(packageData?.validFrom, "dd.MM.yyyy")
            : ""
        }
        disabled
        setFieldValue={() => undefined}
      />
      <InputField
        label={t("hbh.validTo.label")}
        name=""
        value={
          packageData?.validFrom
            ? formatDate(packageData?.validTo || "", "dd.MM.yyyy")
            : ""
        }
        disabled
        setFieldValue={() => undefined}
      />

      <SelectField
        disabled
        setFieldValue={() => undefined}
        value={eligibleFromDateType}
        label={t("hbh.eligibleFromDate.label")}
        options={eligibleDateTypeOptions}
        name={"eligibleFromDateType"}
        showEmptyOption
      />
      <SelectField
        disabled
        setFieldValue={() => undefined}
        value={eligibleFromType}
        label={t("hbh.eligibleFrom.label")}
        options={eligibleTypeOptions}
        name={"eligibleFromType"}
        showEmptyOption
      />

      <InputField
        disabled
        label={t("hbh.eligibleFromValue.label")}
        name="eligibleFromValue"
        value={packageData?.eligibleFromValue?.toString() || ""}
        setFieldValue={() => undefined}
      />
      <SelectField
        disabled
        setFieldValue={() => undefined}
        value={eligibleToDateType}
        label={t("hbh.eligibleToDate.label")}
        options={eligibleDateTypeOptions}
        name={"eligibleToDateType"}
        showEmptyOption
      />
      <SelectField
        disabled
        setFieldValue={() => undefined}
        value={eligibleToType}
        label={t("hbh.eligibleTo.label")}
        options={eligibleTypeOptions}
        name={"eligibleToType"}
        showEmptyOption
      />
      <InputField
        disabled
        label={t("hbh.eligibleToValue.label")}
        name="eligibleToValue"
        value={packageData?.eligibleToValue?.toString() || ""}
        setFieldValue={() => undefined}
      />

      <SelectField
        disabled
        setFieldValue={() => undefined}
        value={cancellationPossibilityDateType}
        label={t("hbh.cancellationPossibilityDateType.label")}
        options={eligibleDateTypeOptions}
        name={"cancellationPossibilityDateType"}
        showEmptyOption
      />
      <SelectField
        disabled
        setFieldValue={() => undefined}
        value={cancellationPossibilityType}
        label={t("hbh.cancellationPossibilityType.label")}
        options={eligibleTypeOptions}
        name={"cancellationPossibilityType"}
        showEmptyOption
      />
      <InputField
        disabled
        label={t("hbh.cancellationPossibilityValue.label")}
        name="cancellationPossibilityValue"
        value={packageData?.cancellationPossibilityValue?.toString() || ""}
        setFieldValue={() => undefined}
      />
      <SelectField
        disabled
        setFieldValue={() => undefined}
        value={offboardingDateType}
        label={t("hbh.offboardingDateType.label")}
        options={offboardingDateTypeOptions}
        name={"offboardingDateType"}
        showEmptyOption
      />
      <SelectField
        disabled
        setFieldValue={() => undefined}
        value={offboardingType}
        label={t("hbh.offboardingType.label")}
        options={offboardingTypeOptions}
        name={"offboardingType"}
        showEmptyOption
      />

      <InputField
        disabled
        label={t("hbh.offboardingValue.label")}
        name="offboardingValue"
        value={packageData?.offboardingValue?.toString() || ""}
        setFieldValue={() => undefined}
      />
    </Grid>
  );
}
