import SelectField from "components/controls/SelectField";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy, IRowItem } from "library/types";
import { useMemo } from "react";

type Props = {
  item: IRowItem;
  categoryOptions?: number[];
};

export function TypeOfConsentCellSingleSelect({
  item,
  categoryOptions,
}: Props) {
  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);

  // const implementationConsentOptions = useMemo(() => { // use this if you want to filter options on implemented benefits based on selected options on category
  //   return consentOptions.filter((option) => {
  //     return categoryOptions?.includes(option.value);
  //   });
  // }, [categoryOptions, consentOptions]);

  const defaultValue = useMemo(() => {
    return consentOptions.filter((option) => {
      return item?.consentTypes?.includes(option.value);
    });
  }, [consentOptions, item.consentTypes]);

  return (
    <SelectField
      onClick={(e) => {
        e.stopPropagation();
      }}
      error={""}
      setFieldValue={(name, types) => {
        const id = types.value;

        item.updateState({
          ...item,
          isEdited: true,

          consentTypes: [Number(id)],
        });
      }}
      value={defaultValue[0] as any}
      label=""
      // options={implementationConsentOptions}
      options={consentOptions}
      name={"consentTypes"}
      noErrorSection
      noLabel
    />
  );
}
