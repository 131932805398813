// --- Fabric UI
import { Link, Persona } from "@fluentui/react-components";
import { Flex } from "components/styled";
import { IUser } from "library/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PanelType as GrecoPanelType } from "../PanelType";
import { IUserSettingsStrings } from "./IUserSettingsStrings";

interface IProps {
  userData: IUser | null;
  logout: () => void;
  strings?: IUserSettingsStrings;
  panelType?: GrecoPanelType | null;
}

export const UserSettings = ({
  userData,
  logout,
  strings,
  panelType,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Flex>
        <Persona
          {...{
            text: userData?.displayName,
            imageUrl: userData?.photoMedium,
          }}
          name={userData?.displayName}
          secondaryText={
            <Link id="logout" onClick={() => logout()}>
              {(strings && strings.signOutLinkText) || t("header.SignOut")}
            </Link>
          }
          size="large"
        />
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 30px;
`;
