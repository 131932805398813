import { Button, Input } from "@fluentui/react-components";
import { ChevronLeftFilled, ChevronRightFilled } from "@fluentui/react-icons";
import { useAppTheme } from "hooks/useAppTheme";
import { useState } from "react";
import styled from "styled-components";

interface ICustomPaginationProps {
  totalPages: number;
  page: number;
  onChange: (pageNumber: number) => void;
}

export function CustomPagination({
  totalPages,
  page,
  onChange,
}: ICustomPaginationProps) {
  const [inputValue, setInputValue] = useState(page);
  const theme = useAppTheme();

  const getVisiblePages = () => {
    const visiblePages: any[] = [];
    visiblePages.push(1);

    // Always include the first page

    // Add two pages before, the current page, and two pages after
    for (
      let i = Math.max(2, page - 2);
      i <= Math.min(totalPages - 1, page + 2);
      i++
    ) {
      visiblePages.push(i);
    }

    // Add ellipsis if there's a gap between the last set of buttons and the last page

    visiblePages.push(totalPages);

    return visiblePages;
  };

  const handleInputChangePage = (event) => {
    let value = Number(event.target.value);
    if (value < 1) {
      value = 1;
    }
    if (value > totalPages) {
      value = totalPages;
    }
    setInputValue(value);
    onChange(value);
  };

  const visiblePages = getVisiblePages();

  return (
    <Container>
      <Input
        key={inputValue?.toString()}
        type="number"
        value={inputValue.toString()}
        appearance="outline"
        onFocus={(event) => {
          event.target.select();
        }}
        onBlur={(event) => {
          handleInputChangePage(event);
        }}
        onChange={(event) => {
          setInputValue(Number(event.target.value));
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleInputChangePage(event);
          }
        }}
      />
      <Button
        disabled={page === 1}
        icon={<ChevronLeftFilled />}
        onClick={() => {
          if (page > 1) {
            setInputValue(page - 1);
            onChange(page - 1);
          }
        }}
      />

      {visiblePages.map((number) => {
        const activeButton = number === page;
        const buttonAppearance = activeButton ? "primary" : "outline";
        const buttonBg = activeButton ? theme.palette.themePrimary : "inherit";
        return (
          <Button
            style={{
              background: buttonBg,
              width: "50px",
              minWidth: "50px",
              maxWidth: "50px",
            }}
            key={number}
            onClick={() => {
              setInputValue(number);
              onChange(number);
            }}
            appearance={buttonAppearance}
            shape="rounded"
          >
            {number}
          </Button>
        );
      })}
      <Button
        disabled={page === totalPages}
        icon={<ChevronRightFilled />}
        onClick={() => {
          if (page < totalPages) {
            setInputValue(page + 1);
            onChange(page + 1);
          }
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: flex-end;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    text-align: center;
    max-width: 60px;
  }
`;
