import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";

export function EligibleFields() {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();
  const eligibleFromTypeOptions = useTaxonomyOptions(ETaxonomy.EligibleType);
  const eligibleDateTypeOptions = useTaxonomyOptions(
    ETaxonomy.EligibleDateType
  );

  return (
    <>
      <SelectField
        error={errors["eligibleFromDateType"]}
        setFieldValue={setFieldValue}
        value={values["eligibleFromDateType"]}
        label={t("hbh.eligibleFromDate.label")}
        options={eligibleDateTypeOptions}
        name={"eligibleFromDateType"}
        showEmptyOption
      />
      <SelectField
        error={errors["eligibleFromType"]}
        setFieldValue={setFieldValue}
        value={values["eligibleFromType"]}
        label={t("hbh.eligibleFrom.label")}
        options={eligibleFromTypeOptions}
        name={"eligibleFromType"}
        showEmptyOption
      />

      <InputField
        label={t("hbh.eligibleFromValue.label")}
        name="eligibleFromValue"
        value={values["eligibleFromValue"]}
        setFieldValue={setFieldValue}
        error={errors["eligibleFromValue"]}
      />
      <SelectField
        error={errors["eligibleToDateType"]}
        setFieldValue={setFieldValue}
        value={values["eligibleToDateType"]}
        label={t("hbh.eligibleToDate.label")}
        options={eligibleDateTypeOptions}
        name={"eligibleToDateType"}
        showEmptyOption
      />
      <SelectField
        error={errors["eligibleToType"]}
        setFieldValue={setFieldValue}
        value={values["eligibleToType"]}
        label={t("hbh.eligibleTo.label")}
        options={eligibleFromTypeOptions}
        name={"eligibleToType"}
        showEmptyOption
      />
      <InputField
        label={t("hbh.eligibleToValue.label")}
        name="eligibleToValue"
        value={values["eligibleToValue"]}
        setFieldValue={setFieldValue}
        error={errors["eligibleToValue"]}
      />
    </>
  );
}
