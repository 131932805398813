import {
  makeStyles,
  mergeClasses,
  Overflow,
  OverflowItem,
} from "@fluentui/react-components";
import { OverflowMenu } from "./OverflowMenu";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "nowrap",
    minWidth: 0,
    overflow: "hidden",
  },

  resizableArea: {
    minWidth: "400px",
    maxWidth: "800px",
    display: "flex",
    gap: "10px",
    position: "relative",
  },
});

export const OverflowComponent = ({ children }) => {
  const styles = useStyles();

  return (
    <Overflow>
      <div className={mergeClasses(styles.container, styles.resizableArea)}>
        {children.map((item, index) => (
          <OverflowItem key={index} id={index.toString()}>
            {item}
          </OverflowItem>
        ))}
        <OverflowMenu items={children} />
      </div>
    </Overflow>
  );
};
