import styled, { ThemeProvider } from "styled-components";

import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { styledTheme } from "theme/mainTheme";
import grecoTriangleBg from "../../assets/GrECoGraphicTriangles.svg";

export function FallbackSpinner() {
  return (
    <Container>
      <FluentProvider theme={webLightTheme}>
        <ThemeProvider theme={styledTheme}>
          <Content>
            {/* <Spinner size="huge" /> */}
            {/* <Loader>
            <div className="loader"></div>
          </Loader> */}
            <Loader2>
              <div className="loader"></div>
            </Loader2>
          </Content>
        </ThemeProvider>
      </FluentProvider>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  overflow: auto;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  algin-items: center;
  background-image: url("${grecoTriangleBg}");
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
`;

const Loader = styled.div`
  .loader {
    width: 80px;
    height: 40px;
    display: flex;
  }
  .loader::before,
  .loader::after {
    content: "";
    flex: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    background: #fc3a51;
    animation: l15-1 1s infinite linear alternate,
      l15-2 2s infinite linear -0.5s;
  }
  .loader::after {
    --s: -1, -1;
  }
  @keyframes l15-1 {
    0%,
    10% {
      transform: scale(var(--s, 1)) translate(0px) perspective(150px)
        rotateY(0deg);
    }
    33% {
      transform: scale(var(--s, 1)) translate(-10px) perspective(150px)
        rotateX(0deg);
    }
    66% {
      transform: scale(var(--s, 1)) translate(-10px) perspective(150px)
        rotateX(-180deg);
    }
    90%,
    100% {
      transform: scale(var(--s, 1)) translate(0px) perspective(150px)
        rotateX(-180deg);
    }
  }
  @keyframes l15-2 {
    0%,
    49.99% {
      background: #fc3a51;
    }
    50%,
    100% {
      background: #eb9f9f;
    }
  }
`;

const Loader2 = styled.div`
  .loader {
    width: 40px;
    aspect-ratio: 1;
    color: ${({ theme }) => theme.palette.themePrimary};
    position: relative;
    background: conic-gradient(from 134deg at top, currentColor 92deg, #0000 0)
        top,
      conic-gradient(from -46deg at bottom, currentColor 92deg, #0000 0) bottom;
    background-size: 100% 50%;
    background-repeat: no-repeat;
  }
  .loader:before {
    content: "";
    position: absolute;
    inset: 0;
    --g: currentColor 14.5px, #0000 0 calc(100% - 14.5px), currentColor 0;
    background: linear-gradient(45deg, var(--g)),
      linear-gradient(-45deg, var(--g));
    animation: l7 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
  }
  @keyframes l7 {
    33% {
      inset: -10px;
      transform: rotate(0deg);
    }
    66% {
      inset: -10px;
      transform: rotate(90deg);
    }
    100% {
      inset: 0;
      transform: rotate(90deg);
    }
  }
`;
