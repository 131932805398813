import { Button, ButtonProps, Tooltip } from "@fluentui/react-components";
import React from "react";
import styled from "styled-components";

type Props = {
  tooltipContent?: string;
  handleClick?: () => void;
  icon?: JSX.Element;
} & ButtonProps;

export function ButtonWithTooltip({
  tooltipContent = "",
  handleClick,
  icon,
  ...rest
}: Props) {
  return (
    <Tooltip content={tooltipContent} relationship="description" withArrow>
      <StyledButton
        onClick={handleClick}
        icon={icon}
        appearance="transparent"
        {...rest}
      />
    </Tooltip>
  );
}

const StyledButton = styled(Button)`
  span {
    font-size: 23px;
    width: 23px;
    height: 23px;
  }
`;
