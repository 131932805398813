import { Text } from "@fluentui/react-components";
import InputField from "components/controls/InputField";
import useGetCurrencyOptionsPerUser from "hooks/useGetCurrencyOptionsPerUser";
import { usePrevious } from "hooks/usePrevious";
import { SelectItem } from "library/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { IPackageRowItem } from "./shared";

type Props = {
  item: IPackageRowItem;
  fieldName: string;
  currencyCode?: number;
};

export function PaidByCostCell({ item, fieldName, currencyCode }: Props) {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const currencyOptions = useGetCurrencyOptionsPerUser();

  const currency = currencyOptions.find(
    (currency) => currency.value === currencyCode
  ) as SelectItem;

  const currencyCodeShort = currency?.code?.split(".")[1];

  const isPercentageValue = !!item.usingPercentagesForPaidBy;

  const paidByCompany = item.paidByCompany;
  const paidByEmployee = item.paidByEmployee;

  const price = item.price;

  const handleTableUpdate = (value) => {
    if (value || value === "") {
      const updatedItem = {
        ...item,
        [fieldName]: value,
        isEdited: true,
      };
      item.updateState(updatedItem);
    }
  };

  const handleErrors = async (
    price,
    paidByCompany,
    paidByEmployee,
    isPercentageValue,
    item
  ) => {
    if (price) {
      if (isPercentageValue) {
        if (Number(paidByEmployee) + Number(paidByCompany) === 100) {
          setError("");
          if (error) {
            item.updateState(item, false, false);
          }
        } else {
          setError(t("hbh.paymentsPackagePriceEqualMsg.label"));
          item.updateState(item, false, true);
        }
        return;
      } else {
        if (
          Number(price) - Number(paidByCompany) - Number(paidByEmployee) !==
          0
        ) {
          setError(t("hbh.paymentsPackagePriceEqualMsg.label"));

          item.updateState(item, false, true);
        } else {
          setError("");
          if (error) {
            //with this condition prevent updateState on init
            item.updateState(item, false, false);
          }
        }
      }
    }
  };

  const prevPrice = usePrevious(price);
  const prevIsPercentage = usePrevious(isPercentageValue);
  useEffect(() => {
    if (price !== prevPrice || isPercentageValue !== prevIsPercentage) {
      handleErrors(
        price,
        paidByCompany,
        paidByEmployee,
        isPercentageValue,
        item
      );
    }
  }, [paidByCompany, paidByEmployee, price, isPercentageValue]);

  return (
    <Wrap hasError={!!error}>
      <InputField
        contentAfter={
          <Text size={400}>{isPercentageValue ? "%" : currencyCodeShort}</Text>
        }
        label=""
        name={fieldName}
        type={"number"}
        noLabel
        // noErrorSection={!error}
        noErrorSection
        value={item[fieldName].toString()}
        setFieldValue={(_, value) => {
          // handleTableUpdate(value);

          const isPaidByCompany = fieldName === "paidByCompany";

          if (isPaidByCompany) {
            if (isPercentageValue) {
              const paidByEmployeeValue =
                Number(value) < 101 ? 100 - Number(value) : "0";
              const paidByCompanyValue = Number(value) < 101 ? value : 100;

              const updatedItem = {
                ...item,
                paidByEmployee: paidByEmployeeValue || "0",
                paidByCompany: paidByCompanyValue || "0",
                isEdited: true,
              };
              item.updateState(updatedItem);
            } else {
              const paidByEmployeeValue =
                Number(value) < Number(price)
                  ? Number(price) - Number(value)
                  : "0";
              const paidByCompanyValue =
                Number(value) < Number(price) ? value : Number(price);

              const updatedItem = {
                ...item,
                paidByEmployee: paidByEmployeeValue || "0",
                paidByCompany: paidByCompanyValue || "0",
                isEdited: true,
              };
              item.updateState(updatedItem);
            }
          } else {
            if (isPercentageValue) {
              const paidByCompanyValue =
                Number(value) < 101 ? 100 - Number(value) : "0";
              const paidByEmployeeValue = Number(value) < 101 ? value : 100;

              const updatedItem = {
                ...item,
                paidByCompany: paidByCompanyValue || "0",
                paidByEmployee: paidByEmployeeValue || "0",

                isEdited: true,
              };
              item.updateState(updatedItem);
            } else {
              const paidByCompanyValue =
                Number(value) < Number(price)
                  ? Number(price) - Number(value)
                  : "0";
              const paidByEmployeeValue =
                Number(value) < Number(price) ? value : Number(price);

              const updatedItem = {
                ...item,
                paidByCompany: paidByCompanyValue || "0",
                paidByEmployee: paidByEmployeeValue || "0",
                isEdited: true,
              };
              item.updateState(updatedItem);
            }
          }
        }}
        error={error}
      />
    </Wrap>
  );
}

const Wrap = styled.div<{ hasError: boolean }>`
  ${(props) =>
    props.hasError &&
    css`
      .fui-Input {
        background-color: ${(props) =>
          props.theme.semanticColors.errorBackground};
      }
    `}
`;
