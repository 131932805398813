import {
  Button,
  CompoundButton,
  Link,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { Edit16Regular } from "@fluentui/react-icons";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import { IClient } from "library/types";
import React, { useCallback } from "react";
import styled from "styled-components";
import { EditClientDialog } from "./EditClientDialog";

type Props = {
  client: IClient;
};

const useStyles = makeStyles({
  headerImage: {
    width: "44px",
    height: "44px",
  },
  caption: {
    color: tokens.colorNeutralForeground3,
  },
  clientCard: {
    "& .fui-Button__icon": {
      width: "fit-content",
      height: "auto",
    },
  },
});

export function EditClient({ client }: Props) {
  const [open, setOpen] = React.useState(false);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);
  const styles = useStyles();

  return (
    <>
      <CompoundButton
        className={styles.clientCard}
        style={{
          background: "transparent",
          border: "none",
          height: "100%",
        }}
        icon={
          <Link href={client?.backgroundPicturePath || ""} target="_blank">
            <AvatarWithPreview
              shape="square"
              name={client?.clientName}
              imgSrc={client?.backgroundPicturePath || ""}
              avatarStyle={{
                borderRadius: "5px",
              }}
            />
          </Link>
        }
        // secondaryContent={country?.text}
      >
        <StyledButton
          appearance="transparent"
          style={{
            padding: "0px",
            border: "none",
          }}
          icon={<Edit16Regular />}
          aria-label="More options"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {client?.clientName}
        </StyledButton>
      </CompoundButton>

      {open && (
        <EditClientDialog
          client={client}
          open={open}
          setOpen={onDialogOpenChange}
        />
      )}
    </>
  );
}

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.themePrimary};
`;
