import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// -- Fabric UI

interface IProps {
  onNavigationStateChange: (value: boolean) => void;
  navigationPanelOpen: boolean;
  navigation?: JSX.Element;
}

export function NavigationPanel({
  navigation,
  onNavigationStateChange,
  navigationPanelOpen,
}: IProps) {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    onNavigationStateChange(false);
  }, [location.pathname]);
  return (
    <Drawer
      position="start"
      type={"overlay"}
      separator
      size="small"
      open={navigationPanelOpen}
      onOpenChange={(state) => {
        onNavigationStateChange(false);
      }}
      style={{
        top: "60px",
      }}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => {
                onNavigationStateChange(false);
              }}
            />
          }
        >
          {t("hbh.navigation.label")}
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody
        style={{
          padding: 10,
        }}
      >
        {navigation}
      </DrawerBody>
    </Drawer>
  );
}
