import { ArrowExportRegular } from "@fluentui/react-icons";
import { ManageAttachmentsDialog } from "components/ManageAttachmentsDialog/ManageAttachmentsDialog";
import { ActionButton, Flex } from "components/styled";
import { Attachment, IFormattedFile, IRowItem } from "library/types";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateBenefitImplementationAttachment } from "state/queries/useCreateBenefitImplementationAttachment";
import { useDeleteBenefitImplementationAttachment } from "state/queries/useDeleteBenefitImplementationAttachment";
import { useUpdateBenefitImplementationAttachment } from "state/queries/useUpdateBenefitImplementationAttachment";

type Props = {
  item: IRowItem;
};

export function ImplementedBenefitDocumentsCell({ item }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  const { mutate: onCreateAttachment, isLoading: createAttachmentInProgress } =
    useCreateBenefitImplementationAttachment();

  const { mutate: onDeleteAttachment, isLoading: deleteInProgress } =
    useDeleteBenefitImplementationAttachment();

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdateBenefitImplementationAttachment();

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          benefitCategoryTag: Number(item.benefitCategoryTag),
          benefitImplementationTag: Number(item.benefitImplementationTag),
          clientInternalNumberGOS: item.clientInternalNumberGos || 0,
          countryCode: item.countryCode || 0,
          payload: {
            ...attachment,
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    []
  );

  const handleCreateAttachment = useCallback(
    (newAttach: IFormattedFile | null) => {
      onCreateAttachment(
        {
          benefitCategoryTag: Number(item.benefitCategoryTag),
          benefitImplementationTag: Number(item.benefitImplementationTag),
          clientInternalNumberGOS: item.clientInternalNumberGos || 0,
          countryCode: item.countryCode || 0,
          payload: {
            attachmentName: newAttach?.name || "",
            attachmentContent: newAttach?.base64String || "",
            attachmentMimeType: newAttach?.type || "",
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          },
        }
      );
    },
    []
  );

  const handleDeleteAttachment = useCallback((attach: Attachment) => {
    onDeleteAttachment(
      {
        attachmentName: attach.attachmentName || "",
        benefitCategoryTag: Number(item.benefitCategoryTag),
        benefitImplementationTag: Number(item.benefitImplementationTag),
        clientInternalNumberGOS: item.clientInternalNumberGos || 0,
        countryCode: item.countryCode || 0,
        rowVersion: attach.rowVersion || "",
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.documentDeletedSuccessfully.label"));
        },
      }
    );
  }, []);

  const titleLabel = item?.attachments?.length
    ? t("hbh.manageDocuments.label")
    : t("hbh.addDocuments.label");

  return (
    <>
      <Flex justify="flex-start" gap={"5px"}>
        <ActionButton
          icon={<ArrowExportRegular />}
          noBorder
          appearance="transparent"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {titleLabel}
        </ActionButton>
      </Flex>
      {open && (
        <ManageAttachmentsDialog
          attachmentActionsState={{
            createAttachmentInProgress,
            onCreateAttachment: handleCreateAttachment,
            updateInProgress,
            onUpdateAttachment: handleUpdateAttachment,
            deleteInProgress,
            onDeleteAttachment: handleDeleteAttachment,
          }}
          item={item}
          open={open}
          setOpen={onDialogOpenChange}
        />
      )}
    </>
  );
}
