import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  employeeLoginEmailAddress: string;
  clientInternalNumberGOS: number | string;
  attachmentName: string;
  rowVersion: string;
};

const deleteAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  attachmentName,
  rowVersion,
}: Params) => {
  const response = await api.delete(
    `/employee/attachment/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${attachmentName}`,
    {
      data: {
        rowVersion,
      },
    }
  );
  return response.data;
};

export function useDeleteEmployeeAttachment(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();

  return useMutation(deleteAttachment, {
    mutationKey: MUTATION_KEYS.deleteEmployeeAttachment,
    onSuccess: () => {
      if (refetch) {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeDetails,
        });

        invalidateCompanyEmployees();
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
