import { atom } from "jotai";
import {
  IBenefitCategory,
  IBenefitImplementation,
  IBenefitPackage,
  IGrecoClient,
  SelectItem,
} from "library/types";

export const selectedCompanyState = atom<IGrecoClient | null>(null);
export const selectedCountryState = atom<SelectItem | null>(null);

export const filteredBenefitCategoriesState = atom<
  IBenefitCategory[] | undefined
>(undefined);

export const filteredBenefitImplementationState = atom<
  IBenefitImplementation[] | undefined
>(undefined);

export const filteredPackagesState = atom<IBenefitPackage[] | undefined>(
  undefined
);

export const categorySearchFilterValueState = atom<string>("");
export const implementationSearchFilterValueState = atom<string>("");
export const packagesSearchFilterValueState = atom<string>("");

export const onlyActiveImplementationsState = atom<boolean>(true);
