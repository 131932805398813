import React from "react";
import { ScrollableTree, Section, SectionItem } from "components/styled";
import BenefitsTree from "./components/benefits/BenefitsTree";
import BenefitsFilters from "./components/benefits/BenefitsFilters";
import BenefitsSelectedInfo from "./components/benefits/BenefitsSelectedInfo";

const BenefitsSection = () => {
  return (
    <Section>
      <SectionItem>
        <BenefitsFilters />
      </SectionItem>
      <SectionItem isflexible="true">
        <BenefitsTree />
        <BenefitsSelectedInfo />
      </SectionItem>
    </Section>
  );
};

export default BenefitsSection;
