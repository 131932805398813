import { STORAGE_KEYS } from "../../library/shared";

export const getHeaderConfig = (appName: string) => ({
  application_name: appName,
  search_visible: false,
  notifications_visible: true,
  help_visible: false,
  settings_visible: true,

  title_visible: true,
  darkMode:
    localStorage.getItem(STORAGE_KEYS.darkMode) === "true" ? true : false,
  tooltipsStatus:
    localStorage.getItem(STORAGE_KEYS.darkMode) === "true" ? true : false,
  settings_language_settings: true,
  app_launcher_visible: false,
  isMobile: false,
});
