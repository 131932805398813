import { breakpoints } from "library/constants";
import { useWindowWidth } from "./useWindowWidth";

export const useIsMobile = () => {
  const { width } = useWindowWidth();
  // const isMobile = width <= 768;
  const isMobile = width <= breakpoints.md;

  return isMobile;
};
