export const colorList = [
  {
    toneGroupName: "Gray/Silver Tones",
    colors: [
      {
        colorName: "DimGray",
        hexValue: "#696969",
        textColor: "white",
      },
      {
        colorName: "Silver",
        hexValue: "#c0c0c0",
        textColor: "#242424",
      },
      {
        colorName: "DarkSlateGray",
        hexValue: "#2f4f4f",
        textColor: "white",
      },
      {
        colorName: "SlateGray",
        hexValue: "#708090",
        textColor: "white",
      },
      {
        colorName: "Gray",
        hexValue: "#808080",
        textColor: "white",
      },
      {
        colorName: "Gainsboro",
        hexValue: "#dcdcdc",
        textColor: "#242424",
      },
      {
        colorName: "LightSteelBlue",
        hexValue: "#b0c4de",
        textColor: "#242424",
      },
      {
        colorName: "DarkGray",
        hexValue: "#a9a9a9",
        textColor: "white",
      },
      {
        colorName: "CadetBlue",
        hexValue: "#5f9ea0",
        textColor: "white",
      },
      {
        colorName: "LightBlue",
        hexValue: "#add8e6",
        textColor: "#242424",
      },
    ],
  },
  {
    toneGroupName: "Green Tones",
    colors: [
      {
        colorName: "DarkOliveGreen",
        hexValue: "#556b2f",
        textColor: "white",
      },
      {
        colorName: "SeaGreen",
        hexValue: "#2e8b57",
        textColor: "white",
      },
      {
        colorName: "Olive",
        hexValue: "#808000",
        textColor: "white",
      },
      {
        colorName: "MediumSeaGreen",
        hexValue: "#3cb371",
        textColor: "white",
      },
      {
        colorName: "LimeGreen",
        hexValue: "#32cd32",
        textColor: "white",
      },
      {
        colorName: "YellowGreen",
        hexValue: "#9acd32",
        textColor: "#242424",
      },
      {
        colorName: "PaleGreen",
        hexValue: "#98fb98",
        textColor: "#242424",
      },
      {
        colorName: "LightSeaGreen",
        hexValue: "#20b2aa",
        textColor: "white",
      },
      {
        colorName: "SpringGreen",
        hexValue: "#00ff7f",
        textColor: "#242424",
      },
      {
        colorName: "GreenYellow",
        hexValue: "#adff2f",
        textColor: "#242424",
      },
    ],
  },
  {
    toneGroupName: "Blue T tones",
    colors: [
      {
        colorName: "MidnightBlue",
        hexValue: "#191970",
        textColor: "white",
      },
      {
        colorName: "DarkSlateBlue",
        hexValue: "#483d8b",
        textColor: "white",
      },
      {
        colorName: "Navy",
        hexValue: "#000080",
        textColor: "white",
      },
      {
        colorName: "SteelBlue",
        hexValue: "#4682b4",
        textColor: "white",
      },
      {
        colorName: "MediumBlue",
        hexValue: "#0000cd",
        textColor: "white",
      },
      {
        colorName: "SlateBlue",
        hexValue: "#6a5acd",
        textColor: "white",
      },
      {
        colorName: "RoyalBlue",
        hexValue: "#4169e1",
        textColor: "white",
      },
      {
        colorName: "DeepSkyBlue",
        hexValue: "#00bfff",
        textColor: "#242424",
      },
      {
        colorName: "DodgerBlue",
        hexValue: "#1e90ff",
        textColor: "white",
      },
      {
        colorName: "SkyBlue",
        hexValue: "#87ceeb",
        textColor: "#242424",
      },
    ],
  },
  {
    toneGroupName: "Red Tones",
    colors: [
      {
        colorName: "DarkRed",
        hexValue: "#8b0000",
        textColor: "white",
      },
      {
        colorName: "Firebrick",
        hexValue: "#b22222",
        textColor: "white",
      },
      {
        colorName: "Crimson",
        hexValue: "#dc143c",
        textColor: "white",
      },
      {
        colorName: "IndianRed",
        hexValue: "#cd5c5c",
        textColor: "white",
      },
      {
        colorName: "Maroon",
        hexValue: "#800000",
        textColor: "white",
      },
      {
        colorName: "Tomato",
        hexValue: "#ff6347",
        textColor: "#242424",
      },
      {
        colorName: "Salmon",
        hexValue: "#fa8072",
        textColor: "#242424",
      },
      {
        colorName: "DarkSalmon",
        hexValue: "#e9967a",
        textColor: "#242424",
      },
      {
        colorName: "LightCoral",
        hexValue: "#f08080",
        textColor: "#242424",
      },
      {
        colorName: "Coral",
        hexValue: "#ff7f50",
        textColor: "#242424",
      },
    ],
  },
  {
    toneGroupName: "Yellow/Gold Tones",
    colors: [
      {
        colorName: "DarkGoldenrod",
        hexValue: "#b8860b",
        textColor: "white",
      },
      {
        colorName: "Goldenrod",
        hexValue: "#daa520",
        textColor: "white",
      },
      {
        colorName: "Gold",
        hexValue: "#ffd700",
        textColor: "#242424",
      },
      {
        colorName: "Yellow",
        hexValue: "#ffff00",
        textColor: "#242424",
      },
      {
        colorName: "Khaki",
        hexValue: "#f0e68c",
        textColor: "#242424",
      },
      {
        colorName: "PaleGoldenRod",
        hexValue: "#eee8aa",
        textColor: "#242424",
      },
      {
        colorName: "LaserLemon",
        hexValue: "#ffff54",
        textColor: "#242424",
      },
      {
        colorName: "DarkKhaki",
        hexValue: "#bdb76b",
        textColor: "white",
      },
      {
        colorName: "YellowGreen",
        hexValue: "#9acd32",
        textColor: "#242424",
      },
      {
        colorName: "LightYellow",
        hexValue: "#ffffe0",
        textColor: "#242424",
      },
    ],
  },
  {
    toneGroupName: "Orange Tones",
    colors: [
      {
        colorName: "DarkOrange",
        hexValue: "#ff8c00",
        textColor: "#242424",
      },
      {
        colorName: "OrangeRed",
        hexValue: "#ff4500",
        textColor: "white",
      },
      {
        colorName: "Orange",
        hexValue: "#ffa500",
        textColor: "#242424",
      },
      {
        colorName: "Tomato",
        hexValue: "#ff6347",
        textColor: "#242424",
      },
      {
        colorName: "SandyBrown",
        hexValue: "#f4a460",
        textColor: "#242424",
      },
      {
        colorName: "PeachPuff",
        hexValue: "#ffdab9",
        textColor: "#242424",
      },
      {
        colorName: "LightSalmon",
        hexValue: "#ffa07a",
        textColor: "#242424",
      },
      {
        colorName: "Bisque",
        hexValue: "#ffe4c4",
        textColor: "#242424",
      },
      {
        colorName: "Coral",
        hexValue: "#ff7f50",
        textColor: "#242424",
      },
      {
        colorName: "DarkSalmon",
        hexValue: "#e9967a",
        textColor: "#242424",
      },
    ],
  },
  {
    toneGroupName: "Brown Tones",
    colors: [
      {
        colorName: "SaddleBrown",
        hexValue: "#8b4513",
        textColor: "white",
      },
      {
        colorName: "Sienna",
        hexValue: "#a0522d",
        textColor: "white",
      },
      {
        colorName: "Brown",
        hexValue: "#a52a2a",
        textColor: "white",
      },
      {
        colorName: "RosyBrown",
        hexValue: "#bc8f8f",
        textColor: "#242424",
      },
      {
        colorName: "Peru",
        hexValue: "#cd853f",
        textColor: "#242424",
      },
      {
        colorName: "Chocolate",
        hexValue: "#d2691e",
        textColor: "white",
      },
      {
        colorName: "Tan",
        hexValue: "#d2b48c",
        textColor: "#242424",
      },
      {
        colorName: "Burlywood",
        hexValue: "#deb887",
        textColor: "#242424",
      },
      {
        colorName: "Wheat",
        hexValue: "#f5deb3",
        textColor: "#242424",
      },
      {
        colorName: "SandyBrown",
        hexValue: "#f4a460",
        textColor: "#242424",
      },
    ],
  },
  {
    toneGroupName: "Purple Tones",
    colors: [
      {
        colorName: "RebeccaPurple",
        hexValue: "#663399",
        textColor: "white",
      },
      {
        colorName: "Indigo",
        hexValue: "#4b0082",
        textColor: "white",
      },
      {
        colorName: "Purple",
        hexValue: "#800080",
        textColor: "white",
      },
      {
        colorName: "MediumOrchid",
        hexValue: "#ba55d3",
        textColor: "white",
      },
      {
        colorName: "DarkOrchid",
        hexValue: "#9932cc",
        textColor: "white",
      },
      {
        colorName: "BlueViolet",
        hexValue: "#8a2be2",
        textColor: "white",
      },
      {
        colorName: "MediumPurple",
        hexValue: "#9370db",
        textColor: "white",
      },
      {
        colorName: "Violet",
        hexValue: "#ee82ee",
        textColor: "#242424",
      },
      {
        colorName: "Plum",
        hexValue: "#dda0dd",
        textColor: "#242424",
      },
      {
        colorName: "Orchid",
        hexValue: "#da70d6",
        textColor: "white",
      },
    ],
  },
  {
    toneGroupName: "Cyan/Teal Tones",
    colors: [
      {
        colorName: "Teal",
        hexValue: "#008080",
        textColor: "white",
      },
      {
        colorName: "DarkCyan",
        hexValue: "#008b8b",
        textColor: "white",
      },
      {
        colorName: "MediumTurquoise",
        hexValue: "#48d1cc",
        textColor: "#242424",
      },
      {
        colorName: "MediumAquaMarine",
        hexValue: "#66cdaa",
        textColor: "#242424",
      },
      {
        colorName: "Aqua",
        hexValue: "#00ffff",
        textColor: "#242424",
      },
      {
        colorName: "Turquoise",
        hexValue: "#40e0d0",
        textColor: "#242424",
      },
      {
        colorName: "PaleTurquoise",
        hexValue: "#afeeee",
        textColor: "#242424",
      },
      {
        colorName: "LightSeaGreen",
        hexValue: "#20b2aa",
        textColor: "white",
      },
      {
        colorName: "CadetBlue",
        hexValue: "#5f9ea0",
        textColor: "white",
      },
      {
        colorName: "Aquamarine",
        hexValue: "#7fffd4",
        textColor: "#242424",
      },
    ],
  },
  {
    toneGroupName: "Pink Tones",
    colors: [
      {
        colorName: "Fuchsia",
        hexValue: "#ff00ff",
        textColor: "white",
      },
      {
        colorName: "DeepPink",
        hexValue: "#ff1493",
        textColor: "white",
      },
      {
        colorName: "HotPink",
        hexValue: "#ff69b4",
        textColor: "#242424",
      },
      {
        colorName: "PaleVioletRed",
        hexValue: "#db7093",
        textColor: "#242424",
      },
      {
        colorName: "MediumVioletRed",
        hexValue: "#c71585",
        textColor: "white",
      },
      {
        colorName: "Crimson",
        hexValue: "#dc143c",
        textColor: "white",
      },
      {
        colorName: "Pink",
        hexValue: "#ffc0cb",
        textColor: "#242424",
      },
      {
        colorName: "LightPink",
        hexValue: "#ffb6c1",
        textColor: "#242424",
      },
      {
        colorName: "Thistle",
        hexValue: "#d8bfd8",
        textColor: "#242424",
      },
      {
        colorName: "LavenderBlush",
        hexValue: "#fff0f5",
        textColor: "#242424",
      },
    ],
  },
];
