import React from "react";

import type { InputProps } from "@fluentui/react-components";
import { Input, Spinner, useId } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";

type Props = {
  label: string;
  name: string;
  info?: string;
  required?: boolean;
  isLoading?: boolean;
  hasClear?: boolean;
} & InputProps;
export function SearchInput({
  label,
  required,
  info,
  name,
  isLoading,
  hasClear = true,
  ...inputProps
}: Props) {
  const inputId = useId("input");
  const [value, setValue] = React.useState("");

  const onChange: InputProps["onChange"] = (ev, data) => {
    // The controlled input pattern can be used for other purposes besides validation,
    // but validation is a useful example
    if (data.value.length <= 20) {
      setValue(data.value);
    }
  };

  const errMessage = "";

  return (
    <FormFieldContainer>
      <FormFieldLabel label={label} required={!!required} info={info} />
      <Input
        appearance="underline"
        id={inputId}
        name={name}
        onChange={onChange}
        value={value}
        contentAfter={
          isLoading ? (
            <Spinner />
          ) : hasClear && value ? (
            <DismissRegular
              style={{
                cursor: value ? "pointer" : "inherit",
                opacity: value ? "1" : "0.5",
              }}
              onClick={() => {
                if (value) {
                  setValue("");
                }
              }}
            />
          ) : null
        }
        {...inputProps}
      />
      <ErrorMessageContainer>
        <StyledErrorMessage>{errMessage}</StyledErrorMessage>
      </ErrorMessageContainer>
    </FormFieldContainer>
  );
}
