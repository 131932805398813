import {
  makeStyles,
  Skeleton,
  SkeletonItem,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  innerWrapper: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "25px 1fr",
    gridGap: tokens.spacingHorizontalS,
  },
  skeleton: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  tableHeader: {},
  tableRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: "10px",
    position: "relative",
    gap: "10px",
    gridTemplateColumns: "repeat(8, 1fr)",
  },
});

type Props = {
  rowItems?: number;
};

export const TableSkeleton = ({ rowItems = 15 }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <Skeleton aria-label="Loading Content" className={styles.skeleton}>
        <SkeletonItem size={36} />

        {new Array(rowItems).fill(0).map((_, index) => (
          <div className={styles.tableRow} key={index}>
            <SkeletonItem size={36} />
            <SkeletonItem size={36} />
            <SkeletonItem size={36} />
            <SkeletonItem size={36} />
            <SkeletonItem size={36} />
            <SkeletonItem size={36} />
            <SkeletonItem size={36} />
            <SkeletonItem size={36} />
          </div>
        ))}
      </Skeleton>
    </div>
  );
};
