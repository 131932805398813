import React from "react";
import { useAtom, useAtomValue } from "jotai";
import { useAppTheme } from "hooks/useAppTheme";
import GenericTree from "components/GenericTree/GenericTree";
import { colorList } from "pages/HrPage/components/BulkManagementView/colorList";
import { ICompanyInfosInfo } from "library/types";
import {
  expandedBenefitsAtom,
  filterCurrencyAtom,
  selectedBenefitsAtom,
} from "store/UIHrPageDashboard";
import { Button } from "@fluentui/react-components";
import FilterCurrency from "./FilterCurrency";

/**
 * PackagesTree Component - Uses GenericTree to render benefits hierarchy.
 */

interface Props {
  companyInfo?: ICompanyInfosInfo;

  isLoading: boolean;
}

const FilterPackages = ({ companyInfo, isLoading }: Props) => {
  const theme = useAppTheme();
  const [selectedItems, setSelectedItems] = useAtom(selectedBenefitsAtom);
  const [expandedItems, setExpandedItems] = useAtom(expandedBenefitsAtom);
  const filterCurrency = useAtomValue(filterCurrencyAtom);

  const handleSelectDeselectAll = () => {
    if (selectedItems.size === treeData.length) {
      // Deselect all
      setSelectedItems(new Set());
    } else {
      // Select all
      const allIds = treeData.map((node) => node.id);
      setSelectedItems(new Set(allIds));
    }
  };

  const treeData = React.useMemo(() => {
    const result: any[] = [];
    if (!companyInfo) {
      return result;
    }
    //Creating category nodes
    const categories = {};
    companyInfo.benefitCategories?.forEach((benefitPackage) => {
      const categoryId = benefitPackage.benefitCategoryTag + "_category";
      if (!categories[categoryId]) {
        categories[categoryId] = {
          id: categoryId,
          content: benefitPackage.name,
          parentId: undefined,
          dataItem: benefitPackage,
        };
        result.push(categories[categoryId]);
      }
    });

    // Creating implementation nodes
    const implementations: any = {};
    companyInfo.benefitImplementations?.forEach((benefitPackage) => {
      const implementationId =
        benefitPackage.benefitImplementationTag + "_implementation";
      const categoryId = benefitPackage.benefitCategoryTag + "_category";
      if (!implementations[implementationId]) {
        implementations[implementationId] = {
          id: implementationId,
          content: benefitPackage.name,
          parentId: categoryId,
          dataItem: benefitPackage,
        };
        result.push(implementations[implementationId]);
      }
    });

    // Creating package nodes
    companyInfo.benefitPackages?.forEach((benefitPackage) => {
      const eligibleCount = companyInfo.employeeEnrollments?.filter(
        (enrol) => enrol.benefitPackageTag === benefitPackage.benefitPackageTag
      ).length;
      const enrolledCount = companyInfo.employeeEnrollments?.filter(
        (enrol) =>
          enrol.benefitPackageTag === benefitPackage.benefitPackageTag &&
          enrol.dateOfEnrollment !== null
      ).length;

      result.push({
        id: benefitPackage.benefitPackageTag + "_package",
        content:
          benefitPackage.name +
          " (" +
          enrolledCount +
          "/" +
          eligibleCount +
          ")",
        parentId: benefitPackage.benefitImplementationTag + "_implementation",
        dataItem: benefitPackage,
      });
    });

    return result;
  }, [companyInfo, filterCurrency]);

  React.useEffect(() => {
    if (companyInfo) {
      const allIds = treeData.map((node) => node.id);
      setSelectedItems(new Set(allIds));
    }
  }, [companyInfo, treeData, setSelectedItems]);

  const colorAssignments = React.useMemo(() => {
    const retVal: any = {};
    if (!companyInfo) {
      return retVal;
    }

    let colorGroupIndex = 0;
    let colorIndex = 0;

    companyInfo.benefitPackages?.forEach((item) => {
      const colorGroup: any = colorList[colorGroupIndex];
      const color = colorGroup?.colors[colorIndex] ?? {
        colorName: "",
        hexValue: "#ffffff",
        textColor: "#000000",
      };

      retVal[item.benefitPackageTag as number] = {
        colorGroup: colorGroup.toneGroupName,
        color: color.colorName,
        hexValue: color.hexValue,
        textColor: color.textColor,
      };

      colorIndex++;

      if (colorIndex >= colorGroup.colors.length) {
        colorIndex = 0;
        colorGroupIndex = (colorGroupIndex + 1) % colorList.length;
      }
    });

    return retVal;
  }, [companyInfo]);
  return (
    <GenericTree
      selectedItems={selectedItems}
      expandedItems={expandedItems}
      isLoading={false} //isLoading}
      treeData={treeData}
      onNodeSelect={(id, selectedItems) => setSelectedItems(selectedItems)}
      onNodeExpand={(id, expandedItems) => setExpandedItems(expandedItems)}
      colorAssignments={colorAssignments}
      heightOffset={600}
      rowHeight="auto"
      childRowHeight="auto"
      additionalButtons={
        <>
          <Button
            size="small"
            appearance="primary"
            title={
              selectedItems.size === treeData.length
                ? "Deselect All"
                : "Select All"
            }
            onClick={handleSelectDeselectAll}
            disabled={isLoading}
            style={{ marginRight: "8px" }}
          >
            {selectedItems.size === treeData.length
              ? "Deselect All"
              : "Select All"}
          </Button>
        </>
      }
    />
  );
};

export default FilterPackages;
