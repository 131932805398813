import { Dropdown, Option } from "@fluentui/react-components";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtom } from "jotai";
import { ETaxonomy } from "library/types";
import React from "react";
import { filterPaymentTypeAtom } from "store/UIHrPageDashboard";

const FilterPaymentType = () => {
  const paymentTypeOptions = useTaxonomyOptions(ETaxonomy.PaymentType);
  const [filterPaymentType, setFilterPaymentType] = useAtom(
    filterPaymentTypeAtom
  );

  const handleOptionSelect = (ev, data) => {
    if (data.optionValue === "") {
      setFilterPaymentType({
        code: "PaymentType.All",
        text: "All Payment Types",
        value: "",
      });
      return;
    }
    setFilterPaymentType(
      paymentTypeOptions.find((e) => e.value === data.optionValue)
    );
  };

  return (
    <Dropdown
      placeholder="Payment Type"
      title="Payment Type"
      style={{ marginTop: "10px" }}
      value={String(filterPaymentType?.text) || ""}
      onOptionSelect={handleOptionSelect}
    >
      <Option key={"All"} defaultChecked={true} value="">
        {"All Payment Types"}
      </Option>
      {paymentTypeOptions
        .sort((a, b) => a.text.localeCompare(b.text))
        .map((option) => (
          <Option key={option.code} value={option.value}>
            {option.text}
          </Option>
        ))}
    </Dropdown>
  );
};

export default FilterPaymentType;
