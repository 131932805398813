import type { SearchBoxProps } from "@fluentui/react-components";
import { SearchBox } from "@fluentui/react-components";
import { useAppTheme } from "hooks/useAppTheme";
import { debounce } from "library/utils";
import * as React from "react";

type Props = {
  onSearch: (searchValue: string) => void;
  value: string;
  width;
} & SearchBoxProps;

export const SearchBoxField = ({
  onSearch,
  value,
  style,
  width,
  ...props
}: Props) => {
  const { palette } = useAppTheme();
  const [localStateValue, setLocalStateValue] = React.useState("");

  const onChange = (data) => {
    setLocalStateValue(data.value);
  };

  const handleSearch = React.useCallback(
    debounce((searchValue) => {
      if (searchValue || searchValue === "") {
        onSearch(searchValue.value);
      }
    }, 500),
    []
  );

  React.useEffect(() => {
    if (!value) {
      setLocalStateValue("");
    } else {
      setLocalStateValue(value);
    }
  }, [value]);

  return (
    <SearchBox
      style={{
        ...style,
        width: width || "100%",
        background: palette.themeLighterAlt,
        borderColor: palette.neutralLight,
      }}
      value={localStateValue}
      onChange={(_, value) => {
        onChange(value);
        handleSearch(value);
      }}
      {...props}
    />
  );
};
