import { Radio, RadioGroup, RadioGroupProps } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import { useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
  name: string;
  label: string;
  error?: string;
  info?: string;
  noLabel?: boolean;
  noErrorSection?: boolean;
  disabled?: boolean;
  required?: boolean;
  options: { value: string; label: string }[];
  value: string;
  setFieldValue: (name: string, value: string) => void;
} & RadioGroupProps;

export const RadioGroupField = ({
  name,
  label,
  disabled,
  required = false,
  options = [],
  setFieldValue,
  info,
  value,
  noErrorSection,
  error,
  noLabel,
  ...props
}: Props) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  return (
    <Container>
      {noLabel ? null : (
        <FormFieldLabel
          label={label}
          required={required}
          disabled={disabled}
          info={info}
        />
      )}
      <RadioGroup
        id={name}
        layout="horizontal"
        value={localValue || ""}
        onChange={(e, data) => {
          e.stopPropagation();
          setFieldValue(name, data.value);
        }}
        {...props}
      >
        {options.map((option) => {
          return (
            <Radio
              key={option.value}
              value={option.value}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
      {noErrorSection ? null : (
        <ErrorMessageContainer>
          <StyledErrorMessage>{error}</StyledErrorMessage>
        </ErrorMessageContainer>
      )}
    </Container>
  );
};

const Container = styled(FormFieldContainer)`
  .fui-Radio__indicator:not(:checked) {
    border-color: ${({ theme }) => theme.palette.themePrimary} !important;
  }
`;
