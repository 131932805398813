import type { ComboboxProps } from "@fluentui/react-components";
import { Combobox, Option, useId } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { SelectItem } from "library/types";
import * as React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  label?: string;
  info?: string;
  options?: SelectItem[];
  placeholder?: string;
  value: SelectItem[] | null;
  setFieldValue: (name: string, value: SelectItem) => void;
  error: string;
  noErrorSection?: boolean;
  noLabel?: boolean;
  required?: boolean;
} & Partial<ComboboxProps>;

export const MultiSelectCombobox = ({
  label = "",
  options = [],
  name = "",
  info,
  value,
  placeholder = "",
  error = "",
  setFieldValue,
  noErrorSection = false,
  noLabel = false,
  required,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const comboId = useId("combo-default");
  const theme = useAppTheme();

  const selectedOption = value?.map((item) => item.value.toString());

  const [selectedOptions, setSelectedOptions] = React.useState<string[] | null>(
    selectedOption?.length ? selectedOption : null
  );

  const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
    const payloadValue = options.filter((option) =>
      (data.selectedOptions || [])?.includes(option.value.toString())
    );

    setFieldValue(name, payloadValue as any);
    setSelectedOptions(data.selectedOptions);
  };

  const selectedInputValues = options
    .filter((option) =>
      (selectedOptions || [])?.includes(option.value.toString())
    )
    .map((option) => option.text)
    .join(",");

  const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // setFieldValue(name, ev.target.value);
    // console.log(ev.target.value as any);
  };

  return (
    <FormFieldContainer>
      {!noLabel && (
        <FormFieldLabel label={label} required={!!required} info={info} />
      )}

      <Combobox
        autoComplete="one-time-code"
        appearance="underline"
        id={`${comboId}-controlled`}
        {...props}
        value={selectedInputValues}
        onInput={onInput}
        multiselect={true}
        selectedOptions={selectedOptions || []}
        aria-labelledby={comboId}
        placeholder={placeholder || t("greco.form.selectPlaceholder")}
        onOptionSelect={onOptionSelect}
      >
        {options.map((option) => (
          <Option
            key={option.value}
            disabled={false}
            text={option.text}
            value={option.value.toString()}
            style={{
              background: theme.palette.white,
            }}
          >
            {option?.Icon ?? ""}

            {option.text}
          </Option>
        ))}
      </Combobox>
      {!noErrorSection && (
        <ErrorMessageContainer>
          <StyledErrorMessage>{error}</StyledErrorMessage>
        </ErrorMessageContainer>
      )}
    </FormFieldContainer>
  );
};
