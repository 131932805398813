// @flow
import { Button, ButtonProps } from "@fluentui/react-components";
import * as React from "react";
import styled, { css } from "styled-components";
type Props = {} & ButtonProps;

export function PrimaryButton({ ...props }: Props) {
  return (
    <StyledButton type="submit" appearance="primary" {...props}>
      {props.children}
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  ${(props) =>
    !props.disabled &&
    css`
      background-color: ${(props) => props.theme.palette.themePrimary};
      &:hover {
        background-color: ${(props) => props.theme.palette.themeLight};
      }
    `}
`;
