import {
  Avatar,
  TableCellLayout,
  TableColumnSizingOptions,
  Text,
  createTableColumn,
} from "@fluentui/react-components";
import { Flex, StyledTooltip } from "components/styled";
import { format } from "date-fns";
import { TFunction } from "i18next";
import { ACTION_HISTORY_TRANSLATION_KEYS } from "library/constants";
import { ICompanyAction } from "library/types";
import { ActionDocumentsCell } from "./ActionDocumentsCell";

export const getActionHistoryColumns = ({
  t,
}: {
  t: TFunction<"translation", undefined>;
}) => [
  createTableColumn({
    columnId: "user",
    renderHeaderCell: () => <>{t("hbh.user.label")}</>,
    renderCell: (item: ICompanyAction) => {
      return (
        <StyledTooltip
          content={item.createdByUserEmail || ""}
          relationship="label"
        >
          <Flex>
            <Avatar name={item.createdByUserName} />
            <Text truncate block>
              {item.createdByUserName}
            </Text>
            {/* <Text>{`- ${item.createdByUserEmail}`}</Text> */}
          </Flex>
        </StyledTooltip>
      );
    },

    compare: (a, b) => {
      return (a?.createdByUserName || "").localeCompare(
        b?.createdByUserName || ""
      );
    },
  }),
  createTableColumn({
    columnId: "actionType",
    renderHeaderCell: () => <>{t("hbh.action.label")}</>,
    renderCell: (item: ICompanyAction) => {
      return (
        <TableCellLayout truncate>
          {t(ACTION_HISTORY_TRANSLATION_KEYS[item?.actionType || ""])}
        </TableCellLayout>
      );
    },

    compare: (a, b) => {
      return (a?.actionType || "").localeCompare(b?.actionType || "");
    },
  }),
  createTableColumn({
    columnId: "documentType",
    renderHeaderCell: () => <>{t("hbh.documents.label")}</>,
    renderCell: (item: ICompanyAction) => {
      return <ActionDocumentsCell item={item} />;
    },

    compare: (a, b) => {
      return (`${a.actionDocumentPath}` || "").localeCompare(
        b?.actionDocumentPath || ""
      );
    },
  }),
  createTableColumn({
    columnId: "createdAt",
    renderHeaderCell: () => <>{t("hbh.createdAt.label")}</>,
    renderCell: (item: ICompanyAction) => {
      return (
        <TableCellLayout truncate>
          {format(item?.createdAt || "", "dd.MM.yyyy HH:mm:ss")}
        </TableCellLayout>
      );
    },

    compare: (a, b) => {
      return (a?.createdAt || "").localeCompare(b?.createdAt || "");
    },
  }),
];

export const defaultColumnSizingOptions: TableColumnSizingOptions = {
  user: {
    idealWidth: 400,
    minWidth: 400,
    defaultWidth: 400,
  },
  actionType: {
    idealWidth: 300,
    minWidth: 300,
    defaultWidth: 300,
  },
  documentType: {
    idealWidth: 100,
    minWidth: 100,
    defaultWidth: 100,
  },
  createdAt: {
    idealWidth: 120,
    minWidth: 120,
    defaultWidth: 120,
  },
};
