import { FilteringCombobox } from "components/controls/FilteringCombobox";
import { useAppTheme } from "hooks/useAppTheme";
import useGetCountryOptionsPerUser from "hooks/useGetCountryOptionsPerUser";
import { useAtom, useSetAtom } from "jotai";
import { devices } from "library/constants";
import { SelectItem } from "library/types";
import { useTranslation } from "react-i18next";
import { countryCodeState, selectedCountryState } from "store/UICountryPage";
import styled from "styled-components";

type Props = {
  setSearchFilterValue: (value: string) => void;
};

export function CountryPageSection({ setSearchFilterValue }: Props) {
  const { palette } = useAppTheme();
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useAtom(selectedCountryState);
  const setCountryCode = useSetAtom(countryCodeState);

  const countryOptionsPerUser = useGetCountryOptionsPerUser();

  return (
    <Container>
      <FilteringCombobox
        error={""}
        placeholder={t("hbh.selectCountry.label")}
        style={{
          width: "100%",
          background: palette.themeLighterAlt,
          borderColor: palette.neutralLight,
        }}
        setFieldValue={(_, value: SelectItem) => {
          setCountryCode(Number(value?.value));
          setSelectedCountry(value);
          setSearchFilterValue("");
        }}
        noLabel
        noErrorSection
        value={selectedCountry as any}
        label={t("hbh.country.label")}
        options={countryOptionsPerUser}
        name={"country"}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 300px;

  @media only screen and ${devices.md} {
    width: 360px;
  }
`;
