import {
  Menu,
  MenuButton,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useOverflowMenu,
} from "@fluentui/react-components";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { OverflowMenuItem } from "./OverflowMenuItem";

export const OverflowMenu: React.FC<{ items: any[] }> = ({ items }) => {
  const { t } = useTranslation();
  const { ref, overflowCount, isOverflowing } =
    useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing) {
    return null;
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton appearance="transparent" ref={ref}>
          +{overflowCount} {t("hbh.items.label")}
        </MenuButton>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {items.map((item, index) => {
            return <OverflowMenuItem key={index} item={item} id={index} />;
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
