import InputField from "components/controls/InputField";
import { IRowItem } from "library/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  item: IRowItem;
};

export function BenefitCategoryNameCell({ item }: Props) {
  const { t } = useTranslation();
  const [localState, setLocalState] = useState(item.name);

  // const handleTableUpdate = debounce((value) => {
  //   if (value || value === "") {
  //     const updatedItem = {
  //       ...item,
  //       name: value,
  //     };
  //     item.updateState(updatedItem);
  //   }
  // }, 500);

  // const handleTableUpdate = (value) => {
  //   if (value || value === "") {
  //     const updatedItem = {
  //       ...item,
  //       name: value,
  //     };
  //     item.updateState(updatedItem);
  //   }
  // };

  useEffect(() => {
    setLocalState(item.name);
  }, [item.name]);

  return (
    <InputField
      onClick={(e) => e.stopPropagation()}
      onBlur={() => {
        const changeAccrued = localState !== item.name;

        if ((localState || localState === "") && changeAccrued) {
          const updatedItem = {
            ...item,
            name: localState,
            isEdited: true,
          };
          item.updateState(updatedItem);
        }
      }}
      label={t("hbh.benefitCategory.label")}
      name="name"
      noLabel
      noErrorSection
      value={localState || ""}
      setFieldValue={(_, value) => {
        setLocalState(value);
      }}
      error={""}
    />
  );
}
