import InputField from "components/controls/InputField";
import { IRowItem } from "library/types";
import { useTranslation } from "react-i18next";

type Props = {
  item: IRowItem;
};

function DescCell({ item }: Props) {
  const { t } = useTranslation();
  const handleTableUpdate = (value) => {
    if (value || value === "") {
      const updatedItem = {
        ...item,
        description: value,
        isEdited: true,
      };
      item.updateState(updatedItem);
    }
  };
  return (
    <InputField
      label={t("hbh.description.label")}
      name="textFieldDescription"
      // value={values["textFieldDescription"]}
      // setFieldValue={setFieldValue}
      // error={errors["benefit"]}
      noLabel
      noErrorSection
      value={item?.description || ""}
      setFieldValue={(_, value) => {
        handleTableUpdate(value);
      }}
      error={""}
    />
  );
}

export default DescCell;
