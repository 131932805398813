import DatePickerField from "components/controls/DatePickerField";
import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { Grid, RemoveButton } from "components/styled";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getDependentError } from "./helpers";
import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import CheckboxField from "components/controls/CheckboxField";
import { FilteringCombobox } from "components/controls/FilteringCombobox";

type Props = {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  item: any;
};

export function EmployeeDependentsFormItems({
  item,
  index,
  arrayHelpers,
}: Props) {
  const { t } = useTranslation();
  const { setFieldValue, errors } = useFormikContext<FormData>();
  const dependentType = `dependents[${index}].dependentType`;
  const dependentFirstName = `dependents[${index}].firstName`;
  const dependentLastName = `dependents[${index}].lastName`;
  const dependentEmail = `dependents[${index}].emailAddress`;
  const dependentMobilePhoneNumber = `dependents[${index}].mobilePhoneNumber`;
  const dependentPhoneNumber = `dependents[${index}].phoneNumber`;
  const dateOfBirth = `dependents[${index}].dateOfBirth`;
  const dependentCoverPhoto = `dependents[${index}].dependentCoverPhoto`;

  const dependentGender = `dependents[${index}].genderCodeId`;
  const dependentIdentificationNumber = `dependents[${index}].personIdentificationNumber`;
  const dependentSocialSecurityNumber = `dependents[${index}].socialSecurityNumber`;
  const dependentAddressNameLine1 = `dependents[${index}].addressNameLine1`;
  const dependentOrgUnitId = `dependents[${index}].orgUnitId`;
  const dependentDateOfExit = `dependents[${index}].dateOfExit`;
  const dependentIsInactive = `dependents[${index}].isInactive`;

  const dependentMiddleName = `dependents[${index}].middleName`;
  const dependentLeadingTitle = `dependents[${index}].leadingTitle`;
  const dependentTrailingTitle = `dependents[${index}].trailingTitle`;
  const dependentCitizenshipCodeId = `dependents[${index}].citizenshipCodeId`;
  const dependentPassportNumber = `dependents[${index}].passportNumber`;
  const dependentBirthplace = `dependents[${index}].birthplace`;

  const dependentTypeOptions = useTaxonomyOptions(ETaxonomy.DependentType);
  const genderOptions = useTaxonomyOptions(ETaxonomy.Gender);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);

  return (
    <Container>
      <Grid>
        <SelectField
          required
          error={getDependentError(errors, index, "dependentType")}
          setFieldValue={setFieldValue}
          value={item["dependentType"]}
          label={t("hbh.dependent.label")}
          options={dependentTypeOptions}
          name={dependentType}
          showEmptyOption
        />
        <InputField
          required
          label={t("hbh.firstName.label")}
          name={dependentFirstName}
          value={item["firstName"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "firstName")}
        />
        <InputField
          required
          label={t("hbh.lastName.label")}
          name={dependentLastName}
          value={item["lastName"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "lastName")}
        />
        <InputField
          required
          label={t("hbh.middleName.label")}
          name={dependentMiddleName}
          value={item["middleName"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "middleName")}
        />

        <InputField
          label={t("hbh.email.label")}
          name={dependentEmail}
          value={item["emailAddress"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "emailAddress")}
        />
        <InputField
          name={dependentMobilePhoneNumber}
          label={t("hbh.mobilePhoneNumber.label")}
          value={item["mobilePhoneNumber"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "mobilePhoneNumber")}
        />
        <InputField
          name={dependentPhoneNumber}
          label={t("hbh.phone.label")}
          value={item["phoneNumber"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "phoneNumber")}
        />
        <DatePickerField
          required
          name={dateOfBirth}
          error={getDependentError(errors, index, "dateOfBirth")}
          value={item["dateOfBirth"]}
          setFieldValue={setFieldValue}
          label={t("hbh.dateOfBirth.label")}
        />
        <UploadFile
          key={item["dependentCoverPhoto"]}
          setFieldValue={setFieldValue}
          fieldName={dependentCoverPhoto}
          defaultValue={item["dependentCoverPhoto"]}
          label={t("hbh.chooseCoverPicture.label")}
        />
      </Grid>
      <Grid>
        <SelectField
          label={t("hbh.gender.label")}
          options={genderOptions}
          error={getDependentError(errors, index, "genderCodeId")}
          setFieldValue={setFieldValue}
          value={item["genderCodeId"]}
          name={dependentGender}
          showEmptyOption
        />
        <InputField
          label={t("hbh.leadingTitle.label")}
          name={dependentLeadingTitle}
          value={item["leadingTitle"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "leadingTitle")}
        />
        <InputField
          label={t("hbh.trailingTitle.label")}
          name={dependentTrailingTitle}
          value={item["trailingTitle"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "trailingTitle")}
        />
        <InputField
          label={t("hbh.address.label")}
          name={dependentAddressNameLine1}
          value={item["addressNameLine1"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "addressNameLine1")}
        />
        <InputField
          label={t("hbh.birthplace.label")}
          name={dependentBirthplace}
          value={item["birthplace"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "birthplace")}
        />
        <FilteringCombobox
          error={getDependentError(errors, index, "citizenshipCodeId")}
          setFieldValue={setFieldValue}
          value={item["citizenshipCodeId"]}
          label={t("hbh.citizenship.label")}
          options={countryOptions}
          name={dependentCitizenshipCodeId}
        />
        <InputField
          label={t("hbh.passportNumber.label")}
          name={dependentPassportNumber}
          value={item["passportNumber"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "passportNumber")}
        />
        <InputField
          label={t("hbh.personIdentificationNumber.label")}
          name={dependentIdentificationNumber}
          value={item["personIdentificationNumber"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "personIdentificationNumber")}
        />
        <InputField
          label={t("hbh.socialSecurityNumber.label")}
          name={dependentSocialSecurityNumber}
          value={item["socialSecurityNumber"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "socialSecurityNumber")}
        />

        <InputField
          label={t("hbh.orgUnitId.label")}
          name={dependentOrgUnitId}
          value={item["orgUnitId"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "orgUnitId")}
        />

        <DatePickerField
          name={dependentDateOfExit}
          label={t("hbh.dateOfExit.label")}
          value={item["dateOfExit"]}
          setFieldValue={setFieldValue}
          error={getDependentError(errors, index, "dateOfExit")}
        />
        <CheckboxField
          key={item["isInactive"]}
          name={dependentIsInactive}
          value={item["isInactive"]}
          setFieldValue={setFieldValue}
          label={t("hbh.isInactive.label")}
        />
      </Grid>

      <RemoveBtnWrap>
        <RemoveButton
          onClick={() => {
            arrayHelpers.remove(index);
            // removeDependent(id);
          }}
        >
          {t("hbh.removeDependent.label")}
        </RemoveButton>
      </RemoveBtnWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  box-sizing: border-box;
  padding: 10px;
`;

const RemoveBtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
