import SelectField from "components/controls/SelectField";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy } from "library/types";
import { IPackageRowItem } from "./shared";

type Props = {
  item: IPackageRowItem;
  fieldName: string;

  taxonomyName: ETaxonomy;
};

export function SelectTaxonomyCell({ item, fieldName, taxonomyName }: Props) {
  const options = useTaxonomyOptions(ETaxonomy[taxonomyName]);

  const value = options.find((option) => {
    return option.value === item[fieldName];
  });

  return (
    <SelectField
      onClick={(e) => {
        e.stopPropagation();
      }}
      showEmptyOption
      error={""}
      setFieldValue={(name, newValue) => {
        const updatedValue = newValue?.value ? newValue.value : newValue;
        const updatedItem = {
          ...item,
          [fieldName]: updatedValue,
          isEdited: true,
        };
        item.updateState(updatedItem);
      }}
      value={value as any}
      label=""
      options={options}
      name={fieldName}
      noErrorSection
      noLabel
    />
  );
}
