import { AxiosError } from "axios";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IBenefitImplementation } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import api from "service/api";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  benefitImplementationTag?: number | string;
  payload: IBenefitImplementation;
};

const updateBenefitImplementation = async ({
  countryCode,
  benefitCategoryTag,
  clientInternalNumberGOS,
  benefitImplementationTag,
  payload,
}: Params): Promise<IBenefitImplementation> => {
  const response = await api.patch(
    `/benefitimplementation/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}/${benefitImplementationTag}`,
    {
      ...payload,
    }
  );

  return response.data as IBenefitImplementation;
};

export function useUpdateBenefitImplementation(refetch = true) {
  const queryClient = useQueryClient();

  return useMutation(updateBenefitImplementation, {
    mutationKey: MUTATION_KEYS.updateBenefitImplementation,
    onSuccess: () => {
      if (refetch) {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.benefitImplementations,
        });
        // queryClient.invalidateQueries({
        //   queryKey: QUERY_KEYS.benefitImplementationPackages,
        // });
      }
    },
    onError: (error: AxiosError) => {
      toast.error(error?.message);
    },
  });
}
