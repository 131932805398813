import {
  defaultColumnSizingOptions,
  getColumnsDef,
} from "components/ManageBenefitCategories/IncludedCategoriesTable/columns";
import { ManageBenefitCategories } from "components/ManageBenefitCategories/ManageBenefitCategories";

import { Analytics } from "components/Analytics/Analytics";
import { CategoryToolbar } from "components/ManageBenefitCategories/CategoryToolbar";
import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { PageContent } from "components/styled";
import { useAtom, useAtomValue } from "jotai";
import { columnNames } from "library/constants";
import { EAdminTabView } from "library/types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetBenefitCategories } from "state/queries/useGetBenefitCategories";
import { selectedAdminTabState } from "store";
import {
  filteredBenefitCategoriesState,
  searchFilterValueState,
} from "store/UIAdminPage";
import { Subheader } from "../../components/Subheader/Subheader";

export function AdminPage() {
  const { t } = useTranslation();
  const selectedTab = useAtomValue(selectedAdminTabState);

  const [filteredBenefitCategories, setFilteredBenefitCategories] = useAtom(
    filteredBenefitCategoriesState
  );

  const [searchFilterValue, setSearchFilterValue] = useAtom(
    searchFilterValueState
  );

  const { data: benefitCategoriesRes, isLoading } = useGetBenefitCategories({
    countryCode: 0,
    clientInternalNumberGOS: 0,
  });

  const columnsDef = getColumnsDef({
    countryCode: 0,
    hasSelection: true,
    t,
  });

  const benefitTableItems = useMemo(() => {
    return filteredBenefitCategories
      ?.map((category) => {
        const formattedData = {
          ...category,
          coverPhoto: {
            name: category.backgroundPicturePath,
            base64String: category.backgroundPictureContent,
            type: category.backgroundPictureMimeType,
          },
          id: category.benefitCategoryTag,
          defaultState: {
            ...category,
            coverPhoto: {
              name: category.backgroundPicturePath,
              base64String: category.backgroundPictureContent,
              type: category.backgroundPictureMimeType,
            },
            id: category.benefitCategoryTag,
          },
        };
        // formattedData.defaultState = formattedData;
        return formattedData;
      })
      .sort((a, b) => a.benefitCategoryTag - b.benefitCategoryTag);
  }, [filteredBenefitCategories]);

  const handleSearchFilterValue = useCallback(
    (value) => {
      setSearchFilterValue(value);
    },
    [setSearchFilterValue]
  );

  useEffect(() => {
    if (!benefitCategoriesRes?.benefitCategories) return;
    setFilteredBenefitCategories(
      benefitCategoriesRes?.benefitCategories?.filter((item) =>
        item.name.toLowerCase().includes(searchFilterValue)
      )
    );
  }, [benefitCategoriesRes, searchFilterValue, setFilteredBenefitCategories]);

  const loading = isLoading || !benefitTableItems;

  if (loading) {
    return <PageSkeleton />;
  }

  return (
    <>
      <Subheader
        searchFilterValue={searchFilterValue}
        items={filteredBenefitCategories}
        setSearchFilterValue={handleSearchFilterValue}
      />
      <CategoryToolbar />
      {selectedTab === EAdminTabView.benefits && (
        <>
          <PageContent>
            <ManageBenefitCategories
              countryCode={0}
              benefitCategories={filteredBenefitCategories}
              benefitTableItems={benefitTableItems}
              defaultColumnSizingOptions={defaultColumnSizingOptions}
              columns={columnsDef}
              columnNames={columnNames}
              selectionMode={"single"}
              onRowClick={() => {}}
            />
          </PageContent>
        </>
      )}
      {selectedTab === EAdminTabView.analytics && <Analytics />}
    </>
  );
}
