import { Button } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import { useFormikContext } from "formik";
import { IFormattedFile } from "library/types";
import { genUUID } from "library/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export function AddAttachments() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormData>();
  const [newAttach, setNewAttach] = useState<IFormattedFile | null>(null);

  const attachmentList = values["attachments"] || [];

  return (
    <div>
      <Title>{t("hbh.documentList.label")}</Title>
      <AttachmentList>
        {attachmentList?.map((attachment) => {
          return (
            <AttachmentItem key={genUUID()}>
              <div>{attachment.attachmentName}</div>
              <Button
                onClick={() => {
                  const filteredAttachmentList = attachmentList.filter(
                    (attach) => {
                      return (
                        attach.attachmentName !== attachment.attachmentName
                      );
                    }
                  );
                  setFieldValue("attachments", filteredAttachmentList);
                }}
                icon={<DeleteRegular />}
              />
            </AttachmentItem>
          );
        })}

        <AddNewAttachment>
          <UploadFile
            defaultValue={newAttach}
            setFieldValue={(_, newAttach) => {
              setNewAttach(newAttach);
              const updatedAttachments = [
                ...attachmentList,
                {
                  attachmentName: newAttach?.name,
                  attachmentContent: newAttach?.base64String || "",
                  attachmentMimeType: newAttach?.type || "",
                },
              ];
              setFieldValue("attachments", updatedAttachments);
              setTimeout(() => {
                setNewAttach(null); //hack to clear UploadFile default value
              }, 100);
            }}
            fieldName=""
            label=""
          />
        </AddNewAttachment>
      </AttachmentList>
    </div>
  );
}

const Title = styled.div`
  font-weight: 600;
  text-decoration: underline;
  font-size: 16px;
`;
const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  //   border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  margin: 10px 0;
`;

const AttachmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
`;

const AddNewAttachment = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
  padding: 5px;
`;
