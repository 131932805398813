import { ERole, ETaxonomy } from "library/types";
import { useMemo } from "react";
import { useGetAuthorization } from "state/queries/useGetAuthorization";
import { useTaxonomyOptions } from "./useTaxonomyOptions";

const countryCurrencies = {
  "Country.AD": "Currency.EUR", // Andorra - Euro
  "Country.AE": "Currency.AED", // United Arab Emirates - Dirham
  "Country.AF": "Currency.AFN", // Afghanistan - Afghani
  "Country.AG": "Currency.XCD", // Antigua and Barbuda - East Caribbean Dollar
  "Country.AI": "Currency.XCD", // Anguilla - East Caribbean Dollar
  "Country.AL": "Currency.ALL", // Albania - Lek
  "Country.AM": "Currency.AMD", // Armenia - Dram
  "Country.AN": "Currency.ANG", // Netherlands Antilles - Guilder
  "Country.AO": "Currency.AOA", // Angola - Kwanza
  "Country.AQ": "Currency.USD", // Antarctica - U.S. Dollar
  "Country.AR": "Currency.ARS", // Argentina - Peso
  "Country.AS": "Currency.USD", // American Samoa - U.S. Dollar
  "Country.AT": "Currency.EUR", // Austria - Euro
  "Country.AU": "Currency.AUD", // Australia - Dollar
  "Country.AW": "Currency.AWG", // Aruba - Florin
  "Country.AZ": "Currency.AZN", // Azerbaijan - Manat
  "Country.BA": "Currency.BAM", // Bosnia and Herzegovina - Convertible Mark
  "Country.BB": "Currency.BBD", // Barbados - Dollar
  "Country.BD": "Currency.BDT", // Bangladesh - Taka
  "Country.BE": "Currency.EUR", // Belgium - Euro
  "Country.BF": "Currency.XOF", // Burkina Faso - CFA Franc
  "Country.BG": "Currency.BGN", // Bulgaria - Lev
  "Country.BH": "Currency.BHD", // Bahrain - Dinar
  "Country.BI": "Currency.BIF", // Burundi - Franc
  "Country.BJ": "Currency.XOF", // Benin - CFA Franc
  "Country.BM": "Currency.BMD", // Bermuda - Dollar
  "Country.BN": "Currency.BND", // Brunei Darussalam - Dollar
  "Country.BO": "Currency.BOB", // Bolivia - Boliviano
  "Country.BR": "Currency.BRL", // Brazil - Real
  "Country.BS": "Currency.BSD", // Bahamas - Dollar
  "Country.BT": "Currency.BTN", // Bhutan - Ngultrum
  "Country.BV": "Currency.NOK", // Bouvet Island - Norwegian Krone
  "Country.BW": "Currency.BWP", // Botswana - Pula
  "Country.BY": "Currency.BYN", // Belarus - Ruble
  "Country.BZ": "Currency.BZD", // Belize - Dollar
  "Country.CA": "Currency.CAD", // Canada - Dollar
  "Country.CC": "Currency.AUD", // Cocos Islands - Australian Dollar
  "Country.CF": "Currency.XAF", // Central African Republic - CFA Franc
  "Country.CG": "Currency.XAF", // Republic of the Congo - CFA Franc
  "Country.CH": "Currency.CHF", // Switzerland - Franc
  "Country.CI": "Currency.XOF", // Ivory Coast - CFA Franc
  "Country.CK": "Currency.NZD", // Cook Islands - New Zealand Dollar
  "Country.CL": "Currency.CLP", // Chile - Peso
  "Country.CM": "Currency.XAF", // Cameroon - CFA Franc
  "Country.CN": "Currency.CNY", // China - Yuan
  "Country.CO": "Currency.COP", // Colombia - Peso
  "Country.CR": "Currency.CRC", // Costa Rica - Colón
  "Country.CU": "Currency.CUP", // Cuba - Peso
  "Country.CV": "Currency.CVE", // Cape Verde - Escudo
  "Country.CX": "Currency.AUD", // Christmas Island - Australian Dollar
  "Country.CY": "Currency.EUR", // Cyprus - Euro
  "Country.CZ": "Currency.CZK", // Czech Republic - Koruna
  "Country.DE": "Currency.EUR", // Germany - Euro
  "Country.DJ": "Currency.DJF", // Djibouti - Franc
  "Country.DK": "Currency.DKK", // Denmark - Krone
  "Country.DM": "Currency.XCD", // Dominica - East Caribbean Dollar
  "Country.DO": "Currency.DOP", // Dominican Republic - Peso
  "Country.DZ": "Currency.DZD", // Algeria - Dinar
  "Country.EC": "Currency.USD", // Ecuador - U.S. Dollar
  "Country.EE": "Currency.EUR", // Estonia - Euro
  "Country.EG": "Currency.EGP", // Egypt - Pound
  "Country.EH": "Currency.MAD", // Western Sahara - Moroccan Dirham
  "Country.ER": "Currency.ERN", // Eritrea - Nakfa
  "Country.ES": "Currency.EUR", // Spain - Euro
  "Country.ET": "Currency.ETB", // Ethiopia - Birr
  "Country.FI": "Currency.EUR", // Finland - Euro
  "Country.FJ": "Currency.FJD", // Fiji - Dollar
  "Country.FK": "Currency.FKP", // Falkland Islands - Pound
  "Country.FM": "Currency.USD", // Micronesia - U.S. Dollar
  "Country.FO": "Currency.DKK", // Faroe Islands - Danish Krone
  "Country.FR": "Currency.EUR", // France - Euro
  "Country.GA": "Currency.XAF", // Gabon - CFA Franc
  "Country.GB": "Currency.GBP", // United Kingdom - Pound
  "Country.GD": "Currency.XCD", // Grenada - East Caribbean Dollar
  "Country.GE": "Currency.GEL", // Georgia - Lari
  "Country.GF": "Currency.EUR", // French Guiana - Euro
  "Country.GG": "Currency.GBP", // Guernsey - Pound
  "Country.GH": "Currency.GHS", // Ghana - Cedi
  "Country.GI": "Currency.GIP", // Gibraltar - Pound
  "Country.GL": "Currency.DKK", // Greenland - Danish Krone
  "Country.GM": "Currency.GMD", // Gambia - Dalasi
  "Country.GN": "Currency.GNF", // Guinea - Franc
  "Country.GP": "Currency.EUR", // Guadeloupe - Euro
  "Country.GQ": "Currency.XAF", // Equatorial Guinea - CFA Franc
  "Country.GR": "Currency.EUR", // Greece - Euro
  "Country.GT": "Currency.GTQ", // Guatemala - Quetzal
  "Country.GU": "Currency.USD", // Guam - U.S. Dollar
  "Country.GW": "Currency.XOF", // Guinea-Bissau - CFA Franc
  "Country.GY": "Currency.GYD", // Guyana - Dollar
  "Country.HK": "Currency.HKD", // Hong Kong - Dollar
  "Country.HN": "Currency.HNL", // Honduras - Lempira
  "Country.HR": "Currency.EUR", // Croatia - Euro
  "Country.HT": "Currency.HTG", // Haiti - Gourde
  "Country.HU": "Currency.HUF", // Hungary - Forint
  "Country.ID": "Currency.IDR", // Indonesia - Rupiah
  "Country.IE": "Currency.EUR", // Ireland - Euro
  "Country.IL": "Currency.ILS", // Israel - Shekel
  "Country.IN": "Currency.INR", // India - Rupee
  "Country.IQ": "Currency.IQD", // Iraq - Dinar
  "Country.IR": "Currency.IRR", // Iran - Rial
  "Country.IS": "Currency.ISK", // Iceland - Króna
  "Country.IT": "Currency.EUR", // Italy - Euro
  "Country.JE": "Currency.GBP", // Jersey - British Pound
  "Country.JM": "Currency.JMD", // Jamaica - Jamaican Dollar
  "Country.JO": "Currency.JOD", // Jordan - Jordanian Dinar
  "Country.JP": "Currency.JPY", // Japan - Japanese Yen
  "Country.KE": "Currency.KES", // Kenya - Kenyan Shilling
  "Country.KG": "Currency.KGS", // Kyrgyzstan - Kyrgyzstani Som
  "Country.KH": "Currency.KHR", // Cambodia - Riel
  "Country.KI": "Currency.AUD", // Kiribati - Australian Dollar
  "Country.KM": "Currency.KMF", // Comoros - Comorian Franc
  "Country.KN": "Currency.XCD", // St. Kitts and Nevis - East Caribbean Dollar
  "Country.KP": "Currency.KPW", // North Korea - North Korean Won
  "Country.KR": "Currency.KRW", // South Korea - South Korean Won
  "Country.KV": "Currency.EUR", // Kosovo - Euro
  "Country.KW": "Currency.KWD", // Kuwait - Kuwaiti Dinar
  "Country.KY": "Currency.KYD", // Cayman Islands - Cayman Islands Dollar
  "Country.KZ": "Currency.KZT", // Kazakhstan - Kazakhstani Tenge
  "Country.LA": "Currency.LAK", // Laos - Lao Kip
  "Country.LB": "Currency.LBP", // Lebanon - Lebanese Pound
  "Country.LC": "Currency.XCD", // St. Lucia - East Caribbean Dollar
  "Country.LI": "Currency.CHF", // Liechtenstein - Swiss Franc
  "Country.LK": "Currency.LKR", // Sri Lanka - Sri Lankan Rupee
  "Country.LR": "Currency.LRD", // Liberia - Liberian Dollar
  "Country.LS": "Currency.LSL", // Lesotho - Lesotho Loti
  "Country.LT": "Currency.EUR", // Lithuania - Euro
  "Country.LU": "Currency.EUR", // Luxembourg - Euro
  "Country.LV": "Currency.EUR", // Latvia - Euro
  "Country.LY": "Currency.LYD", // Libya - Libyan Dinar
  "Country.MA": "Currency.MAD", // Morocco - Moroccan Dirham
  "Country.MC": "Currency.EUR", // Monaco - Euro
  "Country.MD": "Currency.MDL", // Moldova - Moldovan Leu
  "Country.ME": "Currency.EUR", // Montenegro - Euro
  "Country.MG": "Currency.MGA", // Madagascar - Malagasy Ariary
  "Country.MH": "Currency.USD", // Marshall Islands - U.S. Dollar
  "Country.MK": "Currency.MKD", // North Macedonia - Macedonian Denar
  "Country.ML": "Currency.XOF", // Mali - CFA Franc
  "Country.MM": "Currency.MMK", // Myanmar - Burmese Kyat
  "Country.MN": "Currency.MNT", // Mongolia - Tögrög
  "Country.MO": "Currency.MOP", // Macao - Pataca
  "Country.MP": "Currency.USD", // Northern Mariana Islands - U.S. Dollar
  "Country.MQ": "Currency.EUR", // Martinique - Euro
  "Country.MR": "Currency.MRU", // Mauritania - Ouguiya
  "Country.MS": "Currency.XCD", // Montserrat - East Caribbean Dollar
  "Country.MT": "Currency.EUR", // Malta - Euro
  "Country.MU": "Currency.MUR", // Mauritius - Mauritian Rupee
  "Country.MV": "Currency.MVR", // Maldives - Maldivian Rufiyaa
  "Country.MW": "Currency.MWK", // Malawi - Malawian Kwacha
  "Country.MX": "Currency.MXN", // Mexico - Mexican Peso
  "Country.MY": "Currency.MYR", // Malaysia - Malaysian Ringgit
  "Country.MZ": "Currency.MZN", // Mozambique - Mozambican Metical
  "Country.NA": "Currency.NAD", // Namibia - Namibian Dollar
  "Country.NC": "Currency.XPF", // New Caledonia - CFP Franc
  "Country.NE": "Currency.XOF", // Niger - CFA Franc
  "Country.NF": "Currency.AUD", // Norfolk Island - Australian Dollar
  "Country.NG": "Currency.NGN", // Nigeria - Naira
  "Country.NI": "Currency.NIO", // Nicaragua - Córdoba
  "Country.NL": "Currency.EUR", // Netherlands - Euro
  "Country.NO": "Currency.NOK", // Norway - Norwegian Krone
  "Country.NP": "Currency.NPR", // Nepal - Nepalese Rupee
  "Country.NR": "Currency.AUD", // Nauru - Australian Dollar
  "Country.NU": "Currency.NZD", // Niue - New Zealand Dollar
  "Country.NZ": "Currency.NZD", // New Zealand - New Zealand Dollar
  "Country.OM": "Currency.OMR", // Oman - Omani Rial
  "Country.PA": "Currency.PAB", // Panama - Balboa
  "Country.PE": "Currency.PEN", // Peru - Sol
  "Country.PF": "Currency.XPF", // French Polynesia - CFP Franc
  "Country.PG": "Currency.PGK", // Papua New Guinea - Kina
  "Country.PH": "Currency.PHP", // Philippines - Peso
  "Country.PK": "Currency.PKR", // Pakistan - Rupee
  "Country.PL": "Currency.PLN", // Poland - Zloty
  "Country.PM": "Currency.EUR", // Saint Pierre and Miquelon - Euro
  "Country.PN": "Currency.NZD", // Pitcairn - New Zealand Dollar
  "Country.PR": "Currency.USD", // Puerto Rico - U.S. Dollar
  "Country.PT": "Currency.EUR", // Portugal - Euro
  "Country.PW": "Currency.USD", // Palau - U.S. Dollar
  "Country.PY": "Currency.PYG", // Paraguay - Guarani
  "Country.QA": "Currency.QAR", // Qatar - Qatari Riyal
  "Country.RE": "Currency.EUR", // Reunion - Euro
  "Country.RO": "Currency.RON", // Romania - Leu
  "Country.RS": "Currency.RSD", // Serbia - Dinar
  "Country.RU": "Currency.RUB", // Russia - Ruble
  "Country.RW": "Currency.RWF", // Rwanda - Franc
  "Country.SA": "Currency.SAR", // Saudi Arabia - Riyal
  "Country.SB": "Currency.SBD", // Solomon Islands - Dollar
  "Country.SC": "Currency.SCR", // Seychelles - Rupee
  "Country.SD": "Currency.SDG", // Sudan - Pound
  "Country.SE": "Currency.SEK", // Sweden - Krona
  "Country.SG": "Currency.SGD", // Singapore - Dollar
  "Country.SH": "Currency.SHP", // Saint Helena - Pound
  "Country.SI": "Currency.EUR", // Slovenia - Euro
  "Country.SJ": "Currency.NOK", // Svalbard and Jan Mayen - Norwegian Krone
  "Country.SK": "Currency.EUR", // Slovakia - Euro
  "Country.SL": "Currency.SLL", // Sierra Leone - Leone
  "Country.SM": "Currency.EUR", // San Marino - Euro
  "Country.SN": "Currency.XOF", // Senegal - CFA Franc
  "Country.SO": "Currency.SOS", // Somalia - Shilling
  "Country.SR": "Currency.SRD", // Suriname - Dollar
  "Country.ST": "Currency.STN", // Sao Tome and Principe - Dobra
  "Country.SV": "Currency.USD", // El Salvador - U.S. Dollar
  "Country.SY": "Currency.SYP", // Syria - Syrian Pound
  "Country.SZ": "Currency.SZL", // Swaziland - Lilangeni
  "Country.TC": "Currency.USD", // Turks and Caicos Islands - U.S. Dollar
  "Country.TD": "Currency.XAF", // Chad - CFA Franc
  "Country.TF": "Currency.EUR", // French Southern Territories - Euro
  "Country.TG": "Currency.XOF", // Togo - CFA Franc
  "Country.TH": "Currency.THB", // Thailand - Baht
  "Country.TJ": "Currency.TJS", // Tajikistan - Somoni
  "Country.TK": "Currency.NZD", // Tokelau - New Zealand Dollar
  "Country.TM": "Currency.TMT", // Turkmenistan - Manat
  "Country.TN": "Currency.TND", // Tunisia - Dinar
  "Country.TO": "Currency.TOP", // Tonga - Paʻanga
  "Country.TR": "Currency.TRY", // Turkey - Lira
  "Country.TT": "Currency.TTD", // Trinidad and Tobago - Dollar
  "Country.TV": "Currency.AUD", // Tuvalu - Australian Dollar
  "Country.TW": "Currency.TWD", // Taiwan - New Taiwan Dollar
  "Country.TZ": "Currency.TZS", // Tanzania - Shilling
  "Country.UA": "Currency.UAH", // Ukraine - Hryvnia
  "Country.UG": "Currency.UGX", // Uganda - Shilling
  "Country.UM": "Currency.USD", // United States Minor Outlying Islands - U.S. Dollar
  "Country.US": "Currency.USD", // United States - Dollar
  "Country.UY": "Currency.UYU", // Uruguay - Peso
  "Country.UZ": "Currency.UZS", // Uzbekistan - Som
  "Country.VA": "Currency.EUR", // Vatican City - Euro
  "Country.VC": "Currency.XCD", // Saint Vincent and the Grenadines - East Caribbean Dollar
  "Country.VE": "Currency.VES", // Venezuela - Bolivar
  "Country.VG": "Currency.USD", // British Virgin Islands - U.S. Dollar
  "Country.VI": "Currency.USD", // Virgin Islands (USA) - U.S. Dollar
  "Country.VN": "Currency.VND", // Vietnam - Dong
  "Country.VU": "Currency.VUV", // Vanuatu - Vatu
  "Country.WF": "Currency.XPF", // Wallis and Futuna - CFP Franc
  "Country.WS": "Currency.WST", // Samoa - Tala
  "Country.YE": "Currency.YER", // Yemen - Riyal
  "Country.YT": "Currency.EUR", // Mayotte - Euro
  "Country.ZA": "Currency.ZAR", // South Africa - Rand
  "Country.ZM": "Currency.ZMW", // Zambia - Kwacha
  "Country.ZW": "Currency.ZWL", // Zimbabwe - Dollar
};

function useGetCurrencyOptionsPerUser() {
  const { data } = useGetAuthorization();

  const currencyOptions = useTaxonomyOptions(ETaxonomy.Currency);

  const currencyOptionsPerUser = useMemo(() => {
    const hasAdminRole = data?.roles?.some(
      (role) => role === ERole.GlobalAdmin || role === ERole.ReadonlyGlobalAdmin
    );

    let retVal: any[] = [];
    if (hasAdminRole) {
      retVal = currencyOptions;
    } else {
      // make list of unique currencies from data?.countries? and map of country to currency (countryCurrencies)
      const countryCodes = data?.countries;
      const uniqueCurrencies = new Set();
      countryCodes?.forEach((code) => {
        const currency = countryCurrencies[code];
        if (currency) {
          uniqueCurrencies.add(currency);
        }
      });
      retVal = currencyOptions?.filter((item) =>
        uniqueCurrencies.has(item.code)
      );
    }
    // List of commonly used items that should appear at the top
    const commonlyUsedCurrencies = [
      "Currency.EUR",
      "Currency.ALL",
      "Currency.BAM",
      "Currency.BGN",
      "Currency.CZK",
      "Currency.GEL",
      "Currency.HUF",
      "Currency.MKD",
      "Currency.PLN",
      "Currency.RON",
      "Currency.RSD",
      "Currency.TRY",
      "Currency.UAH",
    ];

    const priorityCurrencies = commonlyUsedCurrencies
      .map((code) => retVal.find((item) => item.code === code))
      .filter((item) => item !== undefined);

    const restOfTheCurrencies = retVal
      .filter((item) => !commonlyUsedCurrencies.includes(item.code))
      .sort((a, b) => a.text.localeCompare(b.text));

    // Move commonly used items to the top
    const prioritizedList = [...priorityCurrencies, ...restOfTheCurrencies];

    return prioritizedList;
  }, [currencyOptions, data?.countries, data?.roles]);

  return currencyOptionsPerUser;
}

export default useGetCurrencyOptionsPerUser;
