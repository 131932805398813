import { PersonEdit24Regular } from "@fluentui/react-icons";
import { ActionButton, StyledTooltip } from "components/styled";
import { EmployeeRowItem, IEmployeeView } from "library/types";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { DeletePackageUser } from "./DeletePackageUser";
import { EmployeeEligibility } from "./EmployeeEligibility/EmployeeEligibility";
import { ManageEmployee } from "./ManageEmployee/ManageEmployee";
import { EmployeeDependentsDialog } from "./ManageEmployee/UpdateEmployeeFields/EmployeeDependentsDialog/EmployeeDependentsDialog";
import { useTranslation } from "react-i18next";

type Props = {
  employeeData: EmployeeRowItem;
  dependentData?: IEmployeeView;
  countryCode?: number;
  stopPropagation?: boolean;
  clientInternalNumberGOS?: number;
};

export function EmployeeActions({
  employeeData,
  dependentData,
  stopPropagation,
}: Props) {
  const { t } = useTranslation();
  const [manageDependentsOpen, setManageDependentsOpen] = useState(false);

  const onManageDependentsOpen = useCallback((value) => {
    setManageDependentsOpen(value);
  }, []);

  return (
    <div
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      <ActionsWrap>
        {dependentData ? (
          <StyledTooltip
            relationship="label"
            content={{
              children: t("hbh.edit.label"),
            }}
          >
            <ActionButton
              icon={<PersonEdit24Regular />}
              noBorder
              appearance="transparent"
              onClick={(e) => {
                setManageDependentsOpen(true);
              }}
            />
          </StyledTooltip>
        ) : (
          <ManageEmployee
            item={employeeData}
            disabled={!!employeeData.disabled}
          />
        )}

        {/* <EmployeeEnrollments item={item} /> */}
        <EmployeeEligibility
          employeeData={employeeData}
          dependentData={dependentData}
        />
        <DeletePackageUser
          employeeData={employeeData}
          dependentData={dependentData}
          closeDialog={() => {
            onManageDependentsOpen(false);
          }}
        />
      </ActionsWrap>

      {manageDependentsOpen && dependentData && (
        <EmployeeDependentsDialog
          singleDependentEdit
          employeeData={employeeData}
          open={manageDependentsOpen}
          setOpen={onManageDependentsOpen}
          dependentData={dependentData}
        />
      )}
    </div>
  );
}

const ActionsWrap = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: center;
`;
