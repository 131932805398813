import { styledGoldDarkTheme, styledGoldTheme } from "./goldTheme";
import { darkStyledTheme, styledTheme } from "./mainTheme";
import { darkStyledPurpleTheme, styledPurpleTheme } from "./purpleTheme";

export const allThemes = {
  main: {
    light: styledTheme,
    dark: darkStyledTheme,
  },
  purple: {
    light: styledPurpleTheme,
    dark: darkStyledPurpleTheme,
  },
  gold: {
    light: styledGoldTheme,
    dark: styledGoldDarkTheme,
  },
};
