import type { PartitionBreadcrumbItems } from "@fluentui/react-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonProps,
  Menu,
  MenuItemLink,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Overflow,
  makeStyles,
  tokens,
  useIsOverflowItemVisible,
  useOverflowMenu,
} from "@fluentui/react-components";
import {
  MoreHorizontalFilled,
  MoreHorizontalRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import * as React from "react";
import { useTranslation } from "react-i18next";

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

type Props = {
  items: Item[];
};

type Item = {
  key: number;
  item?: string;
  itemProps?: {
    icon?: ButtonProps["icon"];
    disabled?: boolean;
    href?: string;
  };
  onClick?: () => void;
};

export const CompanyMobileBreadcrumb = ({ items }: Props) => {
  const styles = useStyle();

  return (
    <div className={styles.container}>
      <Overflow>
        <Breadcrumb>
          <OverflowMenu
            overflowItems={items}
            startDisplayedItems={[]}
            endDisplayedItems={[]}
          />
        </Breadcrumb>
      </Overflow>
    </div>
  );
};

const OverflowMenu = (props: PartitionBreadcrumbItems<Item>) => {
  const { t } = useTranslation();
  const { overflowItems } = props;
  const { ref, isOverflowing, overflowCount } =
    useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing && overflowItems && overflowItems.length === 0) {
    return null;
  }

  const overflowItemsCount = overflowItems
    ? overflowItems.length + overflowCount
    : overflowCount;

  return (
    <BreadcrumbItem>
      <Menu hasIcons>
        <MenuTrigger disableButtonEnhancement>
          <Button
            id="menu"
            appearance="subtle"
            ref={ref}
            icon={<MoreHorizontal />}
            aria-label={`${overflowItemsCount} ${t("hbh.moreItems.label")}`}
            role="button"
          />
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            {overflowItems &&
              overflowItems.map((item: Item) => (
                <MenuItem id={item.key.toString()} item={item} key={item.key} />
              ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </BreadcrumbItem>
  );
};

const MenuItem: React.FC<{ id: string; item: Item }> = (props) => {
  const { item, id } = props;
  const isVisible = useIsOverflowItemVisible(id);

  if (isVisible) {
    return null;
  }

  return (
    <MenuItemLink {...item.itemProps} onClick={item.onClick} href="#">
      {item.item}
    </MenuItemLink>
  );
};

const useStyle = makeStyles({
  container: {
    backgroundColor: tokens.colorNeutralBackground2,
    overflow: "hidden",
    zIndex: 0, //stop the browser resize handle from piercing the overflow menu
    height: "fit-content",
    minWidth: "0",
    resize: "horizontal",
    width: "max-content",
  },
});
