import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  TableCellLayout,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  NotepadPerson24Regular,
} from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import { Formik } from "formik";
import { useCreateEmployeeEnrollments } from "state/queries/useCreateEmployeeEnrollments";
import { selectedCompanyState } from "store/UIHrPage";
import EmployeeEnrollmentFormFields from "./EmployeeEnrollmentFormFields";
import { useEffect, useState } from "react";
import { formatDateFieldForPayload } from "library/utils";

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
  employeeItems?: any[];
  enrollmentItems?: any[];
  benefitItems?: any[];
};

export function EmployeeEnrollmentFormDialog({
  open,
  setOpen,
  employeeItems,
  enrollmentItems,
  benefitItems,
}: Props) {
  const selectedCompany = useAtomValue(selectedCompanyState);
  const [operationResult, setOperationResult] = useState<any>(null);
  const [showOperationResult, setShowOperationResult] = useState(false);

  const { mutate: onCreateEnrollments, isLoading: createInProgress } =
    useCreateEmployeeEnrollments();

  const styles = useStyles();

  useEffect(() => {
    if (open) {
      setShowOperationResult(false);
      setOperationResult(null);
    }
  }, [open]);

  const handleSubmit = (values) => {
    if (!benefitItems) return;
    let payloadItems: any[] = [];
    enrollmentItems?.forEach((enrollment) => {
      payloadItems.push({
        ...enrollment,
        dateOfEnrollment:
          formatDateFieldForPayload(values["dateOfEnrollment"]) ?? null,
        isAutoEnrollment: values["isAutoEnrollment"],
      });
    });

    onCreateEnrollments(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        payload: payloadItems,
      },
      {
        onSuccess: (data: any) => {
          setOperationResult(data);
          toast.success("Employee enrollments updated successfully");
          //setOpen(false);
        },
      }
    );
    return;
  };

  const title = operationResult
    ? "Operation completed successfully"
    : (enrollmentItems ?? [])?.length === 0
    ? `No appropriate employees selected`
    : `Enroll ${enrollmentItems?.length} employee(s) to ${benefitItems?.length} package(s)`;

  const submitLabel = "Enroll";

  const validationSchema = yup.object().shape({
    isAutoEnrollment: yup.boolean().required(),
    dateOfEnrollment: yup
      .mixed()
      .when("isAutoEnrollment", (isAutoEnrollment, schema) => {
        return isAutoEnrollment[0]
          ? schema.nullable() // When isAutoEnrollment is true, dateOfEligibility is not required
          : schema.required();
      }),
  });

  const initialValues = {
    isAutoEnrollment: false,
    dateOfEnrollment: null,
  };

  if (!benefitItems) return null;
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({ values, errors, setFieldValue, submitForm }) => {
        return (
          <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(event, data) => setOpen(data.open)}
          >
            <StyledDialogSurface
              // style={{
              //   bottom: "10%",
              // }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {createInProgress && <AppSpinner />}

              <DialogBody className={styles.content}>
                <DialogTitle
                  action={
                    <DialogTrigger action="close">
                      <Button
                        appearance="subtle"
                        aria-label="close"
                        icon={<Dismiss24Regular />}
                      />
                    </DialogTrigger>
                  }
                >
                  <Flex width={"100%"} justify="flex-start">
                    <NotepadPerson24Regular />
                    <TableCellLayout truncate>{title}</TableCellLayout>
                  </Flex>
                </DialogTitle>
                <DialogContent className={styles.content}>
                  {showOperationResult ? (
                    <code
                      dangerouslySetInnerHTML={{
                        __html: operationResult,
                      }}
                    ></code>
                  ) : operationResult ? null : (
                    <EmployeeEnrollmentFormFields />
                  )}
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">Close</Button>
                  </DialogTrigger>
                  {!showOperationResult && (
                    <PrimaryButton
                      disabled={
                        createInProgress ||
                        (!operationResult &&
                          (enrollmentItems ?? [])?.length === 0)
                      }
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                      onClick={() => {
                        operationResult
                          ? setShowOperationResult(true)
                          : submitForm();
                      }}
                    >
                      {operationResult ? "Show results" : submitLabel}
                    </PrimaryButton>
                  )}
                </DialogActions>
              </DialogBody>
            </StyledDialogSurface>
          </Dialog>
        );
      }}
    </Formik>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 550px;
    min-width: 550px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "550px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
