import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { WarningIcon } from "@fluentui/react-icons-mdl2";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { useAppTheme } from "hooks/useAppTheme";
import { CSSProperties } from "styled-components";

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  dismissLabel?: string;
  confirmLabel?: string;
  isDisabled?: boolean;
  onConfirm: () => void;
  toggleOpen: () => void;
  style?: CSSProperties;
};

export const ConfirmationDialog = ({
  title,
  description,
  isOpen,
  dismissLabel,
  onConfirm,
  confirmLabel,
  isDisabled = false,
  toggleOpen,
  style = {},
}: Props) => {
  const theme = useAppTheme();
  return (
    <Dialog modalType="alert" open={isOpen}>
      <DialogSurface style={style}>
        <DialogBody>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              color: theme.palette.redDark,
              gap: "5px",
            }}
          >
            <WarningIcon />
            {title}
          </DialogTitle>
          <DialogContent>{description}</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={toggleOpen}>
                {dismissLabel || "Close"}
              </Button>
            </DialogTrigger>
            <PrimaryButton onClick={onConfirm} disabled={isDisabled}>
              {confirmLabel || "Confirm"}
            </PrimaryButton>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
