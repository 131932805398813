import { QUERY_KEYS } from "library/shared";
import { IEmployeeDependent } from "library/types";
import { handleAxiosError } from "library/utils";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  employeeDependentTag: number;
  employeeLoginEmailAddress: string;
  enabled?: boolean;
};

const getDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
  employeeLoginEmailAddress,
}: Params) => {
  const response = await api.get(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${employeeDependentTag}`
  );
  return response.data;
};

export function useGetDependentDetails({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  employeeDependentTag,
  enabled = true,
}: Params): UseQueryResult<IEmployeeDependent> {
  return useQuery<IEmployeeDependent, Error>(
    [
      QUERY_KEYS.dependentDetails,
      countryCode,
      clientInternalNumberGOS,
      employeeLoginEmailAddress,
      employeeDependentTag,
    ],
    () =>
      getDependent({
        countryCode,
        clientInternalNumberGOS,
        employeeLoginEmailAddress,
        employeeDependentTag,
      }),
    {
      refetchOnMount: true,

      // cacheTime: 0,
      staleTime: 0,
      enabled: enabled,
      onError: (error: any) => {
        // errorHandler({
        //   label: "Error",
        //   type: notificationTypes.error,
        //   value: "Error while loading offer item data",
        //   errors: error?.message,
        //   fieldName: "",
        // });
        handleAxiosError(error);
      },
    }
  );
}

export const useInvalidateDependentDetails = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.dependentDetails,
    });
};
