import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Checkbox,
  Spinner,
} from "@fluentui/react-components";
import {
  MoreHorizontal20Regular,
  ChevronRight20Regular,
  ChevronDown20Regular,
} from "@fluentui/react-icons";

/**
 * GenericTree component that can be used for rendering hierarchical structures.
 * @param {Object[]} treeData - Array of tree nodes.
 * @param {Function} onNodeSelect - Callback for when a node is selected.
 * @param {Function} onNodeExpand - Callback for when a node is expanded or collapsed.
 * @param {Object} colorAssignments - Object for assigning colors to nodes.
 * @param {boolean} isLoading - Indicates if the component is in a loading state.
 * @param {number} heightOffset - Offset for calculating the tree container height.
 * @param {Set} selectedItems - Set of selected item IDs.
 * @param {Set} expandedItems - Set of expanded item IDs.
 * @param {boolean} selectChildren - Whether to automatically select/deselect children when a parent is selected.
 * @param {JSX.Element} additionalButtons - Array of additional button objects with title, handler function, and optional icon.
 * @param {boolean} useSelectBoxes - Whether to use select checkboxes.
 * @param {boolean} useExpandCollapse - Whether to use expand/collapse buttons.
 */

interface GenericTreeProps {
  selectedItems: Set<string>;

  expandedItems: Set<string>;

  isLoading?: boolean;

  treeData: any[];

  onNodeSelect: (id: string | null, selectedItems: Set<string>) => void;

  onNodeExpand: (id: string | null, expandedItems: Set<string>) => void;

  colorAssignments: any;

  heightOffset?: number;

  rowHeight: string;

  childRowHeight: string;

  selectChildren?: boolean;

  useSelectBoxes?: boolean;

  useExpandCollapse?: boolean;

  additionalButtons?: React.ReactNode;

  levelIndent?: number;
}

const GenericTree = ({
  treeData,
  onNodeSelect,
  onNodeExpand,
  colorAssignments,
  heightOffset = 440,
  isLoading = false,
  selectedItems,
  expandedItems,
  rowHeight = "32px",
  childRowHeight = "32px",
  selectChildren = true,
  additionalButtons = null,
  useSelectBoxes = true,
  useExpandCollapse = true,
  levelIndent = 20,
}: GenericTreeProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getAllChildIds = (nodes, parentId) => {
    const childIds = nodes
      .filter((node) => node.parentId === parentId)
      .map((node) => node.id);
    return childIds.reduce(
      (acc, id) => [...acc, id, ...getAllChildIds(nodes, id)],
      []
    );
  };

  const toggleSelection = (id) => {
    const newSelectedItems = new Set(selectedItems);
    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
      if (selectChildren) {
        const childIds = getAllChildIds(treeData, id);
        childIds.forEach((childId) => newSelectedItems.delete(childId));
      }
    } else {
      newSelectedItems.add(id);
      if (selectChildren) {
        const childIds = getAllChildIds(treeData, id);
        childIds.forEach((childId) => newSelectedItems.add(childId));
      }
    }
    if (onNodeSelect) {
      onNodeSelect(id, newSelectedItems);
    }
  };

  const toggleExpansion = (id) => {
    const newExpandedItems = new Set(expandedItems);
    if (newExpandedItems.has(id)) {
      newExpandedItems.delete(id);
    } else {
      newExpandedItems.add(id);
    }
    if (onNodeExpand) {
      onNodeExpand(id, newExpandedItems);
    }
  };

  const handleExpandCollapseAll = () => {
    if (isExpanded) {
      // Collapse all
      onNodeExpand(null, new Set());
    } else {
      // Expand all
      const allIds = treeData.map((node) => node.id);
      onNodeExpand(null, new Set(allIds));
    }
    setIsExpanded(!isExpanded); // Toggle the state
  };

  const renderTreeNodes = (
    nodes,
    parentId = undefined,
    level = 0,
    rowHeightVal = rowHeight,
    childRowHeightVal = childRowHeight
  ) => {
    return nodes
      .filter((node) => node.parentId === parentId)
      .map((node) => {
        const isSelected = selectedItems.has(node.id);
        const isExpanded = expandedItems.has(node.id);
        const hasChildren = nodes.some((n) => n.parentId === node.id);

        // Get the color for this node from colorAssignments
        const colorAssignment = colorAssignments[node.id];
        const hexValue = colorAssignment ? colorAssignment.hexValue : null; // Default to white if no color assigned
        const textColor = colorAssignment ? colorAssignment.textColor : null; // Default to black if no color assigned

        return (
          <React.Fragment key={node.id}>
            <TreeNodeContainer
              ischild={parentId ? "true" : "false"}
              level={level}
              levelIndent={levelIndent}
              onClick={() => {
                if (useSelectBoxes || node.useSelectBox) {
                  toggleSelection(node.id);
                }
              }}
              rowHeight={rowHeight}
              childRowHeight={childRowHeight}
            >
              {useExpandCollapse && hasChildren ? (
                <CaretPlaceholder>
                  <CaretIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpansion(node.id);
                    }}
                  >
                    {isExpanded ? (
                      <ChevronDown20Regular />
                    ) : (
                      <ChevronRight20Regular />
                    )}
                  </CaretIcon>
                </CaretPlaceholder>
              ) : (
                <div style={{ width: "22px", marginRight: "10px" }} /> // Empty space to align with chevron
              )}
              {(useSelectBoxes || node.useSelectBox) && (
                <FluentCheckbox
                  checked={isSelected}
                  onChange={() => toggleSelection(node.id)}
                />
              )}
              <ColorRectangle hexValue={hexValue} textColor={textColor}>
                {node.content}
              </ColorRectangle>
              <ActionsContainer>
                {node.hasActions &&
                  ((node.useSelectBox &&
                    Array.from(selectedItems).includes(node.id)) ||
                    !node.useSelectBox) && (
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <Button
                          aria-label="More options"
                          appearance="subtle"
                          icon={<MoreHorizontal20Regular />}
                          size="small"
                          onClick={(e) => e.stopPropagation()}
                        />
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          {node.actions?.map((action, index) => (
                            <MenuItem
                              key={index}
                              icon={action.icon}
                              onClick={(e) => {
                                e.stopPropagation();
                                action.onClick(node.id);
                              }}
                              disabled={action.disabled}
                            >
                              {action.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  )}
              </ActionsContainer>
            </TreeNodeContainer>
            {(useExpandCollapse ? isExpanded : true) &&
              renderTreeNodes(
                nodes,
                node.id,
                level + 1,
                rowHeight,
                childRowHeight
              )}
          </React.Fragment>
        );
      });
  };

  if (isLoading) {
    return (
      <div
        style={{
          height: `calc(100vh - ${heightOffset - 44}px)`,
          overflowY: "scroll",
        }}
      >
        <LoadingContainer>
          <Spinner size="large" label="Loading..." />
        </LoadingContainer>
      </div>
    );
  }

  return (
    <>
      <ButtonContainer>
        {useExpandCollapse && (
          <Button
            size="small"
            appearance="primary"
            icon={
              isExpanded ? <ChevronDown20Regular /> : <ChevronRight20Regular />
            }
            title={isExpanded ? "Collapse All" : "Expand All"}
            onClick={handleExpandCollapseAll}
            style={{ marginRight: "8px" }}
            disabled={isLoading}
          />
        )}
        {additionalButtons}
      </ButtonContainer>
      <div
        style={{
          height: `calc(100vh - ${heightOffset}px)`,
          overflowY: "scroll",
        }}
      >
        <TreeContainer>{renderTreeNodes(treeData)}</TreeContainer>
      </div>
    </>
  );
};

export default GenericTree;

// Styled components for layout and styling
const TreeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TreeNodeContainer = styled.div<{
  ischild: string;
  level: number;
  rowHeight: string;
  childRowHeight: string;
  levelIndent: number;
}>`
  display: flex;
  align-items: center;
  padding-left: ${({ level, levelIndent }) =>
    `${level * levelIndent}px`} !important; /* Indent children by level */
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #f0f0f0; /* Hover color */
  }
  height: ${({ ischild, childRowHeight, rowHeight }) =>
    ischild === "true" ? childRowHeight : rowHeight};
`;

const FluentCheckbox = styled(Checkbox)`
  margin-right: 8px;
  pointer-events: none; /* Ignore pointer events so that the parent div handles clicks */
`;

const CaretPlaceholder = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const CaretIcon = styled.div`
  cursor: pointer;
`;

const ColorRectangle = styled.span<{ hexValue: string; textColor: string }>`
  // height: 16px;
  padding: 5px 8px;
  background-color: ${({ hexValue }) => hexValue};
  color: ${({ textColor }) => textColor};
  margin-right: 8px; /* Add some space between the rectangle and the node content */
  border-radius: 6px; /* Slight rounding for a smoother look */
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ActionsContainer = styled.div`
  margin-left: auto;
`;
