import { Section, SectionItem } from "components/styled";
import { IEmployeeView } from "library/types";
import EmployeeFilters from "./components/employees/EmployeeFilters";
import EmployeeSelectedInfo from "./components/employees/EmployeeSelectedInfo";
import EmployeeTree from "./components/employees/EmployeeTree";

type Props = {
  companyEmployeeViews: IEmployeeView[];
  employeeViewsLoading: boolean;
};

const EmployeeSection = () => {
  return (
    <Section>
      <SectionItem>
        <EmployeeFilters />
      </SectionItem>
      <SectionItem isflexible="true">
        <EmployeeTree />
        <EmployeeSelectedInfo />
      </SectionItem>
    </Section>
  );
};

export default EmployeeSection;
