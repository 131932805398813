import { AddAttachments } from "components/AddAttachments/AddAttachments";
import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import DatePickerField from "components/controls/DatePickerField";
import { FilteringCombobox } from "components/controls/FilteringCombobox";
import InputField from "components/controls/InputField";
import { RadioGroupField } from "components/controls/RadioGroupField";
import SelectField from "components/controls/SelectField";
import { TextareaField } from "components/controls/TextareaField";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { checkedOptions } from "library/constants";
import { ECheckedValue, ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SelectInsurer } from "./SelectInsurer";
import useGetCurrencyOptionsPerUser from "hooks/useGetCurrencyOptionsPerUser";

export function ImplementBenefitFormItems() {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);
  const currencyOptions = useGetCurrencyOptionsPerUser();

  // const consentValues = useMemo(() => {
  //   return consentOptions.filter((option) => {
  //     console.log(consentOptions, "options");
  //     console.log(option, "option");
  //     return values["consentTypes"]?.includes(option.value);
  //   });
  // }, [consentOptions, values]);

  // const implementationConsentOptions = useMemo(() => { // use this if you want to filter options on implemented benefits based on selected options on category
  //   const categoryConsentTypes = category?.consentTypes || [];
  //   return consentOptions.filter((option) => {
  //     return categoryConsentTypes?.includes(option.value);
  //   });
  // }, [category?.consentTypes, consentOptions]);

  // const uploadDocuments = values["uploadDocuments"] === ECheckedValue.Yes;

  // useEffect(() => {
  //   const EUR = currencyOptions.find(
  //     (currency) => currency.text.toLowerCase() === "euro"
  //   ) as any;
  //   setFieldValue("currencyCode", EUR); //temp solution
  // }, [currencyOptions]);

  return (
    <Container>
      <Grid>
        <InputField
          label={t("greco.name")}
          name="name"
          value={values["name"]}
          setFieldValue={setFieldValue}
          error={errors["name"]}
          required
        />

        {/* <MultiSelectCombobox
          error={errors["consentTypes"]}
          setFieldValue={setFieldValue}
          value={consentValues as any}
          label="Type of Consent"
          options={consentOptions}
          name={"typeOfConsent"}
        /> */}
        <SelectField
          // required
          error={errors["consentTypes"]}
          setFieldValue={setFieldValue}
          value={values["consentTypes"]}
          label={t("hbh.typeOfConsent.label")}
          options={consentOptions}
          name={"consentTypes"}
          showEmptyOption
        />
        <UploadFile
          setFieldValue={setFieldValue}
          fieldName="coverPhoto"
          defaultValue={values["coverPhoto"]}
          label={t("hbh.chooseCoverPicture.label")}
        />

        <RadioGroupField
          name="uploadDocuments"
          value={values["uploadDocuments"]}
          setFieldValue={setFieldValue}
          label={t("hbh.uploadDocuments.label")}
          error={errors["uploadDocuments"]}
          options={checkedOptions}
        />
        <RadioGroupField
          value={values["consentsNeeded"]}
          setFieldValue={setFieldValue}
          name="consentsNeeded"
          label={t("hbh.consentNeeded.label")}
          error={errors["consentsNeeded"]}
          options={checkedOptions}
          // required
        />
        <RadioGroupField
          value={values["descriptionAsDocument"]}
          setFieldValue={setFieldValue}
          name="descriptionAsDocument"
          label={t("hbh.descriptionAsDocument.label")}
          options={checkedOptions}
          error={errors["descriptionAsDocument"]}
        />
        <FilteringCombobox
          error={errors["currencyCode"]}
          setFieldValue={setFieldValue}
          value={values["currencyCode"]}
          label={t("hbh.currency.label")}
          options={currencyOptions}
          name={"currencyCode"}
          required
        />
        <RadioGroupField
          value={values["enrollmentProcessNeeded"]}
          setFieldValue={setFieldValue}
          name="enrollmentProcessNeeded"
          label={t("hbh.enrollmentProcessNeeded.label")}
          options={checkedOptions}
          error={errors["enrollmentProcessNeeded"]}
        />
      </Grid>
      {/* {uploadDocuments && <AddAttachments />} */}
      <AddAttachments />

      <TextareaField
        label={t("hbh.description.label")}
        name="description"
        value={values["description"]}
        setFieldValue={setFieldValue}
        error={errors["description"]}
        rows={3}
        resize="vertical"
        required
      />
      <Grid gridColumns={3}>
        <SelectInsurer />
        <InputField
          required
          error={errors["carrierName"]}
          label={t("hbh.carrier.label")}
          name="carrierName"
          value={values["carrierName"]}
          setFieldValue={setFieldValue}
        />
        <InputField
          required
          error={errors["policyOrContractNumber"]}
          label={t("hbh.policyOrContractNumber.label")}
          name="policyOrContractNumber"
          value={values["policyOrContractNumber"]}
          setFieldValue={setFieldValue}
        />

        <DatePickerField
          required
          error={errors["validFrom"]}
          name="validFrom"
          value={values["validFrom"]}
          setFieldValue={setFieldValue}
          label={t("hbh.validFrom.label")}
        />
        <DatePickerField
          required
          name="validTo"
          value={values["validTo"]}
          setFieldValue={setFieldValue}
          error={errors["validTo"]}
          label={t("hbh.validTo.label")}
        />
      </Grid>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
