import { ArrowExportRegular } from "@fluentui/react-icons";
import { ActionButton, Flex } from "components/styled";
import { IRowItem } from "library/types";
import React, { useCallback } from "react";
import { ImplementBenefitDialog } from "../ImplementBenefitDialog/ImplementBenefitDialog";
import { useTranslation } from "react-i18next";

type Props = {
  item: IRowItem;
};
function ImplementBenefitCell({ item }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  return (
    <>
      <Flex justify="flex-start" gap={"5px"}>
        <ActionButton
          // disabled={!item?.selected}
          icon={<ArrowExportRegular />}
          noBorder
          appearance="transparent"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {t("hbh.implementBenefit.label")}
        </ActionButton>
      </Flex>
      {open && (
        <ImplementBenefitDialog
          isAddMode
          category={item}
          open={open}
          refetchCategoryOnAdd
          setOpen={onDialogOpenChange}
        />
      )}
    </>
  );
}

export default ImplementBenefitCell;
