import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import { useAppTheme } from "hooks/useAppTheme";
import { devices } from "library/constants";
import {
  Attachment,
  EmployeeRowItem,
  IFormattedFile,
  IRowItem,
} from "library/types";
import { IPackageRowItem } from "pages/CompanyPackagesPage/components/BenefitImplementationPackages/shared";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AttachmentItem } from "./AttachmentItem";
import { useTranslation } from "react-i18next";

type Props = {
  item: IRowItem | EmployeeRowItem | IPackageRowItem;
  open: boolean;
  setOpen: (open: boolean) => void;
  attachmentActionsState: {
    createAttachmentInProgress: boolean;
    deleteInProgress: boolean;
    onCreateAttachment: (attach: IFormattedFile | null) => void;
    onDeleteAttachment: (attach: Attachment) => void;
    onUpdateAttachment: ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
      };
    }) => void;
    updateInProgress: boolean;
  };
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "650px",
  },
  content: {
    top: "100px",
    maxHeight: "80vh",

    scrollbarWidth: "thin",
  },
});

export function ManageAttachmentsDialog({
  item,
  open,
  setOpen,
  attachmentActionsState,
}: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const [attachmentList, setAttachmentList] = React.useState<Attachment[]>([]);
  const [newAttach, setNewAttach] = useState<IFormattedFile | null>(null);
  const styles = useStyles();

  useEffect(() => {
    setAttachmentList(item.attachments || []);
  }, [item?.attachments]);

  const {
    deleteInProgress,
    createAttachmentInProgress,
    onCreateAttachment,
    updateInProgress,
    onDeleteAttachment,
    onUpdateAttachment,
  } = attachmentActionsState;
  return (
    <>
      <Dialog
        modalType="alert"
        open={open}
        onOpenChange={(event, data) => setOpen(data.open)}
      >
        <StyledDialogSurface
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DialogBody className={styles.content}>
            <DialogTitle
              action={
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                  />
                </DialogTrigger>
              }
            >
              {item?.name ?? `${item?.firstName} ${item.lastName}`}:{" "}
              {t("hbh.manageDocuments.label")}
            </DialogTitle>

            {deleteInProgress || createAttachmentInProgress ? (
              <AppSpinner />
            ) : null}
            <DialogContent className={styles.content}>
              <AttachmentList>
                <AddNewAttachment>
                  <UploadFile
                    defaultValue={newAttach}
                    setFieldValue={(_, file) => {
                      setNewAttach(file);
                    }}
                    fieldName=""
                    label=""
                  />
                  <Button
                    style={{
                      padding: "5px 15px",
                    }}
                    disabled={!newAttach}
                    icon={
                      <SaveIcon
                        style={{
                          color: theme.palette.themePrimary,
                          opacity: newAttach ? "1" : "0.5",
                        }}
                      />
                    }
                    // disabled={updateInProgress}
                    appearance="outline"
                    size="small"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await onCreateAttachment(newAttach);
                      setNewAttach(null);
                    }}
                  />
                </AddNewAttachment>
                {attachmentList?.map((attachment, index) => {
                  return (
                    <AttachmentItem
                      deleteInProgress={deleteInProgress}
                      onDeleteAttachment={onDeleteAttachment}
                      attachment={attachment}
                      updateInProgress={updateInProgress}
                      onUpdateAttachment={onUpdateAttachment}
                      item={item}
                      key={attachment.attachmentName + "_" + index}
                    />
                  );
                })}
              </AttachmentList>
            </DialogContent>
          </DialogBody>
        </StyledDialogSurface>
      </Dialog>
    </>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 800px;
    min-width: 800px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  //   border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  margin: 10px 0;
`;

const AddNewAttachment = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
  padding: 5px;
  margin: 20px 0;
`;
