import { ERole, ETaxonomy } from "library/types";
import { useMemo } from "react";
import { useGetAuthorization } from "state/queries/useGetAuthorization";
import { useTaxonomyOptions } from "./useTaxonomyOptions";

function useGetCountryOptionsPerUser() {
  const { data } = useGetAuthorization();

  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);

  const countryOptionsPerUser = useMemo(() => {
    const hasAdminRole = data?.roles?.some(
      (role) => role === ERole.GlobalAdmin || role === ERole.ReadonlyGlobalAdmin
    );

    let retVal: any[] = [];
    if (hasAdminRole) {
      retVal = countryOptions;
    } else {
      retVal = countryOptions?.filter((item) =>
        data?.countries?.includes(item.code)
      );
    }
    // List of commonly used items that should appear at the top
    const commonlyUsedCountries = [
      "Country.AL",
      "Country.AT",
      "Country.BA",
      "Country.BG",
      "Country.HR",
      "Country.CZ",
      "Country.EE",
      "Country.GE",
      "Country.HU",
      "Country.LV",
      "Country.LT",
      "Country.MK",
      "Country.PL",
      "Country.RO",
      "Country.RS",
      "Country.SK",
      "Country.SI",
      "Country.TR",
      "Country.UA",
    ];

    const priorityCountries = commonlyUsedCountries
      .map((code) => retVal.find((item) => item.code === code))
      .filter((item) => item !== undefined);

    const restOfTheCountries = retVal
      .filter((item) => !commonlyUsedCountries.includes(item.code))
      .sort((a, b) => a.text.localeCompare(b.text));

    // Move commonly used items to the top
    const prioritizedList = [...priorityCountries, ...restOfTheCountries];

    return prioritizedList;
  }, [countryOptions, data?.countries, data?.roles]);

  return countryOptionsPerUser;
}

export default useGetCountryOptionsPerUser;
