import CheckboxField from "components/controls/CheckboxField";
import DatePickerField from "components/controls/DatePickerField";
import { PackageValidationFields } from "components/PackageValidationFields/PackageValidationFields";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { IEmployeeView } from "library/types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PackageEditableFields } from "../../PackageEditableFields";
import { PackageSelectField } from "./PackageSelectField/PackageSelectField";
import { useAddEligibilityOptions } from "./useAddEligibilityOptions";
import { useCalculateDates } from "./useCalculateDates";

type Props = {
  isAddMode?: boolean;
  packageUser?: IEmployeeView;
  benefitPackageTag?: number;
};

export function EligibilityFormFields({
  packageUser,
  benefitPackageTag,
}: Props) {
  const { t } = useTranslation();
  const employeeDateOfEmployment = packageUser?.dateOfEmployment;
  const employeeDateOfExit = packageUser?.dateOfExit;

  const { values, setFieldValue, errors } = useFormikContext<FormData>();
  const { options: packagesOptions } = useAddEligibilityOptions({
    isAddMode: true,
    employeeLoginEmailAddress: packageUser?.employeeLoginEmailAddress || "",
    employeeEligibilities: packageUser?.enrollments || [],
    benefitPackageTag,
  });
  const packageOptionsAsSelectItem = packagesOptions.map((option) => {
    return {
      ...option,
      text: option.name,
      label: option.name,
      value: option.benefitPackageTag,
    };
  });

  const calculatedDates = useCalculateDates(
    values["package"],
    employeeDateOfEmployment,
    employeeDateOfExit
  );

  useEffect(() => {
    setFieldValue("isAutoEnrollment", values["package"]?.isAutoEnrollment);
    setFieldValue("dateOfEligibility", calculatedDates.startEligibilityDate);
    setFieldValue("endDateOfEligibility", calculatedDates.endEligibilityDate);
    setFieldValue(
      "cancellationPossibility",
      calculatedDates.cancellationPossibility
    );
  }, [values["package"]]);

  return (
    <>
      <Grid gridColumns={3}>
        <PackageSelectField
          disabled={!!benefitPackageTag}
          required
          error={errors["package"]}
          setFieldValue={setFieldValue}
          value={values["package"]}
          label={t("hbh.package.label")}
          options={packageOptionsAsSelectItem}
          name={"package"}
        />
        <DatePickerField
          required
          minDate={new Date(calculatedDates?.startEligibilityDate || "")}
          maxDate={new Date(calculatedDates?.endEligibilityDate || "")}
          error={errors["dateOfEligibility"]}
          name="dateOfEligibility"
          value={values["dateOfEligibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.eligibilityDate.label")}
        />
        <DatePickerField
          required
          minDate={new Date(calculatedDates?.startEligibilityDate || "")}
          error={errors["endDateOfEligibility"]}
          name="endDateOfEligibility"
          value={values["endDateOfEligibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.endEligibilityDate.label")}
        />
        <DatePickerField
          required
          error={errors["cancellationPossibility"]}
          name="cancellationPossibility"
          value={values["cancellationPossibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.cancellationPossibility.label")}
        />
        <CheckboxField
          key={values["isAutoEnrollment"]}
          name="isAutoEnrollment"
          value={values["isAutoEnrollment"]}
          setFieldValue={setFieldValue}
          label={t("hbh.isAutoEnrollment.label")}
        />
      </Grid>
      <Grid>
        <PackageEditableFields />
      </Grid>
      <PackageValidationFields
        isDependent={packageUser?.isDependent}
        packageData={{
          ...values["package"],
          dateOfEmployment: employeeDateOfEmployment,
        }}
      />
    </>
  );
}
