import { Button, Spinner } from "@fluentui/react-components";
import { ArrowUndoFilled, Open24Filled } from "@fluentui/react-icons";
import { EditIcon, SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ImplementBenefitDialog } from "components/ImplementBenefitDialog/ImplementBenefitDialog";
import { ActionButton, Flex } from "components/styled";
import { format } from "date-fns";
import { useAppTheme } from "hooks/useAppTheme";
import { ROUTES } from "library/constants";
import {
  IBenefitCategory,
  IBenefitImplementation,
  IRowItem,
} from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateBenefitImplementation } from "state/queries/useUpdateBenefitImplementation";

type Props = {
  item: IRowItem;
  countryCode: number | string;
  benefitCategory?: IBenefitCategory;
  clientInternalNumberGOS?: number | string;
  benefitCategoryTag?: number | string;
};

export function ImplementedBenefitActionsCell({
  item,
  countryCode,
  clientInternalNumberGOS = 0,
  benefitCategory,
  benefitCategoryTag = 0,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editOpen, setEditOpen] = useState(false);

  const { palette } = useAppTheme();
  const {
    mutate: updateImplementation,
    data: updatedData,

    isLoading: updateInProgress,
  } = useUpdateBenefitImplementation(false);

  const goToPackages = () => {
    navigate(
      `${ROUTES.COMPANY_PROFILE}/${item.benefitCategoryTag}/${item.benefitImplementationTag}/${item?.countryCode}/${item.clientInternalNumberGos}`
    );
  };

  const handleEditOpen = useCallback((state) => {
    setEditOpen(state);
  }, []);

  return (
    <>
      <div>
        {!item?.isEdited ? (
          <Flex width={"100%"} justify="center">
            <ActionButton
              noBorder
              appearance="transparent"
              icon={<EditIcon />}
              onClick={() => {
                console.log("click");
                setEditOpen(true);
              }}
            />
            <Button
              icon={
                <Open24Filled
                  style={{
                    color: palette.themePrimary,
                  }}
                />
              }
              disabled={updateInProgress}
              appearance="transparent"
              size="small"
              onClick={goToPackages}
            />
          </Flex>
        ) : updateInProgress ? (
          <>
            <Spinner size="extra-small" />
            <AppSpinner />
          </>
        ) : (
          <Flex width={"100%"} justify="center">
            <Button
              icon={
                <Open24Filled
                  style={{
                    color: palette.themePrimary,
                  }}
                />
              }
              disabled={updateInProgress}
              appearance="transparent"
              size="small"
              onClick={goToPackages}
            />
            <Button
              icon={
                <SaveIcon
                  style={{
                    color: palette.themePrimary,
                  }}
                />
              }
              disabled={updateInProgress}
              appearance="transparent"
              size="small"
              onClick={() => {
                updateImplementation(
                  {
                    countryCode,
                    clientInternalNumberGOS,
                    benefitCategoryTag,
                    benefitImplementationTag: item?.benefitImplementationTag,
                    payload: {
                      ...item,
                      ...(item.coverPhoto?.base64String && {
                        backgroundPictureContent: item.coverPhoto?.base64String,
                        backgroundPictureName: item.coverPhoto?.name,
                        backgroundPicturePath: item.coverPhoto?.path,
                        backgroundPictureMimeType: item.coverPhoto?.type,
                      }),
                      ...(!item.coverPhoto?.name && {
                        backgroundPictureContent: null,
                        backgroundPictureName: null,
                        backgroundPicturePath: null,
                        backgroundPictureMimeType: null,
                      }),
                      validFrom: format(item?.validFrom || "", "yyyy-MM-dd"),
                      validTo: format(item?.validTo || "", "yyyy-MM-dd"),
                    } as IBenefitImplementation,
                  },
                  {
                    onSuccess: (benefit) => {
                      const updatedItem = {
                        ...benefit,
                        coverPhoto: {
                          name: benefit.backgroundPicturePath,
                          base64String: benefit.backgroundPictureContent,
                          type: benefit.backgroundPictureMimeType,
                        },
                        defaultState: {
                          ...benefit,

                          coverPhoto: {
                            name: benefit.backgroundPicturePath,
                            base64String: benefit.backgroundPictureContent,
                            type: benefit.backgroundPictureMimeType,
                          },
                          benefitCategoryName: item?.benefitCategoryName,
                          id: benefit.benefitImplementationTag,
                        },
                        benefitCategoryName: item?.benefitCategoryName,

                        rowVersion: benefit.rowVersion,
                        isEdited: false,
                        id: benefit.benefitImplementationTag,
                      };
                      item.updateState(updatedItem as IBenefitImplementation);
                      toast.success(
                        t("hbh.implementationUpdatedSuccessfully.label")
                      );
                    },
                  }
                );
              }}
            />
            <Button
              icon={
                <ArrowUndoFilled
                  style={{
                    color: palette.redDark,
                  }}
                />
              }
              disabled={updateInProgress}
              appearance="transparent"
              size="small"
              onClick={() => {
                const updatedRowItem = updatedData as IRowItem;
                if (updatedRowItem) {
                  const updatedItem = {
                    ...updatedRowItem,
                    coverPhoto: {
                      name: updatedRowItem.backgroundPicturePath,
                      base64String: updatedRowItem.backgroundPictureContent,
                      type: updatedRowItem.backgroundPictureMimeType,
                    },
                    defaultState: {
                      ...updatedRowItem,
                      coverPhoto: {
                        name: updatedRowItem.backgroundPicturePath,
                        base64String: updatedRowItem.backgroundPictureContent,
                        type: updatedRowItem.backgroundPictureMimeType,
                      },
                      benefitCategoryName: item?.benefitCategoryName,
                      id: updatedRowItem.benefitImplementationTag,
                    },
                    benefitCategoryName: item?.benefitCategoryName,

                    isEdited: false,
                    id: updatedRowItem.benefitImplementationTag,
                  };
                  item.updateState(updatedItem as IBenefitImplementation, true);
                  return;
                }

                const updatedItem = {
                  ...item.defaultState,
                  defaultState: item.defaultState,
                  isEdited: false,
                } as any;
                item.updateState(updatedItem, true);
              }}
            />
          </Flex>
        )}
      </div>
      {editOpen && (
        <ImplementBenefitDialog
          category={benefitCategory as IBenefitCategory}
          open={editOpen}
          setOpen={handleEditOpen}
          benefitImplementation={item}
        />
      )}
    </>
  );
}
