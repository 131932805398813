import { SearchBoxField } from "components/controls/SearchBoxField";
import { useIsMobile } from "hooks/useIsMobile";
import { devices } from "library/constants";
import { IEmployeeView } from "library/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ResultsScore } from "../ResultsScore/ResultsScore";
import CompanyPageSection from "./CompanyPageSection/CompanyPageSection";
import { CountryPageSection } from "./CountryPageSection/CountryPageSection";
import { HubPageSection } from "./HubPageSection/HubPageSection";
// import { isListViewState } from "store/UIAdminPage";

type Props = {
  items: IEmployeeView[];
  setSearchFilterValue: (value: string) => void;
  disabled?: boolean;
  searchFilterValue: string;
  showSearchFilter?: boolean;
  isHrPage?: boolean;
  isCountryPage?: boolean;
  isCompanyPage?: boolean;
};

export function Subheader({
  items,
  setSearchFilterValue,
  disabled,
  searchFilterValue,
  showSearchFilter = true,
  isCountryPage,
  isHrPage,
  isCompanyPage,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const handleSearch = useCallback(
    (value) => {
      setSearchFilterValue(value.toLowerCase());
    },
    [setSearchFilterValue]
  );

  return (
    <Container showSearchFilter={showSearchFilter}>
      <Wrap>
        {/* <Navigation /> */}
        {isHrPage ? <HubPageSection /> : null}
        {isCountryPage ? (
          <CountryPageSection setSearchFilterValue={setSearchFilterValue} />
        ) : null}

        {isCompanyPage ? <CompanyPageSection /> : null}

        <SearchBoxField
          style={{
            ...(!showSearchFilter && { display: "none" }),
          }}
          disabled={disabled}
          value={searchFilterValue}
          appearance="outline"
          placeholder={t("greco.form.searchPlaceholder")}
          onSearch={handleSearch}
          width={isMobile ? "300px" : "360px"}
        />
        {/* <Button
          onClick={() => {
            handleAddNotification({
              id: "1",
              text: "This is Error example notification",
              type: "error",
            });
          }}
        >
          Add Error Notification
        </Button>
        <Button
          onClick={() => {
            handleAddNotification({
              id: "2",
              text: "This is Info example notification",
              type: "info",
            });
          }}
        >
          Add Info Notification
        </Button> */}
      </Wrap>

      {showSearchFilter && <ResultsScore score={items?.length || 0} />}
    </Container>
  );
}

const Container = styled.div<{ showSearchFilter?: boolean }>`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: auto;
  position: sticky;
  z-index: 99;
  top: 0px;
  height: ${(props) => (props.showSearchFilter ? "auto" : "52px")};
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  justify-content: space-between;
  gap: 5px;
  @media only screen and ${devices.md} {
    padding: 10px 30px;
  }
`;

const Wrap = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-start;

  flex-wrap: wrap;
  @media only screen and ${devices.md} {
    justify-content: flex-start;
  }
`;
