// /benefitcategory/attachment/{countryCode}/{clientInternalNumberGos}/{benefitCategoryTag}:
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IBenefitImplementation } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  attachmentName: string;
  rowVersion: string;
};

const deleteAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  attachmentName,
  rowVersion,
}: Params) => {
  const response = await api.delete(
    `/benefitcategory/attachment/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}/${attachmentName}`,
    {
      data: {
        rowVersion,
      },
    }
  );
  return response.data as Attachment;
};

export function useDeleteBenefitCategoryAttachment(refetch = true) {
  const queryClient = useQueryClient();
  return useMutation(deleteAttachment, {
    mutationKey: MUTATION_KEYS.deleteBenefitCategoryAttachment,
    onSuccess: () => {
      if (refetch) {
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitCategories,
        });
      }
      // queryClient.invalidateQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
