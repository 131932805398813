import { IBenefitCategory, SelectItem } from "library/types";
import styled from "styled-components";

import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import { IncludedCheckbox } from "components/IncludedCheckbox/IncludedCheckbox";
import { useAppTheme } from "hooks/useAppTheme";
import { useTranslation } from "react-i18next";

export type Item = {
  benefit: { label: string; row: SelectItem; icon: null; width: string };
  include: { label: string; row: SelectItem; icon: null; width: string };
};

type Props = {
  country?: SelectItem;
  rootBenefitCategories: IBenefitCategory[];
  includedBenefitCategories: IBenefitCategory[];
  setIncludedBenefitCategories: (data) => void;
};

export function SelectBenefitCategoriesTable({
  rootBenefitCategories,
  includedBenefitCategories,
  setIncludedBenefitCategories,
}: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();

  const items = rootBenefitCategories?.map((item) => {
    const includedValue = includedBenefitCategories?.find(
      (cat) => cat.benefitCategoryTag === item.benefitCategoryTag
    )?.included;
    return {
      name: item.name,
      included: includedValue ? "true" : "false",
      id: item.benefitCategoryTag,
    };
  });

  const columnsData = [
    {
      name: t("hbh.benefitCategory.label"),
      key: "name",
      width: "80%",
    },
    {
      name: t("hbh.included.label"),
      key: "included",
      width: "20%",
    },
  ];

  return (
    <TableWrap>
      <Table arial-label="Default table">
        <TableHeader>
          <TableRow>
            {columnsData?.map((column) => {
              return (
                <TableHeaderCell
                  key={column.key}
                  style={{
                    width: column.width,
                    background: theme.palette.themeLighter,
                  }}
                >
                  {column.name}
                </TableHeaderCell>
              );
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {items?.map((item) => (
            <TableRow
              key={item.name}
              onClick={() => {
                const included = item.included === "true";

                const data = includedBenefitCategories.map((category) => {
                  return {
                    ...category,
                    included:
                      category.benefitCategoryTag === item.id
                        ? !included
                        : category.included,
                  };
                });

                setIncludedBenefitCategories(data);
              }}
            >
              <TableCell>
                <TableCellLayout>{item.name}</TableCellLayout>
              </TableCell>

              <TableCell>
                <TableCellLayout>
                  <IncludedCheckbox value={item.included} />
                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrap>
  );
}

const TableWrap = styled.div`
  margin-top: 5px;
  table {
    border-collapse: collapse;
  }
  table,
  td,
  th {
    border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  }

  .fui-TableBody {
    .fui-TableRow:hover {
      background: ${({ theme }) => theme.palette.themeLighter};
      cursor: pointer;
    }
  }
`;
