import InputField from "components/controls/InputField";
import { IRowItem } from "library/types";
import { useEffect, useState } from "react";

type Props = {
  item: IRowItem;
};

export function ImplementedBenefitNameCell({ item }: Props) {
  const [localState, setLocalState] = useState(item.name);

  useEffect(() => {
    setLocalState(item.name);
  }, [item.name]);

  return (
    <InputField
      label={""}
      name="name"
      noLabel
      noErrorSection
      setFieldValue={(_, value) => {
        setLocalState(value);
      }}
      value={localState || ""}
      onBlur={() => {
        const changeAccrued = localState !== item.name;

        if ((localState || localState === "") && changeAccrued) {
          const updatedItem = {
            ...item,
            name: localState,
            isEdited: true,
          };
          item.updateState(updatedItem);
        }
      }}
      error={""}
    />
  );
}
