import { QUERY_KEYS } from "library/shared";
import { IExcelReportResponse } from "library/types";
import { handleAxiosError } from "library/utils";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

type Props = {
  enabled?: boolean;
  countryCode: number;
  clientInternalNumberGos: number;
};

export const generateEmployeeTemplate = async ({
  countryCode,
  clientInternalNumberGos,
}: Props) => {
  const response = await api.get(
    `/company/template/${countryCode}/${clientInternalNumberGos}`
  );
  return response.data as IExcelReportResponse;
};

export function useGenerateEmployeeTemplate({
  enabled = true,
  countryCode,
  clientInternalNumberGos,
}): UseQueryResult<IExcelReportResponse> {
  return useQuery<IExcelReportResponse, Error>(
    [QUERY_KEYS.generateEmployeeTemplate],
    () => {
      return generateEmployeeTemplate({
        countryCode,
        clientInternalNumberGos,
      });
    },
    {
      enabled: enabled,
      onError: (error: any) => {
        // errorHandler({
        //   label: "Error",
        //   type: notificationTypes.error,
        //   value: "Error while loading offer item data",
        //   errors: error?.message,
        //   fieldName: "",
        // });
        handleAxiosError(error);
      },
    }
  );
}
