import DatePickerField from "components/controls/DatePickerField";
import { IRowItem } from "library/types";

type Props = {
  item: IRowItem;
  fieldName: string;
};

export function DateCell({ item, fieldName }: Props) {
  const handleTableUpdate = (value) => {
    if (value || value === "") {
      const updatedItem = {
        ...item,
        [fieldName]: value,
        isEdited: true,
      };
      item.updateState(updatedItem);
    }
  };
  return (
    <DatePickerField
      disableInitSetValue
      label=""
      noErrorSection
      name={fieldName}
      value={new Date(item[fieldName])}
      setFieldValue={(_, value) => {
        handleTableUpdate(value);
      }}
    />
  );
}
