export const formattedNumber = new Intl.NumberFormat("de-AT", {
  style: "currency",
  currency: "EUR",
});

export const vibrantColors: any[] = [
  {
    backgroundColor: "rgba(255, 99, 132, 0.5)", // Strong Pink/Red
    borderColor: "rgba(255, 99, 132, 1)",
  },
  {
    backgroundColor: "rgba(255, 159, 64, 0.5)", // Vivid Orange
    borderColor: "rgba(255, 159, 64, 1)",
  },
  {
    backgroundColor: "rgba(255, 205, 86, 0.5)", // Strong Yellow
    borderColor: "rgba(255, 205, 86, 1)",
  },
  {
    backgroundColor: "rgba(75, 192, 192, 0.5)", // Vibrant Teal
    borderColor: "rgba(75, 192, 192, 1)",
  },
  {
    backgroundColor: "rgba(54, 162, 235, 0.5)", // Vivid Blue
    borderColor: "rgba(54, 162, 235, 1)",
  },
  {
    backgroundColor: "rgba(153, 102, 255, 0.5)", // Bright Purple
    borderColor: "rgba(153, 102, 255, 1)",
  },
  {
    backgroundColor: "rgba(201, 203, 207, 0.5)", // Strong Gray
    borderColor: "rgba(201, 203, 207, 1)",
  },
  {
    backgroundColor: "rgba(255, 87, 51, 0.5)", // Strong Red-Orange
    borderColor: "rgba(255, 87, 51, 1)",
  },
  {
    backgroundColor: "rgba(60, 179, 113, 0.5)", // Strong Medium Sea Green
    borderColor: "rgba(60, 179, 113, 1)",
  },
  {
    backgroundColor: "rgba(218, 165, 32, 0.5)", // Goldenrod
    borderColor: "rgba(218, 165, 32, 1)",
  },
  {
    backgroundColor: "rgba(255, 127, 80, 0.5)", // Coral
    borderColor: "rgba(255, 127, 80, 1)",
  },
  {
    backgroundColor: "rgba(106, 90, 205, 0.5)", // Strong Slate Blue
    borderColor: "rgba(106, 90, 205, 1)",
  },

  {
    backgroundColor: "rgba(255, 69, 0, 0.5)", // Bright Orange-Red
    borderColor: "rgba(255, 69, 0, 1)",
  },
  {
    backgroundColor: "rgba(72, 61, 139, 0.5)", // Dark Slate Blue
    borderColor: "rgba(72, 61, 139, 1)",
  },
  {
    backgroundColor: "rgba(32, 178, 170, 0.5)", // Vibrant Light Sea Green
    borderColor: "rgba(32, 178, 170, 1)",
  },
];

export const pastelColors: any[] = [
  {
    backgroundColor: "rgba(230, 230, 250, 0.5)", // Lavender
    borderColor: "rgba(230, 230, 250, 1)",
  },
  {
    backgroundColor: "rgba(255, 239, 213, 0.5)", // Papaya Whip
    borderColor: "rgba(255, 239, 213, 1)",
  },
  {
    backgroundColor: "rgba(221, 160, 221, 0.5)", // Plum
    borderColor: "rgba(221, 160, 221, 1)",
  },
  {
    backgroundColor: "rgba(255, 182, 193, 0.5)", // Light Pink
    borderColor: "rgba(255, 182, 193, 1)",
  },
  {
    backgroundColor: "rgba(255, 223, 186, 0.5)", // Light Peach
    borderColor: "rgba(255, 223, 186, 1)",
  },
  {
    backgroundColor: "rgba(255, 240, 245, 0.5)", // Lavender Blush
    borderColor: "rgba(255, 240, 245, 1)",
  },
  {
    backgroundColor: "rgba(152, 251, 152, 0.5)", // Pale Green
    borderColor: "rgba(152, 251, 152, 1)",
  },
  {
    backgroundColor: "rgba(135, 206, 250, 0.5)", // Light Sky Blue
    borderColor: "rgba(135, 206, 250, 1)",
  },
  {
    backgroundColor: "rgba(245, 222, 179, 0.5)", // Wheat
    borderColor: "rgba(245, 222, 179, 1)",
  },
  {
    backgroundColor: "rgba(176, 224, 230, 0.5)", // Powder Blue
    borderColor: "rgba(176, 224, 230, 1)",
  },
  {
    backgroundColor: "rgba(255, 228, 196, 0.5)", // Bisque
    borderColor: "rgba(255, 228, 196, 1)",
  },
  {
    backgroundColor: "rgba(255, 218, 185, 0.5)", // Peach Puff
    borderColor: "rgba(255, 218, 185, 1)",
  },
  {
    backgroundColor: "rgba(250, 250, 210, 0.5)", // Light Goldenrod Yellow
    borderColor: "rgba(250, 250, 210, 1)",
  },

  {
    backgroundColor: "rgba(240, 128, 128, 0.5)", // Light Coral
    borderColor: "rgba(240, 128, 128, 1)",
  },
  {
    backgroundColor: "rgba(250, 235, 215, 0.5)", // Antique White
    borderColor: "rgba(250, 235, 215, 1)",
  },
];
