import { Avatar, Text } from "@fluentui/react-components";
import { CertificateRegular, RibbonRegular } from "@fluentui/react-icons";
import { components } from "react-select";
import styled from "styled-components";

export const CustomPackageOption = (props) => {
  const data = props.data;

  return (
    <components.Option {...props}>
      <Wrap
        paddingLeft={`${data.level * 20 + 20}px`}
        opacity={data.isDisabled ? "0.5" : "1"}
      >
        {data.isCategory && (
          <RowWrapper>
            <CertificateRegular />
            <Text truncate block>
              {data?.benefitCategoryName}
            </Text>
          </RowWrapper>
        )}
        {data.isImplementation && (
          <RowWrapper>
            <RibbonRegular />
            <Text truncate block>
              {data?.benefitImplementationName}
            </Text>
          </RowWrapper>
        )}
        {data.isPackage && (
          <RowWrapper>
            <Avatar
              style={{
                borderRadius: "5px",
              }}
              size={20}
              name={`${data.name}`}
              image={{
                src: data.backgroundPicturePath,
              }}
            />
            <Text truncate block>
              {data?.name}
            </Text>
          </RowWrapper>
        )}
      </Wrap>
    </components.Option>
  );
};

const Wrap = styled.div<{ opacity?: string; paddingLeft: string }>`
  display: flex;
  align-items: center;
  opacity: ${(props) => props.opacity};
  padding-left: ${(props) => props.paddingLeft};
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 7px;
`;
