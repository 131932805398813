import type { TextareaProps } from "@fluentui/react-components";
import { Textarea } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";

type Props = {
  name: string;
  label: string;
  info?: string;
  required?: boolean;
  onActionBtnClick?: () => void;
  isLoading?: boolean;
  hasClear?: boolean;

  value: string;
  setFieldValue: (name: string, value: string) => void;
  error?: string;
  noLabel?: boolean;
  noErrorSection?: boolean;
} & TextareaProps;

export const TextareaField = ({
  name,
  label,
  required = false,
  hasClear,
  isLoading,
  info,
  error,
  setFieldValue,
  value,
  noLabel,
  noErrorSection,
  ...props
}: Props) => {
  const onChange: TextareaProps["onChange"] = (ev, data) => {
    setFieldValue(name, data.value);
  };

  return (
    <FormFieldContainer>
      {noLabel ? null : (
        <FormFieldLabel label={label} required={required} info={info} />
      )}
      <Textarea
        value={value || ""}
        onChange={onChange}
        {...props}
        appearance="outline"
      />
      {noErrorSection ? null : (
        <ErrorMessageContainer>
          <StyledErrorMessage>{error}</StyledErrorMessage>
        </ErrorMessageContainer>
      )}
    </FormFieldContainer>
  );
};
