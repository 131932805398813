import { QUERY_KEYS } from "library/shared";
import { IClient, IGrecoClient } from "library/types";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  letters?: string;
  enabled?: boolean;
};

export const getClients = async ({ countryCode }: Params) => {
  if (countryCode === null) return;

  const response = await api.get(`/client/${countryCode}`);

  return response.data.map((client) => {
    return {
      ...client,
      text: client?.clientName || "",
      value: client?.clientInternalNumberGos || "",
    };
  });
};

export function useGetClientsByCountry({
  countryCode,
  enabled = true,
}: Params): UseQueryResult<IClient[]> {
  return useQuery<IClient[], Error>(
    [QUERY_KEYS.clientsByCountry, countryCode],
    () => getClients({ countryCode }),
    {
      staleTime: 0,
      refetchOnMount: true,
      enabled: enabled,
      //   onError: (error: any) => {
      //     errorHandler({
      //       label: "Error",
      //       type: notificationTypes.error,
      //       value: "Error while loading offer item data",
      //       errors: error?.message,
      //       fieldName: "",
      //     });
      //   },
    }
  );
}
