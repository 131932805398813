import { OnlyActiveSwitch } from "components/OnlyActiveSwitch/OnlyActiveSwitch";
import { useAtom } from "jotai";
import { useCallback } from "react";
import { onlyActiveImplementationsState } from "store/UIHrPage";
import { EmployeesTable } from "./EmployeesTable/EmployeesTable";

export function EmployeeView() {
  const [onlyActiveImplementations, setOnlyActiveImplementation] = useAtom(
    onlyActiveImplementationsState
  );

  const onSetOnlyActiveImplementation = useCallback(
    (value) => {
      setOnlyActiveImplementation(value);
    },
    [setOnlyActiveImplementation]
  );

  return (
    <div>
      <OnlyActiveSwitch
        value={onlyActiveImplementations}
        setValue={onSetOnlyActiveImplementation}
      />
      <EmployeesTable />
    </div>
  );
}
