import type { ComboboxProps } from "@fluentui/react-components";
import { Combobox, Option, useId } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import { SelectItem } from "library/types";
import * as React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  label?: string;
  info?: string;
  options?: SelectItem[];
  placeholder?: string;
  value: SelectItem | null;
  setFieldValue: (name: string, value: SelectItem) => void;
  error: string;
} & Partial<ComboboxProps>;

export const ComboBoxField = ({
  label = "",
  options = [],
  name = "",
  info,

  value,
  placeholder = "",
  error = "",

  setFieldValue,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const comboId = useId("combo-default");

  const selectedOption = value;

  const [selectedOptions, setSelectedOptions] = React.useState<
    SelectItem[] | null
  >(selectedOption ? [selectedOption] : null);

  const [inputValue, setInputValue] = React.useState(
    selectedOption?.text || ""
  );

  const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // setFieldValue(name, ev.target.value);
    setInputValue(ev.target.value);
  };

  const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
    const selectedOption = data.optionValue
      ? {
          text: data.optionText ?? "",
          value: data.optionValue ?? "",
        }
      : null;

    setSelectedOptions(selectedOption ? [selectedOption] : null);
    setFieldValue(name, selectedOption as SelectItem);
    setInputValue(data.optionText ?? "");
  };

  return (
    <FormFieldContainer>
      <FormFieldLabel label={label} required={!!props?.required} info={info} />
      <Combobox
        autoComplete="one-time-code"
        appearance="underline"
        clearable
        id={`${comboId}-controlled`}
        {...props}
        value={inputValue}
        selectedOptions={selectedOptions || ([] as any)}
        onInput={onInput}
        aria-labelledby={comboId}
        placeholder={placeholder || t("greco.form.selectPlaceholder")}
        onOptionSelect={onOptionSelect}
      >
        {options.map((option) => (
          <Option
            key={option.value}
            disabled={false}
            text={option.text}
            value={option.value.toString()}
            style={
              {
                // background: theme.palette.white,
              }
            }
          >
            {option?.Icon ?? ""}

            {option.text}
          </Option>
        ))}
      </Combobox>
      <ErrorMessageContainer>
        <StyledErrorMessage>{error}</StyledErrorMessage>
      </ErrorMessageContainer>
    </FormFieldContainer>
  );
};
