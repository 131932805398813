import { makeStyles, Skeleton } from "@fluentui/react-components";
import { CardListWrap } from "components/styled";
import CardItemSkeleton from "./CardItemSkeleton";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },

  skeleton: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});

type Props = {
  cardNumber?: number;
};

export const CardListSkeleton = ({ cardNumber = 10 }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <Skeleton aria-label="Loading Content" className={styles.skeleton}>
        <CardListWrap>
          {new Array(cardNumber).fill(0).map((_, index) => (
            <CardItemSkeleton />
          ))}
        </CardListWrap>
      </Skeleton>
    </div>
  );
};
