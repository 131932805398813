import { TableCellLayout } from "@fluentui/react-components";
import { ROUTES } from "library/constants";
import { IRowItem } from "library/types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type Props = {
  item: IRowItem;
};

export function CategoryNameCell({ item }: Props) {
  const navigate = useNavigate();
  const goToPackages = () => {
    navigate(
      `${ROUTES.COMPANY_PROFILE}/${item.benefitCategoryTag}/${item.benefitImplementationTag}/${item?.countryCode}/${item.clientInternalNumberGos}`
    );
  };
  return (
    <CellValue truncate onClick={goToPackages}>
      {item.benefitCategoryName}
    </CellValue>
  );
}

export default CategoryNameCell;

const CellValue = styled(TableCellLayout)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.themeDark};
  font-weight: 600;
`;
