import { useAtomValue } from "jotai";
import { EmployeeRowItem, IEmployeeEnrollment } from "library/types";
import { companyPackagesState } from "store/UIHrPage";
import styled from "styled-components";
import PackageRowItem from "./SubrowComponent/PackageRowItem";

type Props = {
  employeeData: EmployeeRowItem;
  enrollment: EmployeeRowItem;
};
export function EnrolledEmployeeRow({ employeeData, enrollment }: Props) {
  const companyPackageObject = useAtomValue(companyPackagesState);

  const enrolledPackage = (employeeData.enrollments || []).find((enroll) => {
    return enroll.benefitPackageTag === enrollment.benefitPackageTag;
  });

  const packageFullLData = enrolledPackage
    ? (companyPackageObject || {})[enrolledPackage.benefitPackageTag]
    : null;

  return (
    <Container>
      <PackageRowItem
        enrolledPackage={enrolledPackage as IEmployeeEnrollment}
        packageData={packageFullLData}
        packageUser={employeeData}
        enrollment={enrollment}
      />
    </Container>
  );
}

const Container = styled.div`
  box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
  padding-right: 10px;
`;
