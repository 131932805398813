import { QUERY_KEYS } from "library/shared";
import { ERole } from "library/types";
import { handleAxiosError } from "library/utils";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

const getRole = async () => {
  const response = await api.get(`/role`);

  return response.data;
};

export function useGetRole(enabled = true): UseQueryResult<ERole[]> {
  return useQuery<ERole[], Error>([QUERY_KEYS.roles], getRole, {
    enabled: enabled,
    onError: (error: any) => {
      // errorHandler({
      //   label: "Error",
      //   type: notificationTypes.error,
      //   value: "Error while loading offer item data",
      //   errors: error?.message,
      //   fieldName: "",
      // });
      handleAxiosError(error);
    },
  });
}
