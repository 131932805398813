import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridProps,
  DataGridRow,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import {
  DocumentPdfRegular,
  DocumentRegular,
  EditRegular,
  FolderRegular,
  OpenRegular,
  PeopleRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import { useSingleAndDoubleClick } from "hooks/useSingleAndDoubleClick";
import React from "react";
import styled from "styled-components";
import { Item, columns } from "./tableColumns";

const items: Item[] = [
  {
    file: { label: "Meeting notes", icon: <DocumentRegular /> },
    author: { label: "Max Mustermann", status: "available" },
    lastUpdated: { label: "7h ago", timestamp: 1 },
    actions: {
      label: "",
      icon: <EditRegular />,
    },
  },
  {
    file: { label: "Thursday presentation", icon: <FolderRegular /> },
    author: { label: "Erika Mustermann", status: "busy" },
    lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    actions: {
      label: "",
      icon: <OpenRegular />,
    },
  },
  {
    file: { label: "Training recording", icon: <VideoRegular /> },
    author: { label: "John Doe", status: "away" },
    lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    actions: {
      label: "",
      icon: <OpenRegular />,
    },
  },
  {
    file: { label: "Purchase order", icon: <DocumentPdfRegular /> },
    author: { label: "Jane Doe", status: "offline" },
    lastUpdated: { label: "Tue at 9:30 AM", timestamp: 3 },
    actions: {
      label: "",
      icon: <PeopleRegular />,
    },
  },
];

export const TableDemo = () => {
  const refMap = React.useRef<Record<string, HTMLElement | null>>({});
  const [sortState, setSortState] = React.useState<
    Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
  >({
    sortColumn: "file",
    sortDirection: "ascending",
  });
  const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
    setSortState(nextSortState);
  };

  const columnSizingOptions = {
    // file: {
    //   minWidth: 80,
    //   defaultWidth: 120,
    // },
    // author: {
    //   defaultWidth: 180,
    //   minWidth: 120,
    //   idealWidth: 180,
    // },
  };

  const handleRowClick = useSingleAndDoubleClick(
    () => {
      console.log("single click");
    },
    () => {
      console.log("double click");
    }
  );

  return (
    <TableWrap>
      <DataGrid
        items={items}
        columns={columns}
        subtleSelection // select button is visible on hover
        sortable
        sortState={sortState}
        // selectionMode="multiselect"
        selectionMode="single"
        getRowId={(item) => item.file.label}
        focusMode="composite"
        onSortChange={onSortChange}
        resizableColumns
        columnSizingOptions={columnSizingOptions}
      >
        <DataGridHeader>
          <DataGridRow
            selectionCell={{
              checkboxIndicator: { "aria-label": "Select all rows" },
            }}
          >
            {({ renderHeaderCell, columnId }, dataGrid) =>
              dataGrid.resizableColumns ? (
                <Menu openOnContext>
                  <MenuTrigger>
                    <DataGridHeaderCell
                      ref={(el) => (refMap.current[columnId] = el)}
                    >
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(
                          columnId
                        )}
                      >
                        Keyboard Column Resizing
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ) : (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )
            }
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item>
              key={rowId}
              onClick={handleRowClick}
              onContextMenu={(data) => {
                console.log(item, "right clicked");
              }}
              selectionCell={{
                checkboxIndicator: { "aria-label": "Select row" },
              }}
              style={{
                background: item.author.status === "busy" ? "lightcoral" : "",
              }}
            >
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </TableWrap>
  );
};

const TableWrap = styled("div")`
  .fui-TableBody {
    .fui-TableRow:hover {
      background: ${({ theme }) => theme.palette.themeLighter};
    }
  }
`;
