import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitPackageTag: number;
  employeeDependentTag: number;
  employeeLoginEmailAddress: string;
  rowVersion: string;
};
const deleteEmployeeDependentEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  employeeDependentTag,
  benefitPackageTag,
  rowVersion,
}: Params) => {
  const response = await api.delete(
    `/employeeenrollment/dependent/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeDependentTag}`,
    {
      data: {
        rowVersion,
      },
    }
  );
  return response.data as Attachment;
};

export function useDeleteEmployeeDependentEnrollment(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();

  return useMutation(deleteEmployeeDependentEnrollment, {
    mutationKey: MUTATION_KEYS.deleteEmployeeDependentEnrollment,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEnrollmentsByEmployee,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEnrollmentsByPackage,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
