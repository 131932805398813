import * as React from "react";
import {
  Select,
  InteractionTag,
  InteractionTagPrimary,
  InteractionTagSecondary,
  TagGroup,
  Button,
  makeStyles,
  useId,
  TagGroupProps,
  Overflow,
  OverflowItem,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useIsOverflowItemVisible,
  useOverflowMenu,
  shorthands,
  tokens,
  tagClassNames,
} from "@fluentui/react-components";
import { useAtom } from "jotai";
import { companyEmployeeViewsAtom } from "store/UIHrPage";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between label, select, and tags
  },
  selectGroup: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between Select and Button
    padding: "5px",
  },
  select: {
    width: "200px", // Set the width of the Select component
  },
  tagGroup: {
    display: "flex",
    flexWrap: "wrap", // Allow tags to wrap to the next line if needed
    gap: "5px", // Space between tags
  },
  container: {
    overflow: "hidden",
    padding: "5px",
    zIndex: 0, // stop the browser resize handle from piercing the overflow menu
    height: "fit-content",
    minWidth: "415px",
    resize: "horizontal",
    width: "100%",
    boxSizing: "border-box",
  },
});
//----- OverflowMenuItem -----//

type OverflowMenuItemProps = {
  tag: {
    children: string;
    value: string;
  };
};

const useMenuItemStyles = makeStyles({
  menuItem: {
    padding: `${tokens.spacingVerticalSNudge} ${tokens.spacingHorizontalXS}`,
    ":hover": {
      [`& .${tagClassNames.root}`]: {
        color: tokens.colorNeutralForeground2Hover,
      },
    },
  },
  tag: {
    backgroundColor: "transparent",
    ...shorthands.borderColor("transparent"),
  },
});

/**
 * A menu item for an overflow menu that only displays when the tag is not visible
 */
const OverflowMenuItem = (props: OverflowMenuItemProps) => {
  const { tag } = props;
  const isVisible = useIsOverflowItemVisible(tag.value);

  const styles = useMenuItemStyles();

  if (isVisible) {
    return null;
  }

  return (
    <MenuItem key={tag.value} className={styles.menuItem}>
      <InteractionTag key={tag.value} value={tag.value} size="small">
        <InteractionTagPrimary hasSecondaryAction>
          {tag.children}
        </InteractionTagPrimary>
        <InteractionTagSecondary aria-label="remove" />
      </InteractionTag>
    </MenuItem>
  );
};

//----- OverflowMenu -----//

/**
 * A menu for viewing tags that have overflowed and are not visible.
 */
const OverflowMenu = ({
  visibleTags,
}: {
  visibleTags: Array<{ children: string; value: string }>;
}) => {
  const { ref, isOverflowing, overflowCount } =
    useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing) {
    return null;
  }

  return (
    <InteractionTag size="small">
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <InteractionTagPrimary
            ref={ref}
            aria-label={`${overflowCount} more tags`}
          >
            {`+${overflowCount}`}
          </InteractionTagPrimary>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            {visibleTags.map((item) => (
              <OverflowMenuItem key={item.value} tag={item} />
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </InteractionTag>
  );
};

type Props = {
  value: Array<{ children: string; value: string }>;
  setValue: (value: Array<{ children: string; value: string }>) => void;
};

const EmployeeTagsField = ({ value, setValue }: Props) => {
  const [originalEmployeeViews] = useAtom(companyEmployeeViewsAtom); // Keep the original list unchanged

  const options = React.useMemo(() => {
    // Flatten the list of all tags from the original list
    const allTags = originalEmployeeViews?.flatMap(
      (employee) => employee?.tags
    );

    // Create a Set to get unique tags and map it to an array
    return Array.from(new Set(allTags))
      .map((tag) => ({
        value: tag || "",
        children: tag,
      }))
      .filter((tag) => {
        return (
          value.length === 0 || !value.map((e) => e.value).includes(tag.value)
        );
      });
  }, [originalEmployeeViews, value]);

  const comboId = useId();
  const styles = useStyles();

  // State to track selected option
  const [selected, setSelected] = React.useState<string>("");

  // State to track options available in the dropdown
  const [taggedOptions, setTaggedOptions] = React.useState(options);

  const addTag = () => {
    const selectedOption = options.find((option) => option.value === selected);
    if (
      selectedOption &&
      !value.some((v) => v.value === selectedOption.value)
    ) {
      setValue([
        ...value,
        { ...selectedOption, children: selectedOption.children || "" },
      ]);
      setTaggedOptions(
        taggedOptions.filter((option) => option.value !== selectedOption.value)
      );
      setSelected(""); // Reset selected state after adding the tag
    }
  };

  const removeTag: TagGroupProps["onDismiss"] = (_e, { value: valueItem }) => {
    const removedTag = value.filter((tag) => tag.value !== valueItem);
    setValue(removedTag);
    // if (removedTag) {
    //   setTaggedOptions([...taggedOptions, removedTag]);
    // }
  };

  return (
    <div className={styles.root} style={{ marginTop: "30px" }}>
      <div className={styles.selectGroup}>
        <label id={comboId}>Existing&nbsp;tags:</label>
        <div className={styles.selectGroup}>
          <Select
            size="small" // Set the size of the Select component
            id={comboId}
            value={selected}
            onChange={(e) => setSelected(e.target.value)} // Update selected state on change
            className={styles.select}
            title="Select tags"
          >
            <option value={""}></option>
            {options
              .sort((a, b) => a.value.localeCompare(b.value))
              .map((option) => (
                <option key={option.value} value={option.value}>
                  {option.children}
                </option>
              ))}
          </Select>
          <Button
            onClick={addTag}
            disabled={!selected}
            size="small" // Set the size of the Select component
          >
            Select
          </Button>
        </div>
        <div className={styles.container}>
          <Overflow minimumVisible={2} padding={60}>
            <TagGroup
              onDismiss={removeTag}
              aria-label="Tag management"
              className={styles.tagGroup}
            >
              {value.map((tag) => (
                <OverflowItem key={tag.value} id={tag.value}>
                  <InteractionTag
                    key={tag.value}
                    value={tag.value}
                    size="small"
                  >
                    <InteractionTagPrimary
                      hasSecondaryAction
                      onClick={(e) => e.preventDefault()}
                    >
                      {tag.children}
                    </InteractionTagPrimary>
                    <InteractionTagSecondary aria-label="remove" />
                  </InteractionTag>
                </OverflowItem>
              ))}
              <OverflowMenu visibleTags={value} />
            </TagGroup>
          </Overflow>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTagsField;
