import { AddCircle20Regular } from "@fluentui/react-icons";
import { PackageDialog } from "components/PackageDialog/PackageDialog";
import { ActionButton, Flex } from "components/styled";
import { IBenefitImplementation, IRowItem } from "library/types";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  item: IRowItem;
};
export function AddPackageCell({ item }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  return (
    <>
      <Flex justify="flex-start" gap={"5px"}>
        <ActionButton
          icon={<AddCircle20Regular />}
          noBorder
          appearance="transparent"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {t("hbh.addPackage.label")}
        </ActionButton>
      </Flex>
      {open && (
        <PackageDialog
          benefitImplementation={item as IBenefitImplementation}
          open={open}
          setOpen={onDialogOpenChange}
        />
      )}
    </>
  );
}
