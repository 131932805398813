import {
  TableCellLayout,
  TableSelectionCell,
  createTableColumn,
} from "@fluentui/react-components";
import { CategoryActionsCell } from "components/TableCells/CategoryActionsCell";
import { columnNames } from "library/constants";
import styled from "styled-components";
import { BenefitCategoryNameCell } from "../../TableCells/BenefitCategoryNameCell";
import { ConsentNeededCell } from "../../TableCells/ConsentNeededCell";
import { CoverPhotoCell } from "../../TableCells/CoverPhotoCell";
import { DescAsDocCell } from "../../TableCells/DescAsDocCell";
import DescCell from "../../TableCells/DescCell";
import { EnrollmentProcessNeeded } from "../../TableCells/EnrollmentProcessNeeded";
import ImplementBenefitCell from "../../TableCells/ImplementBenefitCell";
import { TypeOfConsentCell } from "../../TableCells/TypeOfConsentCell";
import { UploadDocumentsCell } from "../../TableCells/UploadDocumentsCell";
import { IRowItem } from "library/types";
import { TFunction } from "i18next";
import { CategoryDocumentsCell } from "components/TableCells/CategoryDocumentsCell";

type ColumnProps = {
  countryCode: string | number;
  clientInternalNumberGOS?: number;
  withIncludedBenefits?: boolean;
  hasSelection?: boolean;
  withOpenCategory?: boolean;
  t: TFunction;
};

type IHeaderItem =
  | {
      toggleAllRows: (e: any) => void;
      allRowsSelected: boolean;
      selectionMode: "single" | "multiselect";
      someRowsSelected: boolean;
      toggleAllKeydown: (e: any) => void;
    }
  | any;

export const getColumnsDef = ({
  countryCode,
  withIncludedBenefits = false,
  clientInternalNumberGOS = 0,
  hasSelection,
  withOpenCategory,
  t,
}: ColumnProps) => [
  ...(hasSelection
    ? [
        createTableColumn({
          columnId: "select",
          renderHeaderCell: (item: IHeaderItem) => {
            if (item.selectionMode === "multiselect") {
              return (
                <TableSelectionCell
                  checked={
                    item.allRowsSelected
                      ? true
                      : item.someRowsSelected
                      ? "mixed"
                      : false
                  }
                  onClick={(e) => {
                    if (item.selectionMode === "multiselect") {
                      item.toggleAllRows(e);
                    }
                  }}
                  onKeyDown={item.toggleAllKeydown}
                  checkboxIndicator={{ "aria-label": "Select all rows " }}
                />
              );
            }
            if (item.selectionMode === "single") {
              return <TableSelectionCell type="radio" invisible />;
            }
            return <></>;
          },
          renderCell: (item: IRowItem) => {
            if (!item.selectionMode) return <></>;
            if (item.selectionMode === "multiselect")
              return (
                <TableSelectionCell
                  checked={item.selected}
                  checkboxIndicator={{ "aria-label": "Select row" }}
                />
              );
            if (item.selectionMode === "single") {
              return (
                <TableSelectionCell
                  checked={item.selected}
                  type="radio"
                  radioIndicator={{ "aria-label": "Select row" }}
                />
              );
            }
          },

          compare: (a, b) => {
            return (a?.benefitCategoryTag || 0) - (b?.benefitCategoryTag || 0);
          },
        }),
      ]
    : [
        // createTableColumn({
        //   columnId: "id",
        //   renderHeaderCell: () => <>{columnNames.id}</>,
        //   renderCell: (item: IRowItem) => {
        //     return <TableCellLayout>{item.benefitCategoryTag}</TableCellLayout>;
        //   },
        //   compare: (a, b) => {
        //     return (a?.benefitCategoryTag || 0) - (b?.benefitCategoryTag || 0);
        //   },
        // }),
      ]),
  createTableColumn({
    columnId: "coverPhoto",
    renderHeaderCell: () => <>{t(`hbh.coverPicture.label`)}</>,
    renderCell: (item: IRowItem) => {
      return <CoverPhotoCell item={item} />;
    },

    compare: (a, b) => {
      return (a?.coverPhoto?.name || "")?.localeCompare(
        b?.coverPhoto?.name || ""
      );
    },
  }),
  createTableColumn({
    columnId: "name",
    renderHeaderCell: () => <>{t(`hbh.name.label`)}</>,
    renderCell: (item: IRowItem) => {
      if (countryCode !== 0) {
        return item.name;
      }
      return (
        <InputCellWrap>
          <BenefitCategoryNameCell item={item} />
        </InputCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.name || "")?.localeCompare(b?.name || "");
    },
  }),

  ...(withIncludedBenefits
    ? [
        createTableColumn({
          columnId: "implementBenefit",
          renderHeaderCell: () => <>{t("hbh.implementBenefit.label")}</>,
          renderCell: (item: IRowItem) => {
            return (
              <TableCellLayout>
                <ImplementBenefitCell item={item} />
              </TableCellLayout>
            );
          },

          // compare: (a, b) => {
          //   return a.description.data.localeCompare(b.description.data);
          // },
        }),
        createTableColumn({
          columnId: "implementedBenefits",
          renderHeaderCell: () => <>{t("hbh.implementedBenefits.label")}</>,
          renderCell: (item: IRowItem) => {
            return item.implementationsCount;
          },

          compare: (a, b) => {
            return (
              (a?.implementationsCount ? 1 : 0) -
              (b?.implementationsCount ? 1 : 0)
            );
          },
        }),
      ]
    : []),
  createTableColumn({
    columnId: "uploadDocuments",
    renderHeaderCell: () => <>{t("hbh.uploadDocuments.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <UploadDocumentsCell item={item} />
        </SelectCellWrap>
      );
    },
    compare: (a, b) => {
      return (a?.uploadDocuments ? 1 : 0) - (b?.uploadDocuments ? 1 : 0);
    },
  }),
  createTableColumn({
    columnId: "manageDocuments",
    renderHeaderCell: () => <>{t("hbh.documents.label")}</>,
    renderCell: (item: IRowItem) => {
      return <CategoryDocumentsCell item={item} />;
    },

    compare: (a, b) => {
      return (a?.name || "").localeCompare(b?.name || "");
    },
  }),
  createTableColumn({
    columnId: "consentNeeded",
    renderHeaderCell: () => <>{t("hbh.consentNeeded.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <ConsentNeededCell item={item} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.consentsNeeded ? 1 : 0) - (b?.consentsNeeded ? 1 : 0);
    },
  }),
  createTableColumn({
    columnId: "consentTypes",
    renderHeaderCell: () => <>{t("hbh.typeOfConsent.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <TypeOfConsentCell item={item} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.consentTypes?.length || 0) - (b?.consentTypes?.length || 0);
    },
  }),
  createTableColumn({
    columnId: "descriptionAsDocument",
    renderHeaderCell: () => <>{t("hbh.descriptionAsDocument.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <DescAsDocCell item={item} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (
        (a?.descriptionAsDocument ? 1 : 0) - (b?.descriptionAsDocument ? 1 : 0)
      );
    },
  }),
  createTableColumn({
    columnId: "enrollmentProcessNeeded",
    renderHeaderCell: () => <>{t("hbh.enrollmentProcessNeeded.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <EnrollmentProcessNeeded item={item} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (
        (a?.enrollmentProcessNeeded ? 1 : 0) -
        (b?.enrollmentProcessNeeded ? 1 : 0)
      );
    },
  }),

  createTableColumn({
    columnId: "description",
    renderHeaderCell: () => <>{t("hbh.description.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <InputCellWrap>
          <DescCell item={item} />
        </InputCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.description || "").localeCompare(b?.description || "");
    },
  }),

  createTableColumn({
    columnId: "actionsCell",
    renderHeaderCell: () => (
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        {t("hbh.actions.label")}
      </div>
    ),
    renderCell: (item: IRowItem) => {
      return (
        <CategoryActionsCell
          item={item}
          countryCode={countryCode}
          clientInternalNumberGOS={clientInternalNumberGOS}
          withOpenCategory={withOpenCategory}
        />
      );
    },

    // compare: (a, b) => {
    //   return a.enrollmentProcessNeeded - b.enrollmentProcessNeeded;
    // },
  }),
];

export const defaultColumnSizingOptions = {
  id: {
    idealWidth: 30,
    minWidth: 30,
  },
  select: {
    idealWidth: 30,
    minWidth: 30,
  },
  name: {
    idealWidth: 200,
    minWidth: 200,
  },
  coverPhoto: {
    minWidth: 90,
    defaultWidth: 90,
  },
  uploadDocuments: {
    minWidth: 80,
  },
  manageDocuments: {
    idealWidth: 200,
    minWidth: 200,
  },
  consentNeeded: {
    minWidth: 80,
  },
  consentTypes: {
    minWidth: 280,
  },
  descriptionAsDocument: {
    minWidth: 165,
  },
  enrollmentProcessNeeded: {
    minWidth: 150,
  },
  // entitlements: {
  //   minWidth: 150,
  // },
  description: {
    minWidth: 220,
  },
  implementBenefit: {
    minWidth: 220,
  },
  implementedBenefits: {
    minWidth: 200,
  },

  // included: {
  //   minWidth: 70,
  //   idealWidth: 70,
  //   defaultWidth: 70,
  // },
  actionsCell: {
    minWidth: 80,
    idealWidth: 80,
    defaultWidth: 80,
  },
};

const SelectCellWrap = styled.div`
  .fui-Select__select,
  .fui-Select__select:focus,
  .fui-Select__select:focus-within {
    border-bottom: none;
  }

  .fui-Combobox,
  .fui-Combobox:focus {
    border-bottom: none;
  }
`;

const InputCellWrap = styled.div`
  .fui-Input,
  .fui-Input:focus {
    border-bottom: none;
  }
`;
