import {
  Divider,
  makeStyles,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { PageContent } from "components/styled";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  contentWrap: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gap: "20px",
    padding: "20px",
  },
  contentLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  contentRight: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
  },
  contentItemHeight: {
    height: "70vh",
  },
});

export const SummarySkeleton = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Skeleton>
        <SkeletonItem
          size={48}
          style={{
            opacity: "0.4",
          }}
        />
        <Divider />
        <SkeletonItem
          size={48}
          style={{
            opacity: "0.4",
          }}
        />
      </Skeleton>
      <PageContent>
        <Skeleton>
          <div className={styles.contentWrap}>
            <div className={styles.contentLeft}>
              <SkeletonItem className={styles.contentItemHeight} />
            </div>
            <div className={styles.contentRight}>
              <SkeletonItem className={styles.contentItemHeight} />

              <SkeletonItem className={styles.contentItemHeight} />
            </div>
          </div>
        </Skeleton>
      </PageContent>
    </div>
  );
};
