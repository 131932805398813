import { useAtomValue } from "jotai";
import { selectedBenefitsAtom } from "store/UIHrPage";

const BenefitsSelectedInfo = () => {
  const selectedPackages = useAtomValue(selectedBenefitsAtom);

  return (
    <div style={{ marginTop: "40px" }}>
      Selected {Array.from(selectedPackages).length} Packages
    </div>
  );
};

export default BenefitsSelectedInfo;
