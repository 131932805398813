import i18n from "../../i18n";

import Logo from "../../assets/GrECo_Logo_small.jpg";

import { logout } from "../../service/GraphService";
import { getHeaderConfig } from "./utils";

import { useTranslation } from "react-i18next";

import GrecoHeader from "components/GrecoHeader/GrecoHeader";
import { NavigationContent } from "components/Navigation/NavigationContent";
import useNotificationHandler from "hooks/useNotificationHandler";
import { useAtom, useSetAtom } from "jotai";
import { SelectItem } from "library/types";
import { useUserData } from "state/queries/useUserData";
import { buildYupLocale } from "validation";
import { STORAGE_KEYS } from "../../library/shared";
import { LocalStorage } from "../../service/storage";
import { darkModeState, panelIsOpenState } from "../../store";

const AppHeader = () => {
  const { t } = useTranslation();
  const { notifications, handleClearAllNotifications } =
    useNotificationHandler();

  const [_, setDarkMode] = useAtom(darkModeState);
  const setPanelIsOpen = useSetAtom(panelIsOpenState);

  const { data: user } = useUserData();

  const headerConfig = getHeaderConfig(t("hbh.appName.label"));

  return (
    <GrecoHeader
      navigation={<NavigationContent />}
      headerConfig={headerConfig}
      panelStatus={setPanelIsOpen}
      user={user}
      notifications={notifications}
      clearNotifications={handleClearAllNotifications}
      logo={Logo}
      logout={logout}
      darkMode={(isDarkMode: boolean) => {
        setDarkMode(isDarkMode);
      }}
      languageOptions={[
        {
          value: "en-GB",
          text: t("Language.EN_GB"),
        },
        {
          value: "de-AT",
          text: t("Language.DE_AT"),
        },
      ]}
      defaultLanguage={i18n.resolvedLanguage}
      onChangeLanguage={async (__event: any, languageOption?: SelectItem) => {
        if (!languageOption) return;

        LocalStorage.set(STORAGE_KEYS.appLang, languageOption?.value || "");
        i18n.changeLanguage(languageOption.value as string);
        buildYupLocale(null, t);
      }}
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
          darkModeToggleLabel: t("header.DarkMode"),
          selectLanguageLabel: t("header.SelectLanguage"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
      }}
    />
  );
};

export default AppHeader;
