import {
  Avatar,
  Button,
  Image,
  Link,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Text,
} from "@fluentui/react-components";
import { ArrowUploadRegular } from "@fluentui/react-icons";
import { ClearIcon } from "@fluentui/react-icons-mdl2";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile, IRowItem } from "library/types";
import { formatFile } from "library/utils";
import { IPackageRowItem } from "pages/CompanyPackagesPage/components/BenefitImplementationPackages/shared";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

type Props = {
  item: IRowItem | IPackageRowItem;
};

export const CoverPhotoCell = ({ item }: Props) => {
  const theme = useAppTheme();
  const [showImg, setShowImg] = useState(false);

  const [imgPreview, setImgPreview] = useState("");
  const defaultValue = useMemo(() => {
    return item.backgroundPicturePath
      ? {
          name: item.backgroundPictureName || "",
          path: item.backgroundPicturePath || "",
          base64String: item.backgroundPictureContent || "",
          type: item.backgroundPictureMimeType || "",
        }
      : null;
  }, [
    item.backgroundPicturePath,
    item.backgroundPictureName,
    item.backgroundPictureContent,
    item.backgroundPictureMimeType,
  ]);

  const [selectedFile, setSelectedFile] = useState<IFormattedFile | null>(
    defaultValue
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const previewFile = URL.createObjectURL(file);
      setImgPreview(previewFile);
    }

    const formattedFile = await formatFile(file);
    setSelectedFile(formattedFile);
    const updatedItem = {
      ...item,
      coverPhoto: formattedFile,
      isEdited: true,
    };
    item.updateState(updatedItem);
  };

  const handleMouseEnter = () => {
    setShowImg(true);
  };
  const onMouseLeave = () => {
    setShowImg(false);
  };

  useEffect(() => {
    if (item?.coverPhoto?.name === item?.defaultState?.coverPhoto?.name) {
      setSelectedFile(defaultValue);
    }
  }, [item]);

  useEffect(() => {
    setSelectedFile(defaultValue);
    setImgPreview("");
  }, [defaultValue]);

  return (
    <Container>
      {selectedFile ? (
        <Flex
          wrap="nowrap"
          justify="center"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {selectedFile.path ? (
            <Popover open={showImg} positioning={"above-start"}>
              <PopoverTrigger>
                <Button
                  icon={
                    <Image
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                      }}
                      shadow
                      src={selectedFile?.path}
                      alt="cover"
                    />
                  }
                  appearance="transparent"
                />
              </PopoverTrigger>
              <PopoverSurface>
                <Link href={selectedFile.path} target="_blank">
                  <img width={"100px"} src={selectedFile?.path} alt="cover" />
                </Link>
              </PopoverSurface>
            </Popover>
          ) : (
            <Text>
              <Image
                style={{
                  width: "30px",
                  height: "30px",
                }}
                shadow
                src={imgPreview}
                alt="cover"
              />
            </Text>
          )}
        </Flex>
      ) : (
        <Text
          style={{
            cursor: "pointer",
          }}
        >
          <Avatar
            name={item?.name}
            style={{
              borderRadius: "5px",
            }}
          />
        </Text>
      )}
      <Flex justify="flex-end">
        {selectedFile && (
          <Button
            size="small"
            appearance="transparent"
            icon={
              <ClearIcon
                style={{
                  transform: "scale(0.6)",
                  color: theme.palette.redDark,
                }}
              />
            }
            onClick={() => {
              const updatedItem = {
                ...item,
                coverPhoto: {
                  name: null,
                  base64String: null,
                  type: null,
                },
                isEdited: !!selectedFile,
              };
              setSelectedFile(null);

              item.updateState(updatedItem);
            }}
          />
        )}
        <Button
          size="small"
          appearance="transparent"
          icon={
            <>
              <ArrowUploadRegular />
              <input
                ref={fileInputRef}
                onChange={handleFileChange}
                type="file"
                accept="*"
                hidden
              />
            </>
          }
          onClick={() => {
            if (!fileInputRef.current) return;
            fileInputRef.current.click();
          }}
        />
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;
