import React from "react";
import {
  PersonEdit24Regular,
  PersonSquareCheckmark24Regular,
} from "@fluentui/react-icons";
import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import styled from "styled-components";
import { isBefore } from "date-fns";

export const generatePackageNode = ({
  benefitPackage,
  palette,
  setters: {
    setSelectedPackage,
    setDeleteEnrollmentsOpen,
    setRemoveEnrollments,
    setManageEligibilityOpen,
    setManageEnrollmentOpen,
    setCancelEnrollmentOpen,
    setSelectedEmployee,
    setSelectedEnrollment,
  },
  t,
}) => {
  return {
    id: benefitPackage.benefitPackageTag + "",
    content: (
      <Container>
        <NameWrap>
          <AvatarWithPreview
            avatarStyle={{
              borderRadius: "5px",
            }}
            size={28}
            name={`${benefitPackage.benefitImplementationName} ${benefitPackage.name}`}
            imgSrc={benefitPackage.backgroundPicturePath}
          />
          <>
            {benefitPackage.benefitCategoryName}&nbsp;&middot;&nbsp;
            {benefitPackage.benefitImplementationName}&nbsp;&middot;&nbsp;
            {benefitPackage.name}
          </>
        </NameWrap>
      </Container>
    ),
    parentId: undefined,
    dataItem: benefitPackage,
    hasActions: true,
    useSelectBox: true,
    actions: [
      {
        label: t("hbh.enrollment.makeEligible.label"),
        onClick: () => {
          setSelectedPackage(benefitPackage);
          setManageEligibilityOpen(true);
        },
        icon: <PersonEdit24Regular style={{ color: palette.themePrimary }} />,
      },
      {
        label: t("hbh.enrollment.enrollEligible.label"),
        onClick: () => {
          setSelectedPackage(benefitPackage);
          setSelectedEmployee(null);
          setSelectedEnrollment(null);
          setManageEnrollmentOpen(true);
        },
        icon: (
          <PersonSquareCheckmark24Regular
            style={{ color: palette.themePrimary }}
          />
        ),
      },
      {
        label: t("hbh.enrollment.removeEligibilities.label"),
        onClick: () => {
          setRemoveEnrollments(false);
          setSelectedPackage(benefitPackage);
          setSelectedEnrollment(null);
          setSelectedEmployee(null);
          setDeleteEnrollmentsOpen(true);
        },
        icon: (
          <DeleteIcon
            style={{
              color: palette.redDark,
            }}
          />
        ),
      },
      {
        label: t("hbh.enrollment.removeEnrollments.label"),
        onClick: () => {
          setRemoveEnrollments(true);
          setSelectedPackage(benefitPackage);
          setSelectedEnrollment(null);
          setSelectedEmployee(null);
          setDeleteEnrollmentsOpen(true);
        },
        icon: (
          <DeleteIcon
            style={{
              color: palette.redDark,
            }}
          />
        ),
      },
      {
        label: t("hbh.enrollment.cancelEnrollments.label"),
        onClick: () => {
          setSelectedPackage(benefitPackage);
          setSelectedEnrollment(null);
          setSelectedEmployee(null);
          setCancelEnrollmentOpen(true);
        },
        icon: (
          <DeleteIcon
            style={{
              color: palette.redDark,
            }}
          />
        ),
      },
    ],
  };
};

export const generateEnrollmentNode = ({
  employee,
  enrollment,
  enrolledPackage,
  packageData,
  packageUser,
  palette,
  dependentTypes,
  t,
  setters: {
    setAddEligibilityOpen,
    setEditEmployeeOpen,
    setManageEnrollmentOpen,
    setRemoveEnrollmentOpen,
    setCancelEnrollmentOpen,
    setSelectedPackage,
    setSelectedEmployee,
    setSelectedEnrollment,
    setRemoveEnrollments,
  },
  invalidateEmployeeEnrollment,
  invalidateDependentEnrollment,
}) => {
  return {
    id: employee,
    content: (
      <Container>
        <Wrap
          eligible={!!enrolledPackage + ""}
          enrolled={!!enrolledPackage?.dateOfEnrollment + ""}
          expired={isBefore(packageData?.validTo || "", new Date()) + ""}
          cancelled={!!enrolledPackage?.dateOfCancellation + ""}
        >
          <Item>
            <AvatarWithPreview
              avatarStyle={
                {
                  //borderRadius: "5px",
                }
              }
              size={28}
              name={`${packageUser?.firstName} ${packageUser?.lastName}`}
              imgSrc={packageUser?.backgroundPicturePath}
            />
            <>
              {packageUser?.firstName +
                " " +
                packageUser?.lastName +
                " " +
                (packageUser?.employeeDependentTag
                  ? "(" +
                    t(
                      dependentTypes.find(
                        (depType) =>
                          depType.value === packageUser?.dependentType
                      )?.code
                    ) +
                    ")"
                  : "- " + packageUser?.emailAddress)}
            </>
          </Item>
        </Wrap>
      </Container>
    ),
    parentId: packageData.benefitPackageTag + "",
    dataItem: enrollment
      ? {
          ...enrollment,
          employeeDependentTag: packageUser.employeeDependentTag,
          employeeLoginEmailAddress: packageUser.employeeLoginEmailAddress,
          dependentType: packageUser.dependentType,
        }
      : null,
    hasActions: true,
    useSelectBox: false,

    // eligible={!!enrolledPackage}
    // enrolled={!!enrolledPackage?.dateOfEnrollment}
    actions: !!!enrolledPackage
      ? // Not eligable
        [
          {
            label: t("hbh.enrollment.makeEligible.label"),
            onClick: () => {
              setSelectedPackage(packageData);
              setSelectedEmployee(packageUser);
              setAddEligibilityOpen(true);
            },
            icon: (
              <PersonEdit24Regular style={{ color: palette.themePrimary }} />
            ),
          },
        ]
      : // Eligable but not enrolled
      !!!enrolledPackage?.dateOfEnrollment
      ? [
          {
            label: t("hbh.enrollment.editEligibility.label"),
            onClick: () => {
              invalidateEmployeeEnrollment();
              invalidateDependentEnrollment();
              setSelectedPackage(packageData);
              setSelectedEmployee(packageUser);
              setSelectedEnrollment(enrollment);
              setEditEmployeeOpen(true);
            },
            icon: (
              <PersonEdit24Regular style={{ color: palette.themePrimary }} />
            ),
          },
          {
            label: t("hbh.enrollment.enrollEligible.label"),
            onClick: () => {
              invalidateEmployeeEnrollment();
              invalidateDependentEnrollment();
              setSelectedPackage(packageData);
              setSelectedEmployee(packageUser);
              setSelectedEnrollment(enrollment);
              setManageEnrollmentOpen(true);
            },
            icon: (
              <PersonSquareCheckmark24Regular
                style={{ color: palette.themePrimary }}
              />
            ),
          },

          {
            label: t("hbh.enrollment.removeEligibility.label"),
            onClick: () => {
              setSelectedPackage(packageData);
              setSelectedEmployee(packageUser);
              setSelectedEnrollment(enrollment);
              setRemoveEnrollments(false);
              setRemoveEnrollmentOpen(true);
            },
            icon: (
              <DeleteIcon
                style={{
                  color: palette.redDark,
                }}
              />
            ),
          },
        ]
      : // Eligable and enrolled
      !!!enrollment?.dateOfCancellation
      ? [
          {
            label: t("hbh.enrollment.editEnrollment.label"),
            onClick: () => {
              invalidateEmployeeEnrollment();
              invalidateDependentEnrollment();
              setSelectedPackage(packageData);
              setSelectedEmployee(packageUser);
              setSelectedEnrollment(enrollment);
              setEditEmployeeOpen(true);
            },
            icon: (
              <PersonEdit24Regular style={{ color: palette.themePrimary }} />
            ),
          },
          {
            label: t("hbh.enrollment.removeEnrollment.label"),
            onClick: () => {
              setSelectedPackage(packageData);
              setSelectedEmployee(packageUser);
              setSelectedEnrollment(enrollment);
              setRemoveEnrollments(true);
              setRemoveEnrollmentOpen(true);
            },
            icon: (
              <DeleteIcon
                style={{
                  color: palette.redDark,
                }}
              />
            ),
          },
          {
            label: t("hbh.enrollment.cancelEnrollment.label"),
            onClick: () => {
              setSelectedPackage(packageData);
              setSelectedEmployee(packageUser);
              setSelectedEnrollment(enrollment);
              setCancelEnrollmentOpen(true);
            },
            icon: (
              <DeleteIcon
                style={{
                  color: palette.redDark,
                }}
              />
            ),
          },
        ]
      : [
          {
            label: t("hbh.enrollment.editEnrollment.label"),
            onClick: () => {
              invalidateEmployeeEnrollment();
              invalidateDependentEnrollment();
              setSelectedPackage(packageData);
              setSelectedEmployee(packageUser);
              setSelectedEnrollment(enrollment);
              setEditEmployeeOpen(true);
            },
            icon: (
              <PersonEdit24Regular style={{ color: palette.themePrimary }} />
            ),
          },
        ],
  };
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  padding: 2px;
  width: 100%;
`;
const NameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

const Wrap = styled.div<{
  eligible: string;
  enrolled: string;
  cancelled: string;
  expired: string;
}>`
  display: flex;
  background: ${({ theme, enrolled, cancelled, expired }) =>
    expired === "true"
      ? theme.palette.neutralTertiary
      : cancelled === "true"
      ? theme.semanticColors.blockingBackground
      : enrolled === "true"
      ? theme.semanticColors.successHighlight
      : "none"};
  border: ${({ theme, enrolled, eligible }) =>
    eligible === "true" && enrolled !== "true"
      ? `1px solid ${theme.semanticColors.successHighlight}`
      : "none"};
  border-left: ${({ theme, enrolled, eligible }) =>
    eligible === "true" && enrolled !== "true"
      ? `15px solid ${theme.semanticColors.successHighlight}`
      : "none"};
  border-radius: 5px;
  margin: 0 0 2px 0;
  box-sizing: border-box;
  padding-right: 2px;
  width: 100%;
`;

const Item = styled.div<{ indent?: boolean; width?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;

  width: ${({ indent, width }) =>
    width ? width : indent ? "fit-content" : "fit-content"};
`;

const Value = styled.div`
  font-weight: 600;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;
