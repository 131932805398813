import { InfoLabel } from "@fluentui/react-components";
import { styled } from "styled-components";

type Props = {
  label: string;
  required: boolean;
  disabled?: boolean;
  info?: string;
};
function FormFieldLabel({ label, required, disabled, info = "" }: Props) {
  return (
    <StyledInfoLabel info={info} disabled={disabled} required={required}>
      {label}
    </StyledInfoLabel>
  );
}

export default FormFieldLabel;

const StyledInfoLabel = styled(InfoLabel)<{ disabled?: boolean }>`
  display: block;
  margin-bottom: 5px;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;
